/*----------------------------------------*/
/*  49 - Pages - Product Detail
/*----------------------------------------*/
.product-detail_area{
    .product-detail_img{
        overflow: hidden;
        .single-img{
            .inner-stuff{
                position: absolute;
                bottom: 20px;
                right: 20px;
                z-index: 1;
                ul{
                    li{
                        &:not(:last-child){
                            margin-bottom: 15px;
                        }
                        a{
                            box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
                            background-color: $white-color;
                            width: 50px;
                            height: 50px;
                            line-height: 53px;
                            border-radius: 100%;
                            display: block;
                            text-align: center;
                            &:hover{
                                background-color: $secondary-color;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        &.vertical-slider_wrap{
            @include breakpoint(min-md_device){
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-start;
                .pd-vertical_slider{
                    flex-basis: 90%;
                    max-width: 90%;
                    margin-left: 30px;
                    cursor: crosshair;
                }
                .vertical-slider_nav{
                    flex-basis: 10%;
                    max-width: 10%;
                    height: 100%;
                    .swiper-wrapper{
                        height: 100%;
                        .swiper-slide{
                            height: 100% !important;
                            opacity: 0.5;
                            &.swiper-slide-thumb-active{
                                opacity: 1;
                            }
                        }
                    }
                }
                &.vt-right_side{
                    flex-direction: unset;
                }
            }
            .vertical-slider_nav{
                @include breakpoint(max-sm_device){
                    padding-top: 30px;
                }
            }
        }
        &.horizontal-slider_wrap{
            .gallery-thumbs{
                padding-top: 8px;
            }
        }
    }
    .product-detail_content{
        @include breakpoint(max-md_device){
            padding-top: 25px;
        }
        h1{
            &.heading{
                font-size: 24px;
                line-height: 28.8px;
                font-weight: 400;
            }
        }
        .price-box{
            padding-bottom: 15px;
            span{
                &.new-price{
                    color: $secondary-color;
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }
        }
        p{
            &.short-desc{
                padding-bottom: 25px;
            }
        }
        .countdown-wrap{
            padding-bottom: 30px;
            .countdown{
                &.item-4{
                    .countdown__item{
                        box-shadow: 0 0 22px 0 rgba(0,0,0,0.1);
                        width: 70px;
                        height: 70px;
                        margin-left: 0;
                        border-radius: 100%;
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        &[class*="countdown__item"]{
                            margin-right: 20px;
                            @include breakpoint(max-xl_device){
                                margin-right: 15px;
                            }
                        }
                        @include breakpoint(max-xxs_device){
                            width: 50px;
                            height: 50px;
                        }
                        span{
                            color: $secondary-color;
                            font-size: 16px;
                            line-height: 1.2;
                            @include breakpoint(max-xxs_device){
                                font-size: 14px;
                            }
                            &.countdown__text{
                                padding-top: 0;
                                font-family: 'Playfair Display', serif;
                                font-style: italic;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .quantity-with_btn{
            display: flex;
            padding-bottom: 20px;
            .add-to_cart{
                padding-left: 15px;
            }
        }
        .product-with_quantity{
            border-bottom: 1px solid $border-color;
            display: flex;
            padding-bottom: 5px;
            margin-bottom: 20px;
            .product-info_wrap{
                padding-left: 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .inner-content{
                    .product-name{
                        a{
                            color: $text-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                    .price-box{
                        span{
                            font-size: 14px;
                            &.new-price{
                                color: inherit;
                            }
                            &.old-price{
                                @include typography(14px, 14px);
                                text-decoration: line-through;
                                color: $nobel-color;
                            }
                        }
                    }
                }
                .addition-content{
                    .product-stock{
                        span{
                            font-size: 12px;
                            &:before {
                             content: '\ea20';
                             font-family: LaStudioIcons;
                             width: 15px;
                             height: 15px;
                             border-radius: 50%;
                             border: 1px solid;
                             font-size: 8px;
                             display: inline-block;
                             line-height: 14px;
                             text-align: center;
                             color: #86BA45;
                             margin-left: 5px;
                             margin-right: 5px;
                         }
                        }
                    }
                }
            }
        }
        .addition-group_btn{
            padding-bottom: 25px;
            li{
                display: inline-block;
                &:not(:last-child){
                    padding-right: 15px;
                }
                a{
                    i{
                        font-size: 20px;
                    }
                }
            }
        }
        .additional-stuff{
            > li{
                border-top: 1px solid $border-color;
                padding-top: 20px;
                &:not(:last-child){
                    padding-bottom: 20px;
                }
                &.product-meta{
                    > ul{
                        li{
                            font-size: 14px;
                            line-height: 25.2px;
                            a{
                                color: $primary-color;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                &.store-info{
                    > ul{
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            &:not(:last-child){
                                padding-right: 25px;
                            }
                            a{
                                color: inherit;
                                font-size: 14px;
                                line-height: 25.2px;
                                &:hover{
                                    color: $primary-color;
                                }
                                i{
                                    vertical-align: text-top;
                                }
                            }
                        }
                    }
                }
                &.social-link_wrap{
                    display: flex;
                    align-items: center;
                    @include breakpoint(max-xxs_device){
                        flex-direction: column;
                    }
                    span{
                        text-transform: uppercase;
                        color: $secondary-color;
                        font-size: 11px;
                        line-height: 19.8px;
                        font-weight: 600;
                        padding-right: 20px;
                        @include breakpoint(max-xxs_device){
                            padding-bottom: 15px;
                            padding-right: 0;
                        }
                    }
                    > ul{
                        li{
                            &:not(:last-child){
                                padding-right: 15px;
                            }
                            a{
                                border: 1px solid $border-color;
                                color: inherit;
                                border-radius: 100%;
                                display: block;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .addition-info{
        .single-img{
            .inner-content{
                position: absolute;
                bottom: 15px;
                left: 15px;
                z-index: 1;
                pointer-events: none;
                span{
                    text-transform: uppercase;
                    &.category{
                        font-size: 20px;
                        line-height: 1.2;
                        @include breakpoint(max-xl_device){
                            font-size: 16px;
                        }
                    }
                    &.offer{
                        font-size: 60px;
                        line-height: 54px;
                        @include breakpoint(max-xl_device){
                            font-size: 40px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
        .pd-newsletter{
            background-color: #e5f3ef;
            text-align: center;
            margin-top: 20px;
            padding: 15px;
            i{
                color: $secondary-color;
                font-size: 30px;
            }
            h5,
            p{
                font-family: 'Playfair Display', serif;
            }
            h5{
                &.heading{
                    padding-bottom: 5px;
                    font-size: 22px;
                    line-height: 1.2;
                }
            }
            p{
                &.short-desc{
                    font-size: 10px;
                    line-height: 15px;
                }
            }
            .subscribe-form_wrap{
                display: flex;
                justify-content: center;
                form{
                    input{
                        &.input-field{
                            font-family: 'Playfair Display', serif;
                            background-color: transparent;
                            width: 100%;
                            font-size: 11px;
                            border: 0;
                            padding-bottom: 10px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

/* ---Product Demo Four--- */
.product-demo-04{
    .product-detail_img{
        .product-demo_wrap{
            --bs-gutter-x: 10px;
            .single-img{
                margin-top: 10px;
            }
            .custom-col-01{
                flex: 0 0 100%;
                max-width: 100%;
                .single-img{
                    img{
                        width: 100%;
                        height: 330px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            .custom-col-02{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}

/* ---Variable Product--- */
.variable-product_area{
    .product-detail_content{
        .selector-wrap{
            display: flex;
            align-items: center;
            span{
                width: 150px;
            }
        }
    }
}