/*----------------------------------------*/
/*  25 - Components - Instagram
/*----------------------------------------*/
.instagram{
    &-img{
        position: relative;
        display: block;
        .add-action{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -20%);
            @extend %basetransition;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            i{
                background-color: $white-color;
                color: $secondary-color;
                border-radius: 100%;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                display: block;
            }
        }
        &:hover{
            .add-action{
                transform: translate(-50%, -50%);
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-area{
        &.style{
            &-1{
                .instagram{
                    &-img{
                        &:before{
                            background-color: $secondary-color;
                            content: "";
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                            @extend %basetransition;
                        }
                        &:hover{
                            &:before{
                                opacity: 0.5;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            &-2{
                .instagram{
                    &-img{
                        display: flex;
                        align-items: center;
                        .single-img{
                            position: relative;
                            .add-action{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -20%);
                                @extend %basetransition;
                                opacity: 0;
                                visibility: hidden;
                                z-index: 1;
                                i{
                                    background-color: $white-color;
                                    color: $secondary-color;
                                    border-radius: 100%;
                                    width: 50px;
                                    height: 50px;
                                    line-height: 50px;
                                    text-align: center;
                                    font-size: 16px;
                                    display: block;
                                }
                            }
                            &:hover{
                                .add-action{
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        .group-img_wrap{
                            position: relative;
                            &:before{
                                border: 1px solid $border-color;
                                border-radius: 100%;
                                width: 100%;
                                height: 90%;
                                top: 0;
                                left: 0;
                                content: "";
                                position: absolute;
                                z-index: -1;
                            }
                            .group-img{
                                display: flex;
                            }
                            .additional-img{
                                width: 65%;
                            }
                        }
                        .single-img{
                            padding: 30px;
                            @include breakpoint(max-lg_device){
                                padding: 20px;
                            }
                            @include breakpoint(max-sm_device){
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Custom Instagram--- */
.custom-instagram_area{
    .instagram-content{
        @include breakpoint(max-md_device){
            padding-bottom: 30px;
        }
        h2{
            &.sub-heading{
                font-size: 56px;
                line-height: 64.4px;
                @include breakpoint(max-lg_device){
                    font-size: 45px;
                    line-height: 53.4px;
                }
            }
        }
    }
}