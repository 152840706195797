/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/

/* ---Scroll Behavior--- */
html {
	scroll-behavior: smooth;
}

body {
	font-family: $fontFamily;
	@include typography($size, $lineHeight, $weight);
	color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary-color;
	font-family: $fontFamily;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

img {
	max-width: 100%;
}

.img-full {
	width: 100%;
	overflow: hidden;
	object-fit: cover;
	object-position: center center;
}

a{
	color: $secondary-color;
	font-size: $size;
	text-transform: capitalize;
}

a,
button,
i {
	@extend %basetransition;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.swiper-button-next,
.swiper-button-prev {
	text-decoration: none;
	outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}

input{
	&.input-field{
		border: 1px solid $border-color;
	}
}
textarea{
	&.textarea-field{
		border: 1px solid $border-color;
	}
}

/* ---Skudmart Custom Input Checkbox--- */
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	position: relative;
	padding-left: 30px;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		display: block;
		border: 2px solid $border-color;
		content: "";
		@extend %basetransition;
	}
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		content: "\ea21";
		font-family: "LaStudioIcons";
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		opacity: 0;
		width: 20px;
		text-align: center;
		@extend %basetransition;
		color: $white-color;
	}
}
input[type="checkbox"]:checked + label {
	&:before {
		border: 2px solid $border-color;
	}
	&:after {
		opacity: 1;
	}
}
/* -End Here- */

label{
	margin-bottom: 0.5rem;
}

button,
.btn {
	cursor: pointer;
	border: 0;
	padding: 0;
	background: transparent;
}

.cursor-pointer{
	cursor: pointer;
}

.required{
	color: $red-color;
}

::selection{
	background-color: $secondary-color;
	color: $white-color;
}
.font-size_inherit{
	font-size: inherit;
}

iframe{
	border: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

 audio,
 canvas,
 iframe,
 img,
 svg,
 video {
   vertical-align: middle;
 }
 
/*
  * Remove default fieldset styles.
*/
 
 fieldset {
   border: 0;
   margin: 0;
   padding: 0;
 }
 
 /*
* Allow only vertical resizing of textareas.
*/
 
 textarea {
   resize: vertical;
}

/* ---Local Font Family--- */
.font-space_mono{
	font-family: 'space_monoregular';
}