/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Navigation
    11 - Components - Animation
    12 - Components - Button
    13 - Components - Slider
    14 - Components - Breadcrumb
    15 - Components - Form
    16 - Components - Newsletter
    17 - Components - Social Link
    18 - Components - Testimonials
    19 - Components - Sidebar
    20 - Components - Tab
    21 - Components - Brand
    22 - Components - Offcanvas
    23 - Components - Product
    24 - Components - Modal
    25 - Components - Instagram
    26 - Components - Banner
    27 - Components - Shipping
    28 - Components - Multiple Section

    35 - Section - Header
    36 - Section - Footer

    37 - Pages - About Us
    38 - Pages - Contact Us
    39 - Pages - Coming Soon
    40 - Pages - FAQs
    41 - Pages - Error 404
    42 - Pages - Collections
    43 - Pages - Shop
    44 - Pages - Cart
    45 - Pages - Order Tracking
    46 - Pages - My Account
    47 - Pages - Wishlist
    48 - Pages - Compare
    49 - Pages - Product Detail
    50 - Pages - Elements
    51 - Pages - Blog
    52 - Pages - Newsletter
    53 - Pages - Checkout

******************************/
/*

    Primary Color:       #D2A35C;
    Primary Color-2:     #ee4300;
    Secondary Color:     #000000;
    Text Color:          #1b1b1b;
    Border Color:        #d8d8d8;


    Font Family List:-

    font-family: 'Pacifico', cursive;
    font-family: 'Playfair Display', serif;
    font-family: 'Roboto', sans-serif;
    
*/
/* ---Google Font--- */
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/
/* ---Scroll Behavior--- */
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #707070;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.img-full {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

a {
  color: #1d1d1d;
  font-size: 16px;
  text-transform: capitalize;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.swiper-button-next,
.swiper-button-prev {
  text-decoration: none;
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input.input-field {
  border: 1px solid #d8d8d8;
}

textarea.textarea-field {
  border: 1px solid #d8d8d8;
}

/* ---Skudmart Custom Input Checkbox--- */
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

input[type="checkbox"] + label:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #d8d8d8;
  content: "";
}

input[type="checkbox"] + label:after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\ea21";
  font-family: "LaStudioIcons";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  width: 20px;
  text-align: center;
  color: #ffffff;
}

input[type="checkbox"]:checked + label:before {
  border: 2px solid #d8d8d8;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* -End Here- */
label {
  margin-bottom: 0.5rem;
}

button,
.btn {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  color: #ff0000;
}

::selection {
  background-color: #1d1d1d;
  color: #ffffff;
}

.font-size_inherit {
  font-size: inherit;
}

iframe {
  border: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
  * Remove default fieldset styles.
*/
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
* Allow only vertical resizing of textareas.
*/
textarea {
  resize: vertical;
}

/* ---Local Font Family--- */
.font-space_mono {
  font-family: 'space_monoregular';
}

/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/
/* ---Custom Bootstrap Container--- */
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
    --bs-gutter-x: 15px;
  }
}

@media (min-width: 1200px) {
  .container.custom-space {
    max-width: calc(100% - 50px);
  }
}

@media (min-width: 1501px) {
  .container.custom-space {
    max-width: calc(100% - 130px);
  }
}

@media (min-width: 1200px) {
  .container.custom-space-2 {
    max-width: calc(100% - 90px);
  }
}

@media (min-width: 1501px) {
  .container.custom-space-2 {
    max-width: calc(100% - 170px);
  }
}

@media (min-width: 768px) {
  .container.custom-space-3 {
    max-width: 770px;
  }
}

@media (min-width: 1200px) {
  .row:not([class*="product-demo_wrap"]) {
    --bs-gutter-x: 30px;
  }
}

/* ---Custom Column--- */
@media (min-width: 1501px) {
  .custom-xxl-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/* ---Skudmart Custom Wrap--- */
.skudmart-wrap-70 {
  margin-left: -35px;
  margin-right: -35px;
}

@media (max-width: 1199px) {
  .skudmart-wrap-70 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.skudmart-wrap-70 [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media (max-width: 1199px) {
  .skudmart-wrap-70 [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* ---Global Overlay--- */
.global-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  visibility: hidden;
}

.global-overlay.overlay-open {
  visibility: visible;
  cursor: pointer;
}

/* ---Section Space--- */
/* --Padding Top-- */
.pt-100 {
  padding-top: 100px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-10 {
  padding-top: 10px;
}

/* --Padding Bottom-- */
.pb-100 {
  padding-bottom: 100px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-05 {
  padding-bottom: 5px;
}

/* ---Paddin Bottom Decrease--- */
@media (max-width: 991px) {
  .pb-max-md-85 {
    padding-bottom: 85px;
  }
}

/* ---Margin Bottom--- */
.mb-05 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* ---Disable Padding Bottom--- */
@media (min-width: 1501px) {
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .pb-xs-0 {
    padding-bottom: 0 !important;
  }
}

/* ---Disable Margin Bottom--- */
@media (max-width: 575px) {
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }
}

/* ---Base Transition--- */
a,
button,
i, input[type="checkbox"] + label:before, input[type="checkbox"] + label:after, .global-overlay, .tab-content .tab-pane.active, .scroll-to-top i, .quantity .cart-plus-minus > .qtybutton, .price-filter .ui-slider-range, .price-filter .ui-slider-handle, .price-filter .price-slider-amount .range-btn, ul.color-option li label, .img-hover_effect.single-img:before, .popup_wrapper, .popup_wrapper .test .popup_off, .popup_wrapper .subscribe-area .subscribe-img:before, .swiper-button-next,
.swiper-button-prev, .circle-arrow .swiper-button-next,
.circle-arrow .swiper-button-prev, .modal-button-next, .modal-button-prev, .swiper-pagination.white-color span.swiper-pagination-bullet, .swiper-pagination.black-color span.swiper-pagination-bullet, .swiper-pagination.vertical-pagination span.swiper-pagination-bullet:before, .swiper-pagination.vertical-pagination span.swiper-pagination-bullet:after, .skudmart-btn, .grow-animation, .shrink-animation, .pulse-animation, .pulse-grow-animation, .main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li a, .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection, .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li a span:before, form.feedback-form.with-border-bottom .form-field textarea.textarea-field, form.feedback-form.with-border-bottom .form-field input.input-field, .sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content, .brand-common_style .swiper-wrapper .swiper-slide .brand-item img, .mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand, .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_img, .product-item .single-product .single-img, .product-item .single-product .single-img:before, .product-item .single-product .single-img a img.secondary-img, .product-item .single-product .single-img .add-actions > ul li, .product-item .single-product .single-img .add-actions > ul li a, .product-item.style-02 .single-product .single-content .add-actions ul li, .product-item.style-02 .single-product .single-content .add-actions ul li a, .modal-wrapper.modal, .instagram-img .add-action, .instagram-area.style-1 .instagram-img:before, .instagram-area.style-2 .instagram-img .single-img .add-action, .banner-area-11 .banner-item .single-img .inner-content, .banner-area-19 .banner-item .single-img .inner-content, .multiple-section .multiple-section_body .product-item .single-content, .multiple-section .multiple-section_body .banner-item.style-02 .inner-content .inner-img img, .main-header .main-nav > ul > li > ul.skudmart-dropdown, .main-header .main-nav > ul > li > ul.skudmart-dropdown li, .main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder ul.skudmart-submenu, .main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap, .header-logo img, .popular-collection_area .collection-item .button-wrap, .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img:before, .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img a img.secondary-img, .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions, .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li, .shop-product-wrap > [class*="col-"], .coupon-all .coupon input.input-btn,
.coupon-all .coupon2 input.input-btn, .cart-page-total a, .order-tracking_item form.order-tracking_form input.order-input, .table-content table td.skudmart-cart_btn a, .compare-table .table tbody tr, .iconbox-style-03 .iconbox-item.single-icon_bg .single-icon .inner-icon, .team-member_item.hover-style .single-img:before, .team-member_item.hover-style .single-img .add-action, .team-member_item.hover-style-2 .single-img img, .team-member_item.hover-style-2 .single-img .add-action, .team-member_item.hover-style-3 .single-img img, .team-member_item.hover-style-3 .single-img:before, .team-member_item.hover-style-3 .single-img .add-action, .team-member_item.hover-style-3 .single-img .add-action ul > li, .coupon-accordion span, .coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"], .order-button-payment input {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* ---Topbar--- */
.topbar-item {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-item a.product-offer {
  padding-right: 30px;
}

.topbar-item a.product-offer span {
  font-weight: 500;
}

.topbar-item.bg-dark_color a.product-offer {
  color: #ffffff;
}

.topbar-item.bg-dark_color a.product-offer:hover {
  color: #D2A35C;
}

.topbar-item.style-02 .product-offer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.topbar-item.style-02 .product-offer span {
  font-size: 14px;
  text-transform: uppercase;
}

.topbar-item.style-02 .product-offer strong {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.topbar-item.style-02 .countdown-wrap {
  display: flex;
}

.topbar-item.style-02 .countdown-wrap span.countdown-title {
  font-weight: 500;
}

.topbar-item.style-02 .countdown-wrap .countdown.item-4 .countdown__item {
  margin-left: 15px;
}

.topbar-item.style-02 .countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
  font-size: 14px;
  font-weight: 500;
}

.topbar-item.style-02 .countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
  display: inline-block;
  font-size: 14px;
  padding-top: 0;
}

/* ---Pagination--- */
.pagination-area.position-center {
  display: flex;
  justify-content: center;
}

.pagination-area .pagination-box li {
  display: inline-block;
}

.pagination-area .pagination-box li:not(:last-child) {
  padding-right: 5px;
}

.pagination-area .pagination-box li a {
  border: 1px solid #d8d8d8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 100%;
}

.pagination-area .pagination-box li a.next i {
  font-size: 16px;
}

.pagination-area .pagination-box li.active a {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.pagination-area .pagination-box li:hover a {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

/* ---Section Title--- */
.section-title_area {
  text-align: center;
}

.section-title_area h2.heading {
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 1px;
}

@media (max-width: 575px) {
  .section-title_area h2.heading {
    font-size: 22px;
  }
}

.section-title_area h2.heading.with-divider {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 25px;
}

.section-title_area h2.heading.with-divider:before {
  background-color: #d8d8d8;
  position: absolute;
  content: "";
  width: 2px;
  height: 20px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section-title_area p.short-desc {
  max-width: 62%;
  margin: 0 auto;
  line-height: 32px;
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .section-title_area p.short-desc {
    max-width: 85%;
  }
}

@media (max-width: 575px) {
  .section-title_area p.short-desc {
    max-width: 100%;
  }
}

.section-title_area .text-btn {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.section-title_area-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title_area-2 h2.heading {
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .section-title_area-2 h2.heading {
    font-size: 20px;
  }
}

.section-title_area-2 h2.with-divider {
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
}

.section-title_area-2 h2.with-divider:before {
  background-color: #1d1d1d;
  width: 50px;
  height: 1px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.section-title_area-2 h2.with-divider:after {
  background-color: #1d1d1d;
  width: 50px;
  height: 1px;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 479px) {
  .section-title_area-2 h2.with-divider {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-title_area-2 h2.with-divider:before, .section-title_area-2 h2.with-divider:after {
    content: none;
  }
}

.section-title_area-2 p.short-desc {
  font-size: 14px;
  line-height: 28px;
}

.section-title_area-3 {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.section-title_area-3 h2.heading {
  font-size: 24px;
  line-height: 28.8px;
}

/* ---Search Box--- */
.search-box.with-border_bottom {
  position: relative;
}

.search-box.with-border_bottom input.input-field {
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 20px 5px 0;
  color: #999999;
  background-color: transparent;
}

.search-box.with-border_bottom input.input-field:focus {
  border-color: #707070;
  color: #707070;
}

.search-box.with-border_bottom input.input-field::placeholder {
  opacity: 1;
}

.search-box.with-border_bottom .search-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .search-box.different-width {
    width: 270px;
  }
}

/* ---Slider Nav Count--- */
.slide-nav_count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 150px;
}

.slide-nav_count span {
  display: block;
}

.slide-nav_count span.data-count {
  position: relative;
}

.slide-nav_count span.data-count:before {
  content: attr(data-count);
  font-size: 51px;
  line-height: 51px;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .slide-nav_count span.data-count:before {
    font-size: 40px;
    line-height: 1.2;
  }
}

.slide-nav_count span sup {
  font-size: 21px;
  line-height: 21px;
  vertical-align: super;
}

/* ---Sticker---*/
.sticker {
  background-color: #D2A35C;
  color: #ffffff;
  position: absolute;
  padding: 0 15px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  top: 20px;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.sticker.secondary-color {
  background-color: #1d1d1d;
}

/* ---Product Information--- */
.product-info {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .product-info {
    flex-direction: column-reverse;
  }
}

.product-info .customer-feedback {
  display: flex;
  align-items: center;
}

.product-info .customer-feedback .rating-box {
  padding-right: 5px;
}

.product-info .customer-feedback span {
  color: #999999;
  font-size: 12px;
}

.product-info .product-stock span {
  font-size: 12px;
}

.product-info .product-stock span:before {
  content: '\ea20';
  font-family: LaStudioIcons;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid;
  font-size: 8px;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  color: #86BA45;
  margin-left: 5px;
  margin-right: 5px;
}

/* ---Tooltip--- */
.tooltip {
  z-index: 10;
}

.tooltip-inner {
  padding: 0 8px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 5px 5px 0;
}

.tooltip-inner {
  background-color: #1d1d1d;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #1d1d1d;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1d1d1d;
}

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #1d1d1d;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #1d1d1d;
}

/* ---Grid Item--- */
.masonry-grid {
  margin-left: -10px;
  margin-right: -10px;
}

.masonry-grid .grid-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.masonry-grid .grid-item .single-img {
  height: 100%;
}

.masonry-grid .grid-item .single-img .inner-img {
  height: 100%;
}

.masonry-grid .grid-item .single-img .inner-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.masonry-grid .grid-item .sub-masonry_grid {
  margin-left: -10px;
  margin-right: -10px;
}

.masonry-grid .grid-item .sub-masonry_grid .sub-grid_item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

/* ---Mousemove Wrap--- */
.mousemove-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

/* ---Blockquote--- */
blockquote.style-01 {
  background-color: #2F2F2F;
  text-align: center;
  border: none;
  padding: 40px;
}

blockquote.style-01 p {
  font-family: "Playfair Display";
  color: #ffffff;
  width: 480px;
  font-size: 18px;
  margin: 0 auto 0;
}

@media (max-width: 767px) {
  blockquote.style-01 p {
    width: 100%;
  }
}

blockquote .cite-wrap .user-name {
  color: #ffffff;
}

blockquote.style-02 {
  border-left: 4px solid #1d1d1d;
  color: #1d1d1d;
  padding-left: 30px;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Playfair Display";
  margin-bottom: 5px;
}

blockquote.style-02 .cite-wrap {
  line-height: 1;
}

blockquote.style-02 .cite-wrap .user-name {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: #1d1d1d;
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-- Scroll To Top --*/
.scroll-to-top {
  background-color: #1d1d1d;
  color: #ffffff;
  position: fixed;
  right: 50px;
  bottom: 90px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  z-index: 999;
  font-size: 20px;
  overflow: hidden;
  display: block;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  animation: fadeOutRight 1s normal;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  animation: skudSlideRight 1s normal;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
}

/* ---Rating Box--- */
.rating-box ul li {
  display: inline-block;
}

.rating-box ul li i {
  color: #dc9b0e;
  font-size: 12px;
}

.rating-box ul li.silver-color i {
  color: #bbbbbb;
}

.rating-box ul li.secondary-color i {
  color: #1d1d1d;
}

/* ---Price Box--- */
.price-box span.new-price.radical-red_color {
  color: #F53E6A;
}

.price-box span.new-price.primary-color {
  color: #D2A35C;
}

.price-box span.old-price {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  color: #999999;
}

/* ---Tag--- */
.tags-list {
  display: flex;
}

@media (max-width: 479px) {
  .tags-list {
    flex-direction: column;
  }
}

.tags-list span {
  text-transform: uppercase;
  color: #1d1d1d;
  font-weight: 600;
  padding-right: 5px;
}

.tags-list li {
  display: inline-block;
}

.tags-list li a {
  text-transform: lowercase;
}

/* ---Quantity--- */
.quantity .cart-plus-minus {
  position: relative;
  width: 76px;
  height: 55px;
  text-align: left;
}

.quantity .cart-plus-minus > .cart-plus-minus-box {
  border: 1px solid #d8d8d8;
  height: 55px;
  text-align: center;
  width: 48px;
  background: #ffffff;
}

.quantity .cart-plus-minus > .qtybutton {
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  cursor: pointer;
  position: absolute;
  text-align: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 10px;
}

.quantity .cart-plus-minus > .qtybutton.dec {
  bottom: 0;
  right: 0;
}

.quantity .cart-plus-minus > .qtybutton.inc {
  border-bottom: none;
  top: 0;
  right: 0;
}

/* ---Range Slider--- */
.price-filter .ui-widget-content {
  background-color: #d8d8d8;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  height: 5px;
  margin: 0 0 25px;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.price-filter .ui-slider-range {
  background-color: #1d1d1d;
  border: none;
  box-shadow: none;
  height: 5px;
}

.price-filter .ui-slider-handle {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background: #ffffff;
  display: block;
  outline: none;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  top: 50%;
  left: 0;
  border-radius: 100%;
  transform: translateY(-50%);
  cursor: e-resize;
}

.price-filter .ui-slider-handle:last-child {
  transform: translateY(-50%);
}

.price-filter .ui-slider-handle:focus {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  outline-color: #ffffff;
  border-color: #ffffff;
}

.price-filter .price-slider-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-filter .price-slider-amount .range-btn {
  border: 2px solid #707070;
  width: 110px;
  height: 40px;
  line-height: 39px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .price-filter .price-slider-amount .range-btn {
    width: 40px;
    font-size: 14px;
  }
}

.price-filter .price-slider-amount .range-btn:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
  color: #ffffff;
}

.price-filter .price-slider-amount .label-input label {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.price-filter .price-slider-amount .label-input input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  font-size: 14px;
  height: inherit;
  padding-left: 5px;
  width: 99px;
}

/* ---Color Option--- */
ul.color-option li label {
  cursor: pointer;
}

ul.color-option li label:before {
  border-radius: 100%;
  border: 0;
}

ul.color-option li label:after {
  content: '\ea20';
}

ul.color-option li input[type="checkbox"]:checked + label:before {
  border: 0;
}

ul.color-option li input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

ul.color-option li.black-color label:before {
  background-color: black;
}

ul.color-option li.blue-color label:before {
  background-color: blue;
}

ul.color-option li.gray-color label:before {
  background-color: gray;
}

ul.color-option li.green-color label:before {
  background-color: green;
}

ul.color-option li:hover label {
  color: #D2A35C;
}

ul.color-option li:hover label:after {
  opacity: 1;
}

/* ---Widgets Checkbox--- */
.widgets-checkbox li label:after {
  content: '\ea20';
}

.widgets-checkbox li input[type="checkbox"]:checked + label:before {
  background-color: #D2A35C;
  border-color: #D2A35C;
}

.widgets-checkbox li input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.widgets-checkbox li:hover label {
  color: #D2A35C;
  cursor: pointer;
}

.widgets-checkbox li:hover label:before {
  background-color: #D2A35C;
  border-color: #D2A35C;
}

.widgets-checkbox li:hover label:after {
  opacity: 1;
}

/* ---Countdown--- */
.countdown-wrap .countdown.item-4 .countdown__item {
  display: inline-block;
  position: relative;
  text-align: center;
  margin-left: 40px;
  position: relative;
}

@media (max-width: 575px) {
  .countdown-wrap .countdown.item-4 .countdown__item {
    margin-left: 20px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item:first-child {
  margin-left: 0;
}

.countdown-wrap .countdown.item-4 .countdown__item span {
  text-transform: capitalize;
  line-height: 1;
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
  font-size: 49px;
}

@media (max-width: 767px) {
  .countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
    font-size: 25px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
  display: block;
  font-size: 18px;
  padding-top: 15px;
}

@media (max-width: 767px) {
  .countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
    font-size: 14px;
  }
}

.countdown-wrap.white-text_color span {
  color: #ffffff;
}

/* ---Image Hover Effect--- */
.img-hover_effect.single-img {
  position: relative;
  overflow: hidden;
}

.img-hover_effect.single-img:before {
  background-color: #000000;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}

.img-hover_effect.single-img img {
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -ms-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

.img-hover_effect:hover.single-img:before {
  opacity: 0.5;
}

.img-hover_effect:hover.single-img img {
  transform: scale(1.1);
}

/* ---Newsletters Popup--- */
.popup_wrapper {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

@media (max-width: 991px) {
  .popup_wrapper {
    display: none;
  }
}

.popup_wrapper .test {
  background: #ffffff;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  height: 425px;
  left: 0;
  margin: auto;
  max-width: 870px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 992px) {
  .popup_wrapper .test {
    max-width: 530px;
  }
}

.popup_wrapper .test .popup_off {
  color: #1d1d1d;
  top: 20px;
  right: 20px;
  display: block;
  cursor: pointer;
  position: absolute;
  font-size: 25px;
  line-height: 1;
}

.popup_wrapper .test .popup_off:hover i {
  color: #D2A35C;
  transform: rotate(180deg);
}

.popup_wrapper .subscribe-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.popup_wrapper .subscribe-area .subscribe-content {
  text-align: center;
  width: calc(100% - 45px);
}

.popup_wrapper .subscribe-area .subscribe-content h2.heading {
  font-size: 14px;
  line-height: 16.8px;
  max-width: 59%;
  margin: 0 auto;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form {
  position: relative;
  width: 310px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form input {
  border-bottom: 2px solid #1d1d1d;
  background: #ffffff;
  color: #1d1d1d;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  width: 100%;
  font-size: 14px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form input::placeholder {
  color: #1d1d1d;
  opacity: 1;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form .subscribe-bottom {
  text-align: left;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form .subscribe-bottom label.label-field {
  font-size: 14px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form .subscribe-bottom input[type="checkbox"]:checked + label::after {
  color: #13aff0;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group button.subscribe-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
}

.popup_wrapper .subscribe-area .subscribe-img {
  position: relative;
  cursor: pointer;
}

.popup_wrapper .subscribe-area .subscribe-img:before {
  background-color: #1d1d1d;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
}

.popup_wrapper .subscribe-area .subscribe-img .inner-content {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  text-align: center;
}

.popup_wrapper .subscribe-area .subscribe-img .inner-content h3.heading {
  max-width: 75%;
  margin: 0 auto;
}

.popup_wrapper .subscribe-area .subscribe-img:hover:before {
  opacity: 0.5;
}

.popup_wrapper .subscribe-area .subscribe-img:hover .inner-content .button-wrap a.skudmart-btn {
  background-color: #1d1d1d;
  color: #ffffff;
}

/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-charcoal_color {
  background-color: #454545;
}

.bg-denim_color {
  background-color: #D2A35C;
}

.bg-smoke_color {
  background-color: #f7f7f7;
}

.bg-white_color {
  background-color: #ffffff;
}

.bg-snow_color {
  background-color: #fcfcfc;
}

.bg-dark_color {
  background-color: #1d1d1d;
}

.bg-jaguar_color {
  background-color: #181531;
}

.bg-midnight_color {
  background-color: #262835;
}

.bg-niro_color {
  background-color: #202020;
}

.bg-primary_color {
  background-color: #D2A35C;
}

/* ---Text Color Variation--- */
.white-text * {
  color: #ffffff;
}

.text-neon_blue * {
  color: #4241FF;
}

.secondary-text * {
  color: #1d1d1d;
}

.text-color * {
  color: #707070;
}

/* ---Template Color Variation--- */
.template-color-1 {
  /* ---Main Menu--- */
  /* ---Transparent Header--- */
  /* ---Slick Arrow--- */
  /* ---Testimonial--- */
  /* ---Scroll To Top--- */
}

.template-color-1 a:hover {
  color: #D2A35C;
}

.template-color-1 .main-menu .main-nav ul li:hover > a {
  color: #D2A35C;
}

.template-color-1 .main-menu .main-nav ul li a.active {
  color: #D2A35C;
}

.template-color-1 .main-menu .main-nav ul li > ul.skudmart-dropdown li:hover > a {
  text-decoration: underline;
  color: #D2A35C;
}

.template-color-1 .main-menu.menu-style_three .main-nav > ul > li:hover > a {
  background-color: #1d1d1d;
  color: #ffffff;
}

.template-color-1 .white-color .main-nav ul li a.active {
  color: #D2A35C;
}

.template-color-1 .slick-arrow {
  color: #D2A35C;
}

.template-color-1 .slick-arrow:hover {
  background-color: #D2A35C;
  color: #ffffff;
}

.template-color-1 .testimonial-item.style-2 .single-img:after {
  color: #D2A35C;
}

.template-color-1 .scroll-to-top:hover {
  background-color: #D2A35C;
}

.template-color-2 {
  /* ---Main Menu--- */
  /* ---Transparent Header--- */
  /* ---Slick Arrow--- */
  /* ---Testimonial--- */
  /* ---Scroll To Top--- */
}

.template-color-2 a:hover {
  color: #7aedc9;
}

.template-color-2 .main-menu .main-nav ul li:hover > a {
  color: #7aedc9;
}

.template-color-2 .main-menu .main-nav ul li a.active {
  color: #7aedc9;
}

.template-color-2 .main-menu .main-nav ul li > ul.skudmart-dropdown li:hover > a {
  text-decoration: underline;
  color: #7aedc9;
}

.template-color-2 .main-menu.menu-style_three .main-nav > ul > li:hover > a {
  background-color: #1d1d1d;
  color: #ffffff;
}

.template-color-2 .white-color .main-nav ul li a.active {
  color: #7aedc9;
}

.template-color-2 .slick-arrow {
  color: #7aedc9;
}

.template-color-2 .slick-arrow:hover {
  background-color: #7aedc9;
  color: #ffffff;
}

.template-color-2 .testimonial-item.style-2 .single-img:after {
  color: #7aedc9;
}

.template-color-2 .scroll-to-top:hover {
  background-color: #7aedc9;
}

/*----------------------------------------*/
/*  10 - Components - Navigation
/*----------------------------------------*/
/* ---Navigation and pagination wrap--- */
.nav-pagination_wrap .swiper-pagination {
  display: none;
}

@media (max-width: 991px) {
  .nav-pagination_wrap .swiper-pagination {
    display: block;
  }
}

.nav-pagination_wrap .swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #fe4a49;
}

.nav-pagination_wrap .swiper-button-next,
.nav-pagination_wrap .swiper-button-prev {
  display: flex;
}

@media (max-width: 991px) {
  .nav-pagination_wrap .swiper-button-next,
  .nav-pagination_wrap .swiper-button-prev {
    display: none;
  }
}

.nav-pagination_wrap .swiper-button-next {
  right: 80px;
}

@media (max-width: 1500px) {
  .nav-pagination_wrap .swiper-button-next {
    right: 40px;
  }
}

.nav-pagination_wrap .swiper-button-prev {
  left: 80px;
}

@media (max-width: 1500px) {
  .nav-pagination_wrap .swiper-button-prev {
    left: 40px;
  }
}

.nav-pagination_wrap .swiper-button-disabled {
  display: none;
}

/* ---Swiper Arrow--- */
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #1d1d1d;
}

.darkNav .swiper-button-next,
.darkNav .swiper-button-prev {
  border-color: #1d1d1d;
  color: #1d1d1d;
}

/* ---Swiper Circle Arrow--- */
.circle-arrow .swiper-button-next,
.circle-arrow .swiper-button-prev {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  opacity: 0;
  visibility: hidden;
}

.circle-arrow .swiper-button-next:after,
.circle-arrow .swiper-button-prev:after {
  font-size: 16px;
}

.circle-arrow .swiper-button-next:hover,
.circle-arrow .swiper-button-prev:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
}

.circle-arrow:hover .swiper-button-next,
.circle-arrow:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.circle-arrow.arrow-rounded .swiper-button-next,
.circle-arrow.arrow-rounded .swiper-button-prev {
  border-radius: 0;
}

/* ---Modal Arrow--- */
.modal-slider:hover .modal-button-next, .modal-slider:hover .modal-button-prev {
  opacity: 1;
  visibility: visible;
}

.modal-slider:hover .modal-button-next {
  right: 10px;
}

.modal-slider:hover .modal-button-prev {
  left: 10px;
}

.modal-button-next, .modal-button-prev {
  background-color: #ffffff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.modal-button-next:hover, .modal-button-prev:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

.modal-button-next i, .modal-button-prev i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-button-next {
  left: auto;
  right: 0;
}

/* ---With Background--- */
.with-bg_white .swiper-button-next,
.with-bg_white .swiper-button-prev {
  background-color: #ffffff;
  border: 0;
  color: #1d1d1d;
}

.with-bg_white .swiper-button-next:hover,
.with-bg_white .swiper-button-prev:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

/* ---Swiper Arrow Size--- */
.swiper-arrow_sm .swiper-button-next,
.swiper-arrow_sm .swiper-button-prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

/* ---Verticle Arrows--- */
.verticle-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.verticle-arrow .swiper-button-next {
  display: block;
}

/* ---Swiper Arrow Wrap--- */
.swiper-arrow_wrap.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 0;
}

.swiper-arrow_wrap.bottom-left .swiper-button-next {
  left: 70px;
}

.swiper-arrow_wrap.bottom-left .swiper-button-prev {
  left: 0;
}

.swiper-arrow_wrap.without-background .swiper-button-next,
.swiper-arrow_wrap.without-background .swiper-button-prev {
  background-color: transparent;
  color: #ccc;
  width: auto;
  height: auto;
}

.swiper-arrow_wrap.without-background .swiper-button-next:after,
.swiper-arrow_wrap.without-background .swiper-button-prev:after {
  font-size: 40px;
}

.swiper-arrow_wrap.without-background .swiper-button-next:hover,
.swiper-arrow_wrap.without-background .swiper-button-prev:hover {
  color: #1d1d1d;
}

/* ---Swiper Pagination--- */
.swiper-pagination.white-color span.swiper-pagination-bullet {
  background-color: #ffffff;
}

.swiper-pagination.white-color span.swiper-pagination-bullet:hover {
  opacity: 1;
}

.swiper-pagination.white-color span.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination.black-color span.swiper-pagination-bullet {
  background-color: #000000;
}

.swiper-pagination.black-color span.swiper-pagination-bullet:hover {
  opacity: 1;
}

.swiper-pagination.black-color span.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination.dark-border span.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.swiper-pagination.dark-border span.swiper-pagination-bullet-active {
  border: 2px solid #1d1d1d;
  background-color: transparent;
}

.swiper-pagination.vertical-pagination {
  top: 50%;
  bottom: auto;
  left: auto;
  right: 80px;
  transform: translateY(-50%);
  width: auto;
}

@media (max-width: 991px) {
  .swiper-pagination.vertical-pagination {
    right: 30px;
  }
}

.swiper-pagination.vertical-pagination span.swiper-pagination-bullet {
  margin: 30px 0;
  display: block;
  width: 15px;
  height: 15px;
  position: relative;
}

@media (max-width: 991px) {
  .swiper-pagination.vertical-pagination span.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 25px 0;
  }
}

.swiper-pagination.vertical-pagination span.swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  left: calc(-1*(16px - 4px));
  top: calc(-1*(16px - 4px));
  bottom: calc(-1*(16px - 4px));
  right: calc(-1*(16px - 4px));
  border: 1px solid #1d1d1d;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.5);
}

.swiper-pagination.vertical-pagination span.swiper-pagination-bullet:after {
  content: '';
  position: absolute;
  left: calc(-1*(16px + 4px));
  top: calc(-1*(16px + 4px));
  bottom: calc(-1*(16px + 4px));
  right: calc(-1*(16px + 4px));
  border: 1px solid #1d1d1d;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.5);
}

.swiper-pagination.vertical-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 991px) {
  .swiper-pagination.vertical-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    transform: scale(0.6);
  }
}

.swiper-pagination.vertical-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 991px) {
  .swiper-pagination.vertical-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    transform: scale(0.6);
  }
}

.swiper-pagination.with-position_relative {
  position: relative;
  padding-top: 45px;
  bottom: 0;
}

/* ---Slick Arrow--- */
.slick-arrow {
  background-color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 42px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 14px;
  z-index: 1;
  transform: translateY(-50%);
}

.slick-arrow.slick-next {
  left: auto;
  right: 0;
}

/*----------------------------------------*/
/*  11 - Components - Animation
/*----------------------------------------*/
.swiper-slide .inner-slide .slide-content {
  opacity: 0;
}

.swiper-slide.swiper-slide-active .inner-slide .slide-content {
  opacity: 1;
}

/* ---Slider Animation--- */
.animation-style-01.swiper-slide-active .slide-content span.category {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content h1.heading {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content .price-box {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content .group-btn .secondary-btn {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content .group-btn .primary-btn {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content span.category {
  animation-name: fadeInLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content span.product-offer {
  animation-name: fadeInLeft;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-03.swiper-slide-active .slide-img {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-03.swiper-slide-active .slide-content span.category {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-03.swiper-slide-active .slide-content h2.heading {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-03.swiper-slide-active .slide-content .price-box {
  animation-name: fadeInUp;
  animation-delay: 2.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-03.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-img {
  animation-name: fadeInRight;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-content h1.heading {
  animation-name: fadeInLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-content .color-option span.title {
  animation-name: fadeInLeft;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-content .color-option ul li a {
  animation-name: colorList;
  animation-delay: 1.5s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-content .color-option ul li:nth-child(1) a {
  animation-duration: 1s;
}

.animation-style-04.swiper-slide-active .slide-content .color-option ul li:nth-child(2) a {
  animation-duration: 1.5s;
}

.animation-style-04.swiper-slide-active .slide-content .color-option ul li:nth-child(3) a {
  animation-duration: 2s;
}

.animation-style-04.swiper-slide-active .slide-content .price-box {
  animation-name: fadeInLeft;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-04.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 2.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content .slide-img {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content h3.heading {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content .product-offer {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content p.short-desc {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content .read-more {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-05.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-content .category {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-content .product-offer {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-img .inner-img {
  animation-name: zoomIn;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-img .front-img {
  animation-name: fadeIn;
  animation-delay: 1.5s;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}

.animation-style-06.swiper-slide-active .slide-img .sticker-img {
  animation-name: skudZoomIn;
  animation-delay: 1.8s;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content .slide-img {
  animation-name: skudZoomIn;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content h1.heading {
  animation-name: skudZoomIn;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content .product-collection {
  animation-name: fadeIn;
  animation-delay: 1.2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content .product-collection::before {
  animation-name: skudSlideRight;
  animation-delay: 1.3s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content .product-collection::after {
  animation-name: skudSlideLeft;
  animation-delay: 1.4s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animation-style-07.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInUp;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

/* ---Animated Image--- */
.animated-img.style-01 {
  position: relative;
  text-align: center;
  animation: sk-rotate 7.0s infinite linear;
  width: 35%;
}

@media (max-width: 767px) {
  .animated-img.style-01 {
    top: 20%;
  }
}

@media (max-width: 575px) {
  .animated-img.style-01 {
    top: 50%;
  }
}

@media (max-width: 479px) {
  .animated-img.style-01 {
    width: 45%;
  }
}

.animated-img.style-01 img {
  margin: 5%;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* ---Color List--- */
@keyframes colorList {
  from {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  to {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}

/* ---Skudmart's Zoom In--- */
@keyframes skudZoomIn {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* ---skudmart's Slide Left--- */
@keyframes skudSlideLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* ---skudmart's Slide Right--- */
@keyframes skudSlideRight {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/*skudmart's Slide Up Animation*/
@keyframes skudSlideInUp {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*Top Down Keyframes*/
@-webkit-keyframes skudSlideInDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes skudSlideInDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

.skudSlideInDown {
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.kenburns-top {
  animation: kenburns-top 5s linear 1 normal both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-13 17:17:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@keyframes kenburns-top {
  0% {
    transform: scale(1) translateY(0);
    transform-origin: 50% 16%;
  }
  100% {
    transform: scale(1.1) translateY(-15px);
    transform-origin: top;
  }
}

/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---Skudmart's Button Position--- */
.button-wrap {
  display: flex;
}

.button-wrap.position-center {
  justify-content: center;
}

/* ---Group Button--- */
.group-btn {
  display: flex;
  justify-content: center;
}

.group-btn li:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 479px) {
  .group-btn li:not(:last-child) {
    margin-right: 15px;
  }
}

/* ---skudmart's Button--- */
.skudmart-btn {
  width: 140px;
  height: 45px;
  line-height: 45px;
  display: block;
  text-align: center;
  font-size: 14px;
}

.skudmart-btn.primary-btn {
  background-color: #1d1d1d;
  color: #ffffff;
}

.skudmart-btn.secondary-btn {
  background-color: #fe4a49;
}

.skudmart-btn.aquamarine-btn {
  background-color: #7aedc9;
  color: #1d1d1d;
}

.skudmart-btn.apache-btn {
  background-color: #D2A35C;
  color: #ffffff;
}

.skudmart-btn.aluminium-btn {
  background-color: #818a91;
  color: #ffffff;
}

.skudmart-btn.aluminium-btn:hover {
  color: #ffffff;
}

.skudmart-btn.valhalla-btn {
  background-color: #2d2d48;
  color: #ffffff;
}

.skudmart-btn.radical-red_btn {
  background-color: #F53E6A;
  color: #ffffff;
}

.skudmart-btn.white-btn {
  background-color: #ffffff;
  color: #1d1d1d;
}

.skudmart-btn.btn-with_border {
  border: 1px solid #1d1d1d;
  background-color: transparent;
  color: #1d1d1d;
}

.skudmart-btn.btn-with_border:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

.skudmart-btn.white-border_btn {
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
}

.skudmart-btn.white-border_btn:hover {
  background-color: #ffffff;
  color: #1d1d1d;
}

.skudmart-btn.gainsboro-border_btn {
  border: 2px solid #d8d8d8;
  background-color: transparent;
  color: #1d1d1d;
}

.skudmart-btn.gainsboro-border_btn:hover {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.skudmart-btn.secondary-border {
  border: 1px solid #fe4a49;
  color: #fe4a49;
}

.skudmart-btn.primary-hover:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
  color: #ffffff;
}

.skudmart-btn.aquamarine-hover:hover {
  background-color: #7aedc9;
  border-color: #7aedc9;
  color: #ffffff;
}

.skudmart-btn.white-hover:hover {
  background-color: #ffffff;
  color: #000000;
}

.skudmart-btn.secondary-hover:hover {
  background-color: #fe4a49;
  border-color: #fe4a49;
  color: #ffffff;
}

.skudmart-btn.nero-hover:hover {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.skudmart-btn.persian-blue-hover:hover {
  background-color: #2f21b3;
  border-color: #2f21b3;
  color: #ffffff;
}

.skudmart-btn.gradient-hover:hover {
  background: linear-gradient(to 270deg, #f64d4d 0%, #c965b7 100%);
  color: #ffffff;
}

.skudmart-btn.radius-2 {
  border-radius: 2px;
}

.skudmart-btn.radius-10 {
  border-radius: 10px;
}

.skudmart-btn.radius-18 {
  border-radius: 18px;
}

.skudmart-btn.radius-50 {
  border-radius: 50px;
}

/* ---Button Size--- */
.fullwidth-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
}

.btn-xs_size {
  width: 80px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.btn-sm_size {
  width: 90px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.btn-md_size {
  width: 120px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.btn-lg_size {
  width: 150px;
  height: 55px;
  line-height: 55px;
}

.btn-xl_size {
  width: 200px;
  height: 70px;
  line-height: 70px;
}

/* ---Button With Padding--- */
.btn-with_padding {
  font-size: 14px;
  display: block;
  padding: 15px 30px;
}

.btn-with_padding.primary-btn {
  background-color: #1d1d1d;
  color: #ffffff;
}

.btn-with_padding.aluminium-btn {
  background-color: #818a91;
  color: #ffffff;
}

.btn-with_padding.aluminium-btn:hover {
  color: #ffffff;
}

.btn-with_padding.neon-blue_btn {
  background-color: #4241FF;
  color: #ffffff;
}

.btn-with_padding.neon-blue_btn:hover {
  color: #ffffff;
}

.btn-with_padding.fern-btn {
  background-color: #5cb85c;
  color: #ffffff;
}

.btn-with_padding.fern-btn:hover {
  color: #ffffff;
}

.btn-with_padding.casablanca-btn {
  background-color: #f0ad4e;
  color: #ffffff;
}

.btn-with_padding.casablanca-btn:hover {
  color: #ffffff;
}

.btn-with_padding.cerulean-btn {
  background-color: #2C51D6;
  color: #ffffff;
}

.btn-with_padding.blue-violet_btn {
  background-color: #7817E8;
  color: #ffffff;
}

.btn-with_padding.turquoise-btn {
  background-color: #02deea;
  color: #ffffff;
}

.btn-with_padding.persian-blue {
  background-color: #2f21b3;
  border-color: #2f21b3;
  color: #ffffff;
}

.btn-with_padding.persian-blue:hover {
  color: #ffffff;
}

.btn-with_padding.btn-with_border {
  border: 1px solid #1d1d1d;
  background-color: transparent;
  color: #1d1d1d;
}

.btn-with_padding.btn-with_border:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

.btn-with_padding.secondary-border {
  border: 1px solid #fe4a49;
  color: #fe4a49;
}

.btn-with_padding.gainsboro-border_btn {
  border: 2px solid #d8d8d8;
  background-color: transparent;
  color: #1d1d1d;
}

.btn-with_padding.gainsboro-border_btn:hover {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.btn-with_padding.secondary-hover:hover {
  background-color: #fe4a49;
  border-color: #fe4a49;
  color: #ffffff;
}

.btn-with_padding.border-style-double {
  border-style: double;
  border-width: 4px 4px 4px 4px;
  border-color: #1d1d1d;
}

.btn-with_padding.border-style-dotted {
  border-style: dotted;
  border-width: 2px 2px 2px 2px;
  border-color: #1d1d1d;
}

.btn-with_padding.border-style-dashed {
  border-style: dashed;
  border-width: 2px 2px 2px 2px;
  border-color: #1d1d1d;
}

.btn-with_padding.border-width-2 {
  border: 2px solid #1d1d1d;
}

.btn-with_padding.border-width-3 {
  border: 3px solid #1d1d1d;
}

.btn-with_padding.border-width-4 {
  border: 4px solid #1d1d1d;
}

.btn-with_padding.p-8 {
  padding: 8px;
}

.btn-with_padding.p-12 {
  padding: 12px;
}

.btn-with_padding.p-16 {
  padding: 16px;
}

.btn-with_padding.p-20 {
  padding: 20px;
}

/* ---Button Animation--- */
.grow-animation {
  transform: scale(1);
}

.grow-animation:hover {
  transform: scale(1.1);
}

.shrink-animation {
  transform: scale(1);
}

.shrink-animation:hover {
  transform: scale(0.9);
}

.pulse-animation:hover {
  animation-name: pulse-animation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pulse-grow-animation:hover {
  animation-name: pulse-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pulse-animation {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.15, 1.15, 1.15);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

/* ---Additional Button--- */
.read-more {
  position: relative;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.read-more:before {
  border-top: 1px solid;
  width: 30px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.read-more:after {
  border-top: 1px solid;
  width: 30px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.read-more.without-left_border:before {
  content: none;
  margin-right: 0;
}

/* ---More Product--- */
.more-product {
  font-size: 12px;
}

.more-product i {
  vertical-align: middle;
}

/*----------------------------------------*/
/*  13 - Components - Slider
/*----------------------------------------*/
/* ---Main Slider--- */
.main-slider .swiper-slide .inner-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-slider .swiper-slide .inner-slide.bg-1, .main-slider .swiper-slide .inner-slide.bg-2, .main-slider .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/1-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
}

@media (max-width: 1500px) {
  .main-slider .swiper-slide .inner-slide.bg-1, .main-slider .swiper-slide .inner-slide.bg-2, .main-slider .swiper-slide .inner-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  .main-slider .swiper-slide .inner-slide.bg-1, .main-slider .swiper-slide .inner-slide.bg-2, .main-slider .swiper-slide .inner-slide.bg-3 {
    height: 445px;
  }
}

@media (max-width: 767px) {
  .main-slider .swiper-slide .inner-slide.bg-1, .main-slider .swiper-slide .inner-slide.bg-2, .main-slider .swiper-slide .inner-slide.bg-3 {
    height: 380px;
  }
}

.main-slider .swiper-slide .inner-slide.bg-1.bg-2, .main-slider .swiper-slide .inner-slide.bg-2.bg-2, .main-slider .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/1-2.jpg");
}

.main-slider .swiper-slide .inner-slide.bg-1.bg-3, .main-slider .swiper-slide .inner-slide.bg-2.bg-3, .main-slider .swiper-slide .inner-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/1-3.jpg");
}

.main-slider .swiper-slide .inner-slide .slide-content {
  text-align: center;
}

.main-slider .swiper-slide .inner-slide .slide-content span.category {
  font-size: 13px;
  line-height: 1.2;
  padding-bottom: 20px;
  letter-spacing: 7px;
  display: block;
}

.main-slider .swiper-slide .inner-slide .slide-content span.category.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  letter-spacing: 1px;
  font-size: 17px;
  line-height: 22px;
}

.main-slider .swiper-slide .inner-slide .slide-content h1.heading {
  font-size: 53px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 2px;
  padding-bottom: 35px;
}

@media (max-width: 767px) {
  .main-slider .swiper-slide .inner-slide .slide-content h1.heading {
    font-size: 25px;
  }
}

/* ---Main Slider Two--- */
.main-slider-2 .swiper-slide .inner-slide {
  display: flex;
  align-items: center;
}

.main-slider-2 .swiper-slide .inner-slide.bg-1, .main-slider-2 .swiper-slide .inner-slide.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/2-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
}

@media (max-width: 1500px) {
  .main-slider-2 .swiper-slide .inner-slide.bg-1, .main-slider-2 .swiper-slide .inner-slide.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  .main-slider-2 .swiper-slide .inner-slide.bg-1, .main-slider-2 .swiper-slide .inner-slide.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-3 {
    height: 445px;
  }
}

@media (max-width: 767px) {
  .main-slider-2 .swiper-slide .inner-slide.bg-1, .main-slider-2 .swiper-slide .inner-slide.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-3 {
    height: 380px;
  }
}

.main-slider-2 .swiper-slide .inner-slide.bg-1.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-2.bg-2, .main-slider-2 .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/2-2.jpg");
}

.main-slider-2 .swiper-slide .inner-slide.bg-1.bg-3, .main-slider-2 .swiper-slide .inner-slide.bg-2.bg-3, .main-slider-2 .swiper-slide .inner-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/2-3.jpg");
}

.main-slider-2 .swiper-slide .inner-slide .slide-content {
  text-align: center;
  display: inline-block;
}

.main-slider-2 .swiper-slide .inner-slide .slide-content span {
  color: #1d1d1d;
  display: block;
}

.main-slider-2 .swiper-slide .inner-slide .slide-content span.category {
  font-size: 24px;
  line-height: 28px;
}

.main-slider-2 .swiper-slide .inner-slide .slide-content span.product-offer {
  position: relative;
  font-size: 36px;
  line-height: 42px;
}

.main-slider-2 .swiper-slide .inner-slide .slide-content span.product-offer strong {
  font-size: 119px;
  line-height: 139px;
  vertical-align: middle;
  font-weight: 400;
}

.main-slider-2 .swiper-slide .inner-slide .slide-content span.product-offer sup {
  vertical-align: super;
}

@media (min-width: 768px) {
  .main-slider-2 .swiper-slide .inner-slide .slide-content.specific-direction {
    position: relative;
    left: 60%;
    text-align: left;
  }
}

/* ---Main Slider Three--- */
.main-slider-3 .swiper-slide .inner-slide {
  display: flex;
  align-items: center;
  position: relative;
}

.main-slider-3 .swiper-slide .inner-slide.bg-1 {
  background-image: url("../images/slider/bg/3-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.main-slider-3 .swiper-slide .inner-slide.item-height {
  height: 850px;
}

@media (max-width: 1500px) {
  .main-slider-3 .swiper-slide .inner-slide.item-height {
    height: 610px;
  }
}

@media (max-width: 1199px) {
  .main-slider-3 .swiper-slide .inner-slide.item-height {
    height: 445px;
  }
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide.item-height {
    height: 380px;
  }
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide .container {
    height: 100%;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-img.style-01 {
  position: absolute;
  bottom: 0;
  left: 60px;
  height: 80%;
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-img.style-01 {
    left: 0;
  }
}

@media (max-width: 575px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-img.style-01 {
    height: 60%;
  }
}

@media (max-width: 575px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-img.style-01 {
    height: 40%;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-img.style-01 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

.main-slider-3 .swiper-slide .inner-slide .slide-img_wrap {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-img_wrap {
    top: 20px;
  }
}

@media (max-width: 479px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-img_wrap {
    width: 60%;
    top: 10%;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-img_wrap .slide-img.style-02 img {
  width: 90%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.main-slider-3 .swiper-slide .inner-slide .slide-img_wrap .animated-img {
  display: flex;
  justify-content: flex-end;
  animation: skud 2s infinite linear alternate;
}

.main-slider-3 .swiper-slide .inner-slide .slide-img_wrap .animated-img img {
  width: 30%;
  object-fit: cover;
}

@keyframes skud {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-content {
  position: absolute;
  bottom: 25%;
  right: 25%;
}

@media (max-width: 991px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content {
    right: 15%;
  }
}

@media (max-width: 575px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content {
    bottom: auto;
    top: 30px;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-content.vertical-position_middle {
  bottom: auto;
  top: 50%;
  right: 20%;
  transform: translateY(-50%);
}

@media (max-width: 1199px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content.vertical-position_middle {
    right: 15%;
  }
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content.vertical-position_middle {
    bottom: 20px;
    top: auto;
    right: 20%;
    transform: translate(-50%, 0);
    left: 50%;
    width: 100%;
    text-align: center;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-content span.category {
  font-size: 14px;
  display: block;
}

.main-slider-3 .swiper-slide .inner-slide .slide-content h2.heading {
  font-size: 32px;
  line-height: 45px;
}

@media (max-width: 1199px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content h2.heading {
    font-size: 20px;
    line-height: 33px;
  }
}

.main-slider-3 .swiper-slide .inner-slide .slide-content .price-box span.new-price {
  color: #1d1d1d;
}

@media (max-width: 767px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content .button-wrap {
    justify-content: center;
  }
}

@media (max-width: 1199px) {
  .main-slider-3 .swiper-slide .inner-slide .slide-content .button-wrap .skudmart-btn {
    width: 120px;
    height: 40px;
    line-height: 40px;
  }
}

/* ---Main Slider Four--- */
.main-slider-4 .swiper-slide .inner-slide {
  display: flex;
  align-items: center;
  position: relative;
  height: 940px;
}

@media (max-width: 1500px) {
  .main-slider-4 .swiper-slide .inner-slide {
    height: 730px;
  }
}

@media (max-width: 767px) {
  .main-slider-4 .swiper-slide .inner-slide {
    height: 315px;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-content {
  width: 40%;
  padding-left: 90px;
}

@media (max-width: 1199px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content {
    padding-left: 30px;
  }
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content {
    width: 100%;
    z-index: 1;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-content h1.heading {
  font-size: 46px;
  line-height: 54px;
  max-width: 65%;
}

@media (max-width: 1500px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content h1.heading {
    font-size: 40px;
    max-width: 85%;
  }
}

@media (max-width: 991px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content h1.heading {
    max-width: 100%;
    font-size: 26px;
    line-height: 1.4;
  }
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content h1.heading {
    color: #ffffff;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option span.title {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 14px;
  display: block;
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content .color-option span.title {
    color: #ffffff;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li {
  display: inline-block;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li:not(:last-child) {
  margin-right: 5px;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li:nth-child(1) a {
  transition-delay: 100ms;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li:nth-child(2) a {
  transition-delay: 150ms;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li:nth-child(3) a {
  transition-delay: 200ms;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li a {
  display: block;
  width: 17px;
  height: 17px;
  transform: scaleY(0);
  transform-origin: bottom;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li.text-color a {
  background-color: #707070;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li.secondary-color a {
  background-color: #1d1d1d;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .color-option ul li.primary-color a {
  background-color: #D2A35C;
}

.main-slider-4 .swiper-slide .inner-slide .slide-content .price-box span.new-price {
  color: #1d1d1d;
  font-size: 25px;
  line-height: 30px;
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content .price-box span.new-price {
    color: #ffffff;
  }
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-content .button-wrap .skudmart-btn {
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .main-slider-4 .swiper-slide .inner-slide .slide-content .button-wrap .skudmart-btn:hover {
    border-color: #1d1d1d;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 60%;
  position: absolute;
  right: 0;
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-img {
    width: 100%;
  }
}

.main-slider-4 .swiper-slide .inner-slide .slide-img.bg-1 {
  background-image: url("../images/slider/slide-img/4-1-1145x1040.jpg");
}

.main-slider-4 .swiper-slide .inner-slide .slide-img.bg-2 {
  background-image: url("../images/slider/slide-img/4-2-1145x1040.jpg");
}

.main-slider-4 .swiper-slide .inner-slide .slide-img.bg-3 {
  background-image: url("../images/slider/slide-img/4-3-1145x1040.jpg");
}

@media (max-width: 575px) {
  .main-slider-4 .swiper-slide .inner-slide .slide-img:before {
    background-color: #1d1d1d;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
  }
}

/* ---Main Slider Five--- */
.main-slider-5 .swiper-slide .inner-slide.bg-1, .main-slider-5 .swiper-slide .inner-slide.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/5-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
}

@media (max-width: 1500px) {
  .main-slider-5 .swiper-slide .inner-slide.bg-1, .main-slider-5 .swiper-slide .inner-slide.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  .main-slider-5 .swiper-slide .inner-slide.bg-1, .main-slider-5 .swiper-slide .inner-slide.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-3 {
    height: 445px;
  }
}

@media (max-width: 767px) {
  .main-slider-5 .swiper-slide .inner-slide.bg-1, .main-slider-5 .swiper-slide .inner-slide.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-3 {
    height: 380px;
  }
}

.main-slider-5 .swiper-slide .inner-slide.bg-1.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-2.bg-2, .main-slider-5 .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/5-2.jpg");
}

.main-slider-5 .swiper-slide .inner-slide.bg-1.bg-3, .main-slider-5 .swiper-slide .inner-slide.bg-2.bg-3, .main-slider-5 .swiper-slide .inner-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/5-3.jpg");
}

.main-slider-5 .swiper-slide .inner-slide.style-01 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content span.category {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 5px;
  text-transform: uppercase;
  padding-bottom: 20px;
  display: block;
}

@media (max-width: 479px) {
  .main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content span.category {
    font-size: 14px;
  }
}

.main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content h1.heading {
  padding-bottom: 35px;
  font-weight: 400;
  font-size: 75px;
  line-height: 89px;
  letter-spacing: 5px;
}

@media (max-width: 767px) {
  .main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content h1.heading {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media (max-width: 575px) {
  .main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content h1.heading {
    font-size: 25px;
  }
}

.main-slider-5 .swiper-slide .inner-slide.style-01 .slide-content h1.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.main-slider-5 .swiper-slide .inner-slide.style-02 {
  display: flex;
  align-items: center;
}

.main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content {
  position: relative;
  left: 12%;
}

@media (max-width: 767px) {
  .main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content {
    left: 30px;
  }
}

.main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content h1.heading {
  font-size: 65px;
  line-height: 76px;
  padding-bottom: 5px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content h1.heading {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media (max-width: 575px) {
  .main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content h1.heading {
    font-size: 25px;
  }
}

.main-slider-5 .swiper-slide .inner-slide.style-02 .slide-content .price-box span.new-price {
  color: #1d1d1d;
  font-size: 36px;
  line-height: 42px;
}

/* ---Main Slider Six--- */
.main-slider-6 .swiper-slide .inner-slide.bg-1, .main-slider-6 .swiper-slide .inner-slide.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/6-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 680px;
}

@media (max-width: 1500px) {
  .main-slider-6 .swiper-slide .inner-slide.bg-1, .main-slider-6 .swiper-slide .inner-slide.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  .main-slider-6 .swiper-slide .inner-slide.bg-1, .main-slider-6 .swiper-slide .inner-slide.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-3 {
    height: 445px;
  }
}

@media (max-width: 767px) {
  .main-slider-6 .swiper-slide .inner-slide.bg-1, .main-slider-6 .swiper-slide .inner-slide.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-3 {
    background-position: center;
  }
}

.main-slider-6 .swiper-slide .inner-slide.bg-1.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-2.bg-2, .main-slider-6 .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/6-2.jpg");
}

.main-slider-6 .swiper-slide .inner-slide.bg-1.bg-3, .main-slider-6 .swiper-slide .inner-slide.bg-2.bg-3, .main-slider-6 .swiper-slide .inner-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/6-3.jpg");
}

.main-slider-6 .swiper-slide .inner-slide.style-01 {
  display: flex;
  align-items: center;
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content {
  position: relative;
  left: 12%;
}

@media (max-width: 767px) {
  .main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content {
    width: calc(100% - 30px);
    left: 15px;
  }
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content .product-offer {
  background: linear-gradient(to 90deg, #ed2dbc 0%, #ff8e4e 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  line-height: 139px;
  font-weight: 700;
  font-size: 99px;
  border-color: white;
  color: #ff374a;
  transform-origin: 50% 50% 0px;
  letter-spacing: 2px;
}

@media (max-width: 767px) {
  .main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content .product-offer {
    font-size: 67px;
    line-height: 81px;
  }
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content .product-offer.style-02 sub,
.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content .product-offer.style-02 sup {
  background: linear-gradient(to 90deg, #ed2dbc 0%, #ff8e4e 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  line-height: 42px;
  vertical-align: super;
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content span.read-more {
  color: #1d1d1d;
  text-transform: uppercase;
  display: block;
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content h3.heading {
  font-size: 46px;
  line-height: 54px;
}

@media (max-width: 575px) {
  .main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content h3.heading {
    font-size: 40px;
  }
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content h3.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 36px;
  line-height: 48px;
}

.main-slider-6 .swiper-slide .inner-slide.style-01 .slide-content p.short-desc {
  background: linear-gradient(to 90deg, #ed2dbc 0%, #ff8e4e 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 991px) {
  .main-slider-6 .swiper-pagination {
    bottom: 30px;
  }
}

/* ---Main Slider Seven--- */
.main-slider-7 .swiper-slide.bg-1, .main-slider-7 .swiper-slide.bg-2, .main-slider-7 .swiper-slide.bg-3 {
  background-image: url("../images/slider/bg/7-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 655px;
}

@media (max-width: 1500px) {
  .main-slider-7 .swiper-slide.bg-1, .main-slider-7 .swiper-slide.bg-2, .main-slider-7 .swiper-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  .main-slider-7 .swiper-slide.bg-1, .main-slider-7 .swiper-slide.bg-2, .main-slider-7 .swiper-slide.bg-3 {
    height: 440px;
  }
}

@media (max-width: 767px) {
  .main-slider-7 .swiper-slide.bg-1, .main-slider-7 .swiper-slide.bg-2, .main-slider-7 .swiper-slide.bg-3 {
    height: auto;
  }
}

.main-slider-7 .swiper-slide.bg-1.bg-2, .main-slider-7 .swiper-slide.bg-2.bg-2, .main-slider-7 .swiper-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/7-2.jpg");
}

.main-slider-7 .swiper-slide.style-01 .inner-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 767px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide {
    flex-direction: column;
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content {
  position: relative;
  text-align: center;
  width: 50%;
  z-index: 1;
}

@media (max-width: 1199px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content {
    top: 52px;
    width: 100%;
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content span.category {
  text-transform: uppercase;
  display: block;
}

@media (max-width: 991px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content span.category {
    font-size: 14px;
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content h4.product-offer {
  background: linear-gradient(to right, #ffeca2 0%, #eaff7a 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  line-height: 139px;
  font-weight: 700;
  font-size: 99px;
  border-color: white;
  color: #ffeca2;
  transform-origin: 50% 50% 0px;
  letter-spacing: 5px;
}

@media (max-width: 1199px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-content h4.product-offer {
    font-size: 52px;
    line-height: 1.4;
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img {
  position: relative;
  width: 50%;
}

@media (max-width: 1199px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img {
    width: 100%;
  }
}

@media (max-width: 1500px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img .front-img {
    transform: scale(0.7);
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img .inner-img {
  position: absolute;
  z-index: -1;
  top: 10%;
  left: 5%;
}

@media (max-width: 1500px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img .inner-img {
    transform: scale(0.7);
  }
}

@media (max-width: 479px) {
  .main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img .inner-img {
    transform: scale(0.5);
    top: 0;
    left: -20%;
  }
}

.main-slider-7 .swiper-slide.style-01 .inner-slide .slide-img .sticker-img {
  display: inline-block;
  position: absolute;
  top: 35%;
  left: 35%;
}

.main-slider-7 .swiper-slide.style-02 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-slider-7 .swiper-slide.style-02 .inner-slide {
  width: calc(100% - 30px);
  margin: 0 auto;
}

.main-slider-7 .swiper-slide.style-02 .inner-slide .slide-content {
  text-align: center;
}

.main-slider-7 .swiper-slide.style-02 .inner-slide .slide-content span.category {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 30px;
  display: block;
  color: #ffffff;
}

@media (max-width: 767px) {
  .main-slider-7 .swiper-slide.style-02 .inner-slide .slide-content span.category {
    font-size: 18px;
    letter-spacing: 10px;
  }
}

.main-slider-7 .swiper-slide.style-02 .inner-slide .slide-content h4.product-offer {
  background: linear-gradient(to right, #ffeca2 0%, #eaff7a 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  line-height: 46px;
  font-weight: 700;
  font-size: 54px;
  border-color: white;
  color: #ffeca2;
  transform-origin: 50% 50% 0px;
  letter-spacing: 5px;
}

@media (max-width: 1199px) {
  .main-slider-7 .swiper-slide.style-02 .inner-slide .slide-content h4.product-offer {
    font-size: 24px;
    line-height: 1.4;
  }
}

/* ---Main Slider Eight--- */
.main-slider-8 .swiper-slide .inner-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-slider-8 .swiper-slide .inner-slide.bg-1, .main-slider-8 .swiper-slide .inner-slide.bg-2, .main-slider-8 .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/8-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
}

@media (max-width: 1500px) {
  .main-slider-8 .swiper-slide .inner-slide.bg-1, .main-slider-8 .swiper-slide .inner-slide.bg-2, .main-slider-8 .swiper-slide .inner-slide.bg-3 {
    height: 580px;
  }
}

@media (max-width: 767px) {
  .main-slider-8 .swiper-slide .inner-slide.bg-1, .main-slider-8 .swiper-slide .inner-slide.bg-2, .main-slider-8 .swiper-slide .inner-slide.bg-3 {
    height: 380px;
  }
}

.main-slider-8 .swiper-slide .inner-slide.bg-1.bg-2, .main-slider-8 .swiper-slide .inner-slide.bg-2.bg-2, .main-slider-8 .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/8-2.jpg");
}

.main-slider-8 .swiper-slide .inner-slide .slide-content {
  text-align: center;
}

@media (max-width: 767px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content .slide-img {
    width: 123px;
    height: 107px;
    margin: 0 auto;
  }
}

.main-slider-8 .swiper-slide .inner-slide .slide-content h1.heading {
  font-size: 55px;
  line-height: 65px;
  font-weight: 500;
  letter-spacing: 14px;
}

@media (max-width: 991px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content h1.heading {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content h1.heading {
    letter-spacing: 5px;
    line-height: 1.4;
    padding-bottom: 10px;
    font-size: 20px;
  }
}

.main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 26px;
  line-height: 35px;
  position: relative;
  display: block;
}

@media (max-width: 767px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection {
    font-size: 16px;
    padding-bottom: 20px;
  }
}

.main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:before {
  border-top: 5px solid;
  width: 220px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

@media (max-width: 991px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:before {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:before {
    width: 50px;
  }
}

.main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:after {
  border-top: 5px solid;
  width: 220px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}

@media (max-width: 991px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:after {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content span.product-collection:after {
    width: 50px;
  }
}

@media (max-width: 767px) {
  .main-slider-8 .swiper-slide .inner-slide .slide-content .button-wrap .skudmart-btn {
    width: 100px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
  }
}

/* ---Main Slider Nine--- */
.main-slider-9 .swiper-slide .inner-slide.bg-1, .main-slider-9 .swiper-slide .inner-slide.bg-2, .main-slider-9 .swiper-slide .inner-slide.bg-3 {
  background-image: url("../images/slider/bg/9-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.main-slider-9 .swiper-slide .inner-slide.bg-1.bg-2, .main-slider-9 .swiper-slide .inner-slide.bg-2.bg-2, .main-slider-9 .swiper-slide .inner-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/9-2.jpg");
}

.main-slider-9 .swiper-slide .inner-slide.bg-1.bg-3, .main-slider-9 .swiper-slide .inner-slide.bg-2.bg-3, .main-slider-9 .swiper-slide .inner-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/9-3.jpg");
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list {
    display: none;
  }
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li:not(:last-child) a span {
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li.active a span:before {
  opacity: 1;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li a {
  font-size: 32px;
  line-height: 57px;
  letter-spacing: 2px;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li a span {
  position: relative;
  display: inline-block;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li a span:before {
  background-color: #ffffff;
  content: "";
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-color_list ul li a:hover span:before {
  opacity: 1;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content {
  position: relative;
  text-align: center;
  width: 50%;
}

@media (max-width: 991px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content {
    width: 100%;
  }
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content .playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content span.category {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
  display: block;
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content h1.heading {
  font-size: 86px;
  line-height: 86px;
  letter-spacing: 5px;
  font-weight: 400;
}

@media (max-width: 1500px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content h1.heading {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content h1.heading {
    font-size: 25px;
  }
}

.main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content h1.different-width {
  max-width: 75%;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .main-slider-9 .swiper-slide .inner-slide .inner-slide_wrap .slide-content h1.different-width {
    max-width: 100%;
  }
}

.main-slider-9 .swiper-pagination {
  bottom: auto;
  right: 15px;
  left: auto;
  top: 50%;
  width: auto;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .main-slider-9 .swiper-pagination {
    top: 70%;
  }
}

.main-slider-9 .swiper-pagination span.swiper-pagination-bullet {
  border: 2px solid #ffffff;
  background-color: transparent;
  transform-origin: top;
  display: block;
  width: 15px;
  height: 15px;
  opacity: 1;
  transition: background 0.3s ease;
}

.main-slider-9 .swiper-pagination span:not(:last-child) {
  margin-bottom: 15px;
}

.main-slider-9 .swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #ffffff;
}

/* ---Main Slider Ten--- */
.slider-with_banner {
  height: 100%;
}

.slider-with_banner .main-slider-10 {
  height: 100%;
}

@media (max-width: 991px) {
  .slider-with_banner .main-slider-10 {
    padding-top: 30px;
    height: 450px;
  }
}

.slider-with_banner .main-slider-10 .swiper-slide {
  overflow: hidden;
}

.slider-with_banner .main-slider-10 .swiper-slide.swiper-slide-active .inner-slide:before {
  animation: kenburns-top 5s linear 1 normal both;
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-1, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-2, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-3 {
  height: 100%;
  position: relative;
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-1:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-2:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-3:before {
  background-image: url("../images/slider/bg/10-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-1.bg-2:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-2.bg-2:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-3.bg-2:before {
  background-image: url("../images/slider/bg/10-2.webp");
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-1.bg-3:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-2.bg-3:before, .slider-with_banner .main-slider-10 .swiper-slide .inner-slide.bg-3.bg-3:before {
  background-image: url("../images/slider/bg/10-3.webp");
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 130px;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content {
    width: calc(100% - 60px);
    right: auto;
    left: 30px;
  }
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content span.category {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 32px;
  line-height: 57.6px;
  padding-bottom: 20px;
  letter-spacing: 2px;
  display: block;
}

@media (max-width: 1199px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content span.category {
    font-size: 20px;
    line-height: 1.2;
    padding-bottom: 5px;
  }
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content h1.heading {
  font-size: 100px;
  line-height: 100px;
  font-weight: 500;
  letter-spacing: 5px;
  padding-bottom: 35px;
}

@media (max-width: 1500px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content h1.heading {
    font-size: 50px;
    line-height: 1.4;
    font-weight: 500;
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content h1.heading {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content .button-wrap .skudmart-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

.slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content.style-02 {
  right: auto;
  left: 130px;
}

@media (max-width: 767px) {
  .slider-with_banner .main-slider-10 .swiper-slide .inner-slide .slide-content.style-02 {
    left: 30px;
  }
}

@media (max-width: 991px) {
  .slider-with_banner .banner-wrap {
    padding-top: 30px;
  }
}

.slider-with_banner .banner-wrap .banner-item {
  position: relative;
}

.slider-with_banner .banner-wrap .banner-item .single-img .inner-content {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media (max-width: 1500px) {
  .slider-with_banner .banner-wrap .banner-item .single-img .inner-content {
    left: 30px;
  }
}

.slider-with_banner .banner-wrap .banner-item .single-img .inner-content h2.heading {
  font-family: "Pacifico",Sans-serif;
  font-style: italic;
  font-size: 46px;
  line-height: 46px;
}

@media (max-width: 1500px) {
  .slider-with_banner .banner-wrap .banner-item .single-img .inner-content h2.heading {
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 1.2;
  }
}

.slider-with_banner .banner-wrap .banner-item .single-img .inner-content h3.product-offer {
  font-size: 46px;
  line-height: 46px;
}

@media (max-width: 1500px) {
  .slider-with_banner .banner-wrap .banner-item .single-img .inner-content h3.product-offer {
    padding-bottom: 5px;
    font-size: 30px;
    line-height: 1.2;
  }
}

@media (max-width: 1500px) {
  .slider-with_banner .banner-wrap .banner-item .single-img .inner-content .button-wrap .skudmart-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

.slider-with_banner .banner-wrap .banner-item .single-img .inner-content.style-02 {
  left: auto;
  right: 50px;
}

/*----------------------------------------*/
/*  14 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area .breadcrumb-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.breadcrumb-area .breadcrumb-content h1 {
  text-align: center;
  font-size: 34px;
  line-height: 40.8px;
  letter-spacing: 5px;
  font-weight: 400;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .breadcrumb-area .breadcrumb-content h1 {
    font-size: 25px;
  }
}

.breadcrumb-area .breadcrumb-content ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.breadcrumb-area .breadcrumb-content ul li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 23.6px;
  letter-spacing: 0.5px;
  color: #1d1d1d;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 21.6px;
  letter-spacing: 0.5px;
}

.breadcrumb-area .breadcrumb-content ul li:not(:last-child) {
  padding-right: 8px;
}

.breadcrumb-area .breadcrumb-content ul li:not(:last-child):after {
  content: ">";
  margin-left: 8px;
}

.breadcrumb-area .breadcrumb-content ul li a {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 21.6px;
  letter-spacing: 0.5px;
}

.breadcrumb-area.with-bg {
  background-image: url("../images/breadcrumb/about.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 460px;
}

.breadcrumb-area.with-bg .breadcrumb-content h1 {
  color: #ffffff;
  z-index: 1;
}

.breadcrumb-area.with-bg .breadcrumb-content ul li {
  color: #ffffff;
}

.breadcrumb-area.with-bg .breadcrumb-content ul li a {
  color: #ffffff;
}

.breadcrumb-area.with-bg.contact-page_bg {
  background-image: url("../images/breadcrumb/contact.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.faq-page_bg {
  background-image: url("../images/breadcrumb/faq.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.collection-pages_bg {
  background-image: url("../images/breadcrumb/collections.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.shop-instagram_bg {
  background-image: url("../images/breadcrumb/shop-instagram.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.shop-masonry_bg {
  background-image: url("../images/breadcrumb/shop-masonry.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.vendor-page_bg {
  background-image: url("../images/breadcrumb/collections.jpg");
  height: 270px;
}

.breadcrumb-area.with-bg.vendor-page_bg .breadcrumb-content {
  display: none;
}

.breadcrumb-area.with-bg.shop-related-pages_bg {
  background-image: url("../images/breadcrumb/shop-related.jpg");
  height: 345px;
}

.breadcrumb-area.with-bg.element-pages_bg {
  background-image: url("../images/breadcrumb/element-pages.jpg");
  height: 345px;
}

.breadcrumb-area.with-border {
  border-top: 1px solid #d8d8d8;
  padding-top: 85px;
  padding-bottom: 95px;
}

.breadcrumb-area.with-border .breadcrumb-content h1 {
  color: #1d1d1d;
}

.breadcrumb-area.with-border .breadcrumb-content ul li a {
  color: inherit;
}

.breadcrumb-area .with-page_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb-area .with-page_nav .page-nav ul {
  display: flex;
}

.breadcrumb-area .with-page_nav .page-nav ul li:not(:last-child) {
  padding-right: 10px;
}

/*----------------------------------------*/
/*  15 - Components - Form
/*----------------------------------------*/
.form-area h3.heading {
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 15px;
}

form.feedback-form .form-field {
  margin-bottom: 0;
  padding-bottom: 20px;
}

form.feedback-form .form-field textarea.textarea-field {
  height: 220px;
  width: 100%;
  padding: 25px;
}

form.feedback-form .form-field input.input-field {
  width: 100%;
  height: 50px;
  padding: 0 25px;
}

form.feedback-form .form-field.group-input {
  display: flex;
}

@media (max-width: 767px) {
  form.feedback-form .form-field.group-input {
    flex-direction: column;
  }
  form.feedback-form .form-field.group-input input[class*="input-field"]:not(:last-child) {
    margin-bottom: 20px;
  }
}

form.feedback-form .inquiry-field .nice-select {
  padding: 0 25px;
  height: 50px;
  line-height: 50px;
}

form.feedback-form .form-btn_wrap {
  padding-top: 25px;
}

form.feedback-form.with-border-bottom .form-field textarea.textarea-field {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

form.feedback-form.with-border-bottom .form-field textarea.textarea-field:focus {
  border: 0;
  border-bottom: 1px solid #1d1d1d;
  color: #1d1d1d;
}

form.feedback-form.with-border-bottom .form-field input.input-field {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

form.feedback-form.with-border-bottom .form-field input.input-field:focus {
  border: 0;
  border-bottom: 1px solid #1d1d1d;
  color: #1d1d1d;
}

/*----------------------------------------*/
/*  17 - Components - Social Link
/*----------------------------------------*/
.social-link li {
  display: inline-block;
}

.social-link li:not(:last-child) {
  padding-right: 25px;
}

.social-link li a {
  font-size: 16px;
  display: block;
}

.social-link.with-color li {
  padding-right: 10px;
}

.social-link.with-color li:last-child {
  padding-right: 0;
}

.social-link.with-color li a {
  color: #ffffff;
  font-size: 14px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.social-link.with-color li.facebook a {
  background-color: #3b579d;
}

.social-link.with-color li.twitter a {
  background-color: #3acaff;
}

.social-link.with-color li.google-plus a {
  background-color: #ca0813;
}

.social-link.with-color li.skype a {
  background-color: #00aff0;
}

.social-link.with-border-radius li a {
  border-radius: 3px;
}

.social-link.with-border-radius.sm-size li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.social-link.vertical-social_link {
  display: inline-block;
}

.social-link.vertical-social_link li {
  display: block;
  padding-right: 0;
  padding-bottom: 10px;
}

/*----------------------------------------*/
/*  21 - Components - Testimonials
/*----------------------------------------*/
.testimonial-item {
  display: flex;
}

.testimonial-item .single-content {
  padding-left: 30px;
}

@media (max-width: 767px) {
  .testimonial-item .single-content {
    padding-left: 0;
  }
}

.testimonial-item .single-content h3.user-name span:after {
  content: "\ea7a";
  font-family: 'LaStudioIcons';
  display: block;
  color: #D2A35C;
  font-size: 20px;
  padding-top: 10px;
}

.testimonial-item .single-content h4.user-name-2 {
  font-size: 16px;
  line-height: 28.8px;
}

.testimonial-item.style-2 {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonial-item.style-2 .single-img:after {
  content: "\ea7a";
  font-family: 'LaStudioIcons';
  display: block;
  font-size: 20px;
  margin-top: -15px;
}

.testimonial-item.style-2 .single-img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 100%;
}

.testimonial-item.style-3 {
  font-family: 'Playfair Display', serif;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 770px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .testimonial-item.style-3 {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .testimonial-item.style-3 .single-img {
    width: 120px;
    margin: 0 auto;
  }
}

.testimonial-item.style-3 .single-img img {
  border-radius: 100%;
  object-fit: cover;
  width: 100%;
}

.testimonial-item.style-3 .single-content {
  padding-left: 60px;
  padding-right: 0;
}

@media (max-width: 767px) {
  .testimonial-item.style-3 .single-content {
    text-align: center;
    padding-top: 25px;
    padding-left: 0;
  }
}

.testimonial-item.style-3 .single-content p.short-desc {
  max-width: 95%;
}

.testimonial-item.style-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.testimonial-item.style-4 .single-content p.user-comment {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 32px;
  line-height: 56px;
}

@media (max-width: 479px) {
  .testimonial-item.style-4 .single-content p.user-comment {
    font-size: 18px;
    line-height: 1.8;
  }
}

.testimonial-item.style-4 .single-content h4.user-name-2 {
  font-size: 14px;
  line-height: 25.2px;
}

/* ---Testimonial Area--- */
.testimonial-area.bg-1 {
  background-image: url("../images/testimonials/bg/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 280px;
}

.testimonial-area.bg-2 {
  background-image: url("../images/testimonials/bg/2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 390px;
}

/*----------------------------------------*/
/*  23 - Components - Sidebar
/*----------------------------------------*/
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 95px;
  }
}

.sidebar-area .search-box {
  position: relative;
}

.sidebar-area .search-box input.search-box_input {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 50px;
  padding: 0 45px 0 15px;
}

.sidebar-area .search-box button.search-box_btn {
  color: #454545;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.sidebar-area .search-box button.search-box_btn:hover {
  color: #D2A35C;
}

.sidebar-area .widgets-area h2.heading {
  font-size: 24px;
  line-height: 28.8px;
  padding-bottom: 25px;
}

.sidebar-area .widgets-area .widgets-item ul li:not(:last-child) {
  padding-bottom: 15px;
}

.sidebar-area .widgets-area .widgets-item ul li a {
  font-size: 18px;
  line-height: 32.4px;
  text-transform: uppercase;
  font-weight: 500;
}

.sidebar-area .widgets-area .widgets-item ul li:hover a {
  color: #D2A35C;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub a i {
  margin-left: 20px;
  font-size: 14px;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub > ul.inner-body {
  display: none;
  padding-top: 15px;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub > ul.inner-body li:not(:last-child) {
  padding-bottom: 10px;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub > ul.inner-body li:hover a {
  color: #D2A35C;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub > ul.inner-body li a {
  display: block;
  font-size: 12px;
  line-height: 21.6px;
  color: #999999;
  font-weight: 400;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub > ul.inner-body li a:hover {
  text-decoration: underline;
}

.sidebar-area .widgets-area .widgets-item ul li.has-sub.open a i:before {
  content: '\ea13';
}

.sidebar-area .widgets-area .widgets-item ul.tags-item li {
  display: inline-block;
}

.sidebar-area .widgets-area .widgets-item ul.tags-item li:not(:last-child) {
  margin-right: 5px;
}

.sidebar-area .widgets-area .widgets-item ul.tags-item li a {
  text-transform: none;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  font-weight: 400;
  display: block;
  padding: 10px;
  border: 1px solid #d8d8d8;
}

.sidebar-area .widgets-area .widgets-item ul.tags-item li a:hover {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.sidebar-area .blog-widgets_area h3.heading {
  border-bottom: 2px solid #1d1d1d;
  font-size: 18px;
  line-height: 21.6px;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product {
  display: flex;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product .single-img {
  width: 80px;
  height: 80px;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product .single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product .single-content {
  padding-left: 20px;
  width: calc(100% - 80px);
}

.sidebar-area .blog-widgets_area .widgets-item .list-product .single-content .product-name {
  display: block;
  font-size: 14px;
  line-height: 19.6px;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product .single-content span.meta {
  font-size: 11px;
  line-height: 20.2px;
}

.sidebar-area .blog-widgets_area .widgets-item .list-product[class*="list-product"]:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-category li {
  display: flex;
  justify-content: space-between;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-category li:not(:last-child) {
  padding-bottom: 20px;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-category li span {
  color: #1d1d1d;
  font-size: 14px;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-category li a {
  font-size: 14px;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-tags a {
  text-transform: lowercase;
  font-size: 14px;
  line-height: 25.2px;
}

.sidebar-area .blog-widgets_area .widgets-item .blog-tags a:hover {
  text-decoration: underline;
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner {
  position: relative;
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner:before {
  background-color: #181818;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .5s;
  opacity: .2;
  pointer-events: none;
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content {
    bottom: 20px;
  }
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content span {
  text-transform: uppercase;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  .sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content span {
    font-size: 16px;
    line-height: 16px;
  }
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content h3.offer {
  font-size: 120px;
  line-height: 120px;
}

@media (max-width: 1199px) {
  .sidebar-area .blog-widgets_area .widgets-item .widgets-banner .inner-content h3.offer {
    font-size: 80px;
    line-height: 80px;
  }
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner:hover:before {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
}

.sidebar-area .blog-widgets_area .widgets-item .widgets-banner:hover .inner-content {
  bottom: 30px;
}

/*----------------------------------------*/
/*  24 - Components - Tab
/*----------------------------------------*/
/* ---Product Tab--- */
.product-tab.style-01 ul.product-menu {
  justify-content: center;
}

.product-tab.style-01 ul.product-menu li:not(:last-child) {
  padding-right: 10px;
}

@media (max-width: 767px) {
  .product-tab.style-01 ul.product-menu li:not(:last-child) {
    padding-bottom: 10px;
  }
}

.product-tab.style-01 ul.product-menu li a {
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  padding: 15px 30px;
  font-size: 14px;
  line-height: 22px;
  display: block;
}

@media (max-width: 479px) {
  .product-tab.style-01 ul.product-menu li a {
    padding: 10px 15px;
  }
}

.product-tab.style-01 ul.product-menu li a.active {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.product-tab.style-01 ul.product-menu li a:hover {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
}

.product-tab.style-02 ul.product-menu {
  justify-content: center;
  list-style-type: disc;
}

@media (max-width: 479px) {
  .product-tab.style-02 ul.product-menu {
    list-style-type: none;
  }
}

.product-tab.style-02 ul.product-menu li:not(:last-child) {
  padding-right: 30px;
}

.product-tab.style-02 ul.product-menu li a {
  text-transform: uppercase;
}

@media (max-width: 479px) {
  .product-tab.style-02 ul.product-menu li a {
    font-size: 14px;
  }
}

.product-tab.style-02 ul.product-menu li a.active {
  text-decoration: underline;
}

.product-tab.style-02 ul.product-menu li a:hover {
  color: initial;
}

.product-tab.style-03 ul.product-menu {
  justify-content: center;
}

.product-tab.style-03 ul.product-menu li:not(:last-child) {
  padding-right: 45px;
}

@media (max-width: 479px) {
  .product-tab.style-03 ul.product-menu li:not(:last-child) {
    padding-right: 0;
  }
}

.product-tab.style-03 ul.product-menu li a {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 46px;
  line-height: 82.8px;
  color: #707070;
}

.product-tab.style-03 ul.product-menu li a.active {
  color: #ffffff;
}

@media (max-width: 575px) {
  .product-tab.style-04 ul {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.product-tab.style-04 ul.product-menu {
  justify-content: center;
}

.product-tab.style-04 ul.product-menu li:not(:last-child) {
  padding-right: 45px;
}

@media (max-width: 575px) {
  .product-tab.style-04 ul.product-menu li:not(:last-child) {
    padding-right: 0;
  }
}

.product-tab.style-04 ul.product-menu li a {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 57.6px;
  color: #707070;
  font-weight: 500;
}

.product-tab.style-04 ul.product-menu li a.active {
  color: #1d1d1d;
}

.product-tab.style-04 ul.product-menu li a:hover {
  color: #1d1d1d;
}

/* ---Product Tab Two--- */
.product-tab-2 ul.product-menu {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
  justify-content: center;
}

@media (max-width: 1199px) {
  .product-tab-2 ul.product-menu {
    justify-content: flex-start;
  }
}

.product-tab-2 ul.product-menu li:not(:last-child) {
  padding-right: 35px;
}

@media (max-width: 1199px) {
  .product-tab-2 ul.product-menu li:not(:last-child) {
    padding-right: 15px;
  }
}

.product-tab-2 ul.product-menu li a {
  color: inherit;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.product-tab-2 ul.product-menu li a:hover {
  color: #D2A35C;
}

.product-tab-2 ul.product-menu li a.active {
  color: #1d1d1d;
  font-weight: 600;
}

.product-tab-2 ul.product-menu li a.active:before {
  background-color: #1d1d1d;
  height: 3px;
  width: 100%;
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
}

@media (max-width: 991px) {
  .product-tab-2 ul.product-menu li a.active:before {
    content: none;
  }
}

/* ---Tab Content--- */
.sku-tab_content .description-body .single-item {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .sku-tab_content .description-body .single-item {
    flex-direction: column;
  }
}

.sku-tab_content .description-body .single-item .single-img {
  width: 30%;
}

@media (max-width: 767px) {
  .sku-tab_content .description-body .single-item .single-img {
    width: 100%;
  }
}

.sku-tab_content .description-body .single-item .single-content {
  padding-left: 50px;
  width: 70%;
}

@media (max-width: 767px) {
  .sku-tab_content .description-body .single-item .single-content {
    padding-top: 25px;
    padding-left: 0;
    width: 100%;
  }
}

.sku-tab_content .review-body > h2.heading {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 400;
  padding-bottom: 25px;
}

.sku-tab_content .review-body ul.user-info_wrap > li {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 40px;
}

.sku-tab_content .review-body ul.user-info_wrap > li:not(:last-child) {
  margin-bottom: 40px;
}

.sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 575px) {
  .sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info {
    flex-direction: column;
  }
}

.sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-avatar {
  width: 15%;
}

@media (max-width: 1199px) {
  .sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-avatar {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-avatar {
    width: 40%;
  }
}

.sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-comment {
  padding-left: 30px;
}

@media (max-width: 575px) {
  .sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-comment {
    padding-top: 20px;
    padding-left: 0;
  }
}

.sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-comment h3.product-name {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 18px;
  line-height: 32.4px;
  font-weight: 600;
}

.sku-tab_content .review-body ul.user-info_wrap > li > ul.user-info li.user-comment p.short-desc {
  font-size: 14px;
  line-height: 25.2px;
}

.sku-tab_content .review-body .user-feedback {
  padding-top: 40px;
}

.sku-tab_content .review-body .user-feedback h3.heading {
  font-size: 24px;
  line-height: 43.2px;
}

.sku-tab_content .review-body .user-feedback p.short-desc {
  padding-bottom: 15px;
}

.sku-tab_content .review-body .user-feedback .rating-box {
  display: flex;
  align-items: center;
}

.sku-tab_content .review-body .user-feedback .rating-box span {
  padding-right: 25px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form {
  padding-top: 15px;
  padding-bottom: 50px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field {
  padding-bottom: 20px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field label.label-field {
  padding-bottom: 10px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field textarea.textarea-field {
  height: 220px;
  width: 100%;
  padding: 25px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field input.input-field {
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field {
    flex-direction: column;
  }
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field .group-input {
  width: 100%;
}

@media (max-width: 767px) {
  .sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field .group-input[class*="group-input"]:not(:last-child) {
    padding-bottom: 20px;
  }
}

.sku-tab_content .review-body .user-feedback form.pd-feedback_form .form-btn_wrap {
  padding-top: 30px;
}

.sku-tab_content .shipping-delivery_body {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 767px) {
  .sku-tab_content .shipping-delivery_body {
    padding-left: 0;
    padding-right: 0;
  }
}

.sku-tab_content .shipping-delivery_body h3.heading {
  font-size: 16px;
  line-height: 19.2px;
}

.sku-tab_content .vendor-info_body ul li.rating-box_wrap {
  display: flex;
  align-items: center;
}

.sku-tab_content .vendor-info_body ul li.rating-box_wrap > ul.rating-box li a {
  font-size: 12px;
  line-height: 21.6px;
}

.sku-tab_content .vendor-info_body ul li.rating-box_wrap span {
  font-size: 12px;
  line-height: 21.6px;
  padding-left: 5px;
}

/* ---Product Tab Area--- */
.product-tab_area.bg-dark_color .tab-content .product-item .single-product .single-content .product-name {
  color: #ffffff;
}

.product-tab_area.bg-dark_color .tab-content .product-item .single-product .single-content .product-name:hover {
  color: #D2A35C;
}

.product-tab_area.bg-dark_color .tab-content .product-item .single-product .single-content .price-box span.new-price {
  color: #ffffff;
}

/*----------------------------------------*/
/*  21 - Components - Brand
/*----------------------------------------*/
.brand-common_style .swiper-wrapper {
  align-items: center;
}

.brand-common_style .swiper-wrapper .swiper-slide .brand-item {
  text-align: center;
}

.brand-common_style .swiper-wrapper .swiper-slide .brand-item img {
  filter: grayscale(100);
}

.brand-common_style .swiper-wrapper .swiper-slide .brand-item img:hover {
  filter: grayscale(0);
}

/*----------------------------------------*/
/*  22 - Components - Offcanvas
/*----------------------------------------*/
.mobile-menu_wrapper .offcanvas-body,
.offcanvas-minicart_wrapper .offcanvas-body,
.offcanvas-search_wrapper .offcanvas-body,
.offcanvas-menu_wrapper .offcanvas-body {
  background-color: #1d1d1d;
  position: fixed;
  padding: 0;
  top: 0;
  left: -280px;
  width: 280px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  overflow: visible;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-menu_wrapper .offcanvas-body .inner-body,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body,
.offcanvas-search_wrapper .offcanvas-body .inner-body,
.offcanvas-menu_wrapper .offcanvas-body .inner-body {
  padding: 50px 0;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-top,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-top {
  display: flex;
  justify-content: flex-end;
  padding: 0 25px 30px;
  transform: none;
  height: auto;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close {
  background-image: none;
  font-size: 24px;
  color: #c0c0c0;
  opacity: 1;
  outline: 0;
  padding: 0;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li {
  height: 100%;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li {
  position: relative;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child),
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child),
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child),
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child) {
  padding-bottom: 25px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand {
  position: absolute;
  right: 0;
  top: 2px;
  width: 50px;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  margin-right: 4px;
  font-size: 20px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a {
  color: #c0c0c0;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  padding: 0 30px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu {
  background-color: rgba(255, 255, 255, 0.05);
  overflow-y: auto;
  padding: 25px 0;
  margin: 20px 0 10px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a {
  text-transform: uppercase;
  font-size: 11px;
  color: #c0c0c0;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li.menu-open > a > span > i:before,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li.menu-open > a > span > i:before,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li.menu-open > a > span > i:before,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li.menu-open > a > span > i:before {
  content: '\ea13';
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a {
  color: #D2A35C;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a > span > i:before,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a > span > i:before,
.offcanvas-search_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a > span > i:before,
.offcanvas-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-open > a > span > i:before {
  content: '\ea13';
}

.mobile-menu_wrapper.open .offcanvas-body,
.offcanvas-minicart_wrapper.open .offcanvas-body,
.offcanvas-search_wrapper.open .offcanvas-body,
.offcanvas-menu_wrapper.open .offcanvas-body {
  visibility: visible;
  left: 0;
  opacity: 1;
  z-index: 999;
}

/* ---Offcanvas Menu--- */
.offcanvas-menu_wrapper .offcanvas-body.style-02 {
  width: 470px;
}

@media (max-width: 575px) {
  .offcanvas-menu_wrapper .offcanvas-body.style-02 {
    width: 100%;
  }
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body {
  height: 100%;
  padding: 50px 0 0;
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body .offcanvas-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body .offcanvas-wrap .offcanvas-menu_area .offcanvas-navigation ul.mobile-menu li a {
  color: #ffffff;
  padding: 0 70px;
  font-size: 20px;
  line-height: 36px;
  text-transform: capitalize;
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body .offcanvas-wrap .offcanvas-menu_area .offcanvas-navigation ul.mobile-menu li a span i {
  font-size: 14px;
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body .offcanvas-wrap .offcanvas-menu_area .offcanvas-navigation ul.mobile-menu li ul.sub-menu li a {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 28.8px;
}

.offcanvas-menu_wrapper .offcanvas-body.style-02 .inner-body .offcanvas-wrap .offcanvas-bottom {
  padding: 45px 70px 55px;
  transform: none;
  height: auto;
}

.offcanvas-menu_wrapper .offcanvas-body.direction-right {
  left: auto;
  right: -470px;
}

.offcanvas-menu_wrapper.open .direction-right {
  right: 0;
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper .offcanvas-body {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 55px 60px 0;
  width: 450px;
  left: auto;
  right: -660px;
}

@media (max-width: 575px) {
  .offcanvas-minicart_wrapper .offcanvas-body {
    width: 100%;
    padding: 55px 30px 0;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading h4 {
  font-size: 18px;
  line-height: 21.6px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading .btn-close {
  background-image: none;
  font-size: 24px;
  padding: 0;
  opacity: 1;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list {
  max-height: 350px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product {
  display: flex;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product {
    flex-direction: column;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product a.product-item_remove i {
  position: absolute;
  font-size: 12px;
  top: auto;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_img {
  flex-basis: 70px;
  max-width: 70px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content {
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content a.product-item_title {
  line-height: 1.4;
  font-size: 16px;
  width: 90%;
  display: block;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content a.product-item_title {
    width: 100%;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  padding-top: 10px;
  font-size: 13px;
  line-height: 24.7px;
  color: #454545;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-item_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0 30px;
}

.offcanvas-minicart_wrapper .offcanvas-body .group-btn_wrap {
  padding-bottom: 60px;
}

.offcanvas-minicart_wrapper.open .offcanvas-body {
  left: auto;
  right: 0;
}

/* ---Offcanvas Search Area--- */
.offcanvas-search_wrapper .offcanvas-body {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.97;
  width: 100%;
  left: 0;
  right: 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  transform: scale(0);
  visibility: visible;
  opacity: 0;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  -webkit-transition: 1.3s ease-in-out;
  -moz-transition: 1.3s ease-in-out;
  -ms-transition: 1.3s ease-in-out;
  -o-transition: 1.3s ease-in-out;
  transition: 1.3s ease-in-out;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .offcanvas-top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 125px;
  height: auto;
  transform: none;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .offcanvas-top a.btn-close {
  background-image: none;
  color: #ffffff;
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  padding: 0;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .offcanvas-top a.btn-close:hover {
  color: #D2A35C;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search span.searchbox-info {
  color: #ffffff;
  font-size: 20px;
}

@media (max-width: 575px) {
  .offcanvas-search_wrapper .offcanvas-body .offcanvas-search span.searchbox-info {
    font-size: 16px;
  }
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox {
  position: relative;
  width: 100%;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ffffff;
  height: 100px;
  line-height: 100px;
  width: 100%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  padding-right: 40px;
}

@media (max-width: 575px) {
  .offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox input {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox input::placeholder {
  color: #ffffff;
  text-align: center;
  opacity: 1;
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox .search-btn {
  position: absolute;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: 0;
  background: transparent;
  color: #ffffff;
}

@media (max-width: 575px) {
  .offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox .search-btn {
    font-size: 16px;
  }
}

.offcanvas-search_wrapper .offcanvas-body .offcanvas-search .hm-searchbox .search-btn:hover i {
  color: #D2A35C;
}

.offcanvas-search_wrapper.open .offcanvas-body {
  padding: 0;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/*----------------------------------------*/
/*  23 - Components - Product
/*----------------------------------------*/
/* ---Featured Product--- */
.featured-product_item .single-img {
  position: relative;
  cursor: pointer;
}

.featured-product_item .single-img .fp-btn_wrap.position-bottom_left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  z-index: 2;
}

.featured-product_item .single-img .fp-btn_wrap.position-bottom_left .skudmart-btn {
  width: 100%;
}

.featured-product_item:hover .single-img .fp-btn_wrap.position-bottom_left .skudmart-btn {
  background-color: #ffffff;
  color: #000000;
}

.featured-product_item.style-02 .single-img .fp-btn_wrap.position-bottom_left .skudmart-btn {
  text-transform: uppercase;
}

.featured-product_item.style-02:hover .single-img .fp-btn_wrap.position-bottom_left .skudmart-btn {
  background-color: #7aedc9;
  color: #000000;
}

/* ---Product Item--- */
.product-item {
  padding-bottom: 30px;
}

.product-item .single-product .single-img {
  position: relative;
  overflow: hidden;
}

.product-item .single-product .single-img:before {
  background-color: #1d1d1d;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.product-item .single-product .single-img a img {
  width: 100%;
}

.product-item .single-product .single-img a img.secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.product-item .single-product .single-img .add-actions {
  position: absolute;
  bottom: 50%;
  left: 0;
  transform: translateY(50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.product-item .single-product .single-img .add-actions > ul li {
  display: inline-block;
  transform: translateY(50px);
}

.product-item .single-product .single-img .add-actions > ul li:not(:last-child) {
  padding-right: 5px;
}

.product-item .single-product .single-img .add-actions > ul li:nth-child(1) {
  transition-delay: 100ms;
}

.product-item .single-product .single-img .add-actions > ul li:nth-child(2) {
  transition-delay: 150ms;
}

.product-item .single-product .single-img .add-actions > ul li:nth-child(3) {
  transition-delay: 200ms;
}

.product-item .single-product .single-img .add-actions > ul li:nth-child(4) {
  transition-delay: 250ms;
}

.product-item .single-product .single-img .add-actions > ul li a {
  background-color: #ffffff;
  width: 45px;
  height: 45px;
  line-height: 50px;
  display: block;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 1500px) {
  .product-item .single-product .single-img .add-actions > ul li a {
    width: 40px;
    height: 40px;
    line-height: 45px;
  }
}

.product-item .single-product .single-img .add-actions > ul li a:hover {
  background-color: #D2A35C;
  color: #ffffff;
}

.product-item .single-product .single-img .add-actions.white-border ul li a {
  border: 1px solid #d8d8d8;
  background-color: transparent;
  color: #ffffff;
}

.product-item .single-product .single-img .add-actions.white-border ul li a:hover {
  background-color: #7aedc9;
  border-color: #7aedc9;
}

.product-item .single-product .single-content {
  padding-top: 15px;
}

.product-item .single-product .single-content .product-name {
  padding-bottom: 5px;
  font-size: inherit;
  line-height: inherit;
  display: block;
}

.product-item .single-product .single-content.with-bg {
  background-color: #ffffff;
  padding: 10px 20px;
}

.product-item .single-product .single-content.with-bg .product-name {
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
}

.product-item .single-product .single-content.with-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.product-item .single-product .single-content.with-padding .product-name {
  font-size: 14px;
}

.product-item .single-product:hover .single-img:before {
  opacity: 0.5;
}

.product-item .single-product:hover .single-img.without-overlay:before {
  content: none;
}

.product-item .single-product:hover .single-img a img.secondary-img {
  opacity: 1;
}

.product-item .single-product:hover .single-img .add-actions ul li {
  transform: translateY(0);
}

.product-item .single-product:hover .single-img .add-actions ul li a {
  opacity: 1;
  visibility: visible;
}

.product-item.product-banner .single-img {
  position: relative;
}

.product-item.product-banner .single-img .inner-text {
  position: absolute;
  bottom: 40px;
  left: 40px;
  pointer-events: none;
  z-index: 1;
}

.product-item.product-banner .single-img .inner-text h3.title {
  color: #ffffff;
  font-size: 36px;
  line-height: 43.2px;
}

.product-item.style-02 .single-product {
  overflow: hidden;
}

.product-item.style-02 .single-product .single-content .price-box span.new-price {
  color: #1d1d1d;
  font-weight: 500;
}

.product-item.style-02 .single-product .single-content p.short-desc {
  font-size: 14px;
}

.product-item.style-02 .single-product .single-content .add-actions ul li {
  display: inline-block;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
}

.product-item.style-02 .single-product .single-content .add-actions ul li:not(:last-child) {
  padding-right: 5px;
}

.product-item.style-02 .single-product .single-content .add-actions ul li:nth-child(1) {
  transition-delay: 100ms;
}

.product-item.style-02 .single-product .single-content .add-actions ul li:nth-child(2) {
  transition-delay: 150ms;
}

.product-item.style-02 .single-product .single-content .add-actions ul li:nth-child(3) {
  transition-delay: 200ms;
}

.product-item.style-02 .single-product .single-content .add-actions ul li:nth-child(4) {
  transition-delay: 250ms;
}

.product-item.style-02 .single-product .single-content .add-actions ul li a {
  background-color: #3C3F52;
  color: #ffffff;
  width: 45px;
  height: 45px;
  line-height: 50px;
  display: block;
  text-align: center;
}

.product-item.style-02 .single-product .single-content .add-actions ul li a:hover {
  background-color: #7aedc9;
  color: #ffffff;
}

.product-item.style-02 .single-product:hover .add-actions ul li {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.product-item.with-border .single-product {
  border: 1px solid #d8d8d8;
  padding: 30px;
}

/* ---Popular Product Slider--- */
@media (min-width: 576px) {
  .popular-product_slider .swiper-slide {
    width: 30% !important;
  }
}

@media (min-width: 576px) {
  .popular-product_slider .swiper-slide.swiper-slide-active {
    width: 40% !important;
  }
}

.popular-product_slider .swiper-slide .single-product .single-content {
  text-align: left;
}

@media (max-width: 575px) {
  .popular-product_slider .swiper-slide .single-product .single-content {
    text-align: center;
  }
}

/*----------------------------------------*/
/*  24 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper {
  display: block;
}

.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  z-index: -9;
}

.modal-wrapper.show {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: 1;
  z-index: 1051;
}

.modal-wrapper .modal-slider {
  height: 100%;
}

.modal-wrapper .modal-slider .single-img {
  height: 100%;
}

.modal-wrapper .modal-slider .single-img img.img-full {
  height: 100%;
}

.modal-wrapper .modal-dialog {
  max-width: 945px;
}

@media (max-width: 991px) {
  .modal-wrapper .modal-dialog {
    max-width: 100%;
    width: calc(100% - 60px);
    margin: 30px auto 0;
  }
}

@media (max-width: 479px) {
  .modal-wrapper .modal-dialog {
    width: calc(100% - 30px);
    margin: 15px auto 0;
  }
}

.modal-wrapper .modal-dialog .modal-content {
  border-radius: 0;
  border: 0;
  overflow: visible;
}

.modal-wrapper .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .close-btn {
  font-size: 18px;
  opacity: 1;
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 99;
}

@media (max-width: 479px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .close-btn {
    top: -15px;
    right: -15px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .close-btn i {
  background-color: #1d1d1d;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  display: block;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .close-btn:hover i {
  color: #D2A35C;
  transform: rotate(180deg);
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  height: 510px;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content {
    height: 375px;
  }
}

@media (max-width: 767px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content {
    height: 100%;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-name {
  padding-bottom: 10px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-name a {
  font-size: 24px;
  line-height: 28.8px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .price-box {
  padding-bottom: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .price-box span {
  color: #1d1d1d;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content p.short-desc {
  font-size: 14px;
  line-height: 25.2px;
  padding-bottom: 25px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .button-wrap {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .button-wrap ul.group-btn {
  align-items: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-meta {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-meta ul li span,
.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-meta ul li a {
  font-size: 14px;
  line-height: 25.2px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-meta ul li a {
  color: #D2A35C;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .product-meta ul li a:hover {
  text-decoration: underline;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .social-link {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .social-link li.title {
  color: #1d1d1d;
  padding-right: 20px;
  font-size: 12px;
  line-height: 19.8px;
  font-weight: 500;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .social-link li.title {
    font-size: 11px;
  }
}

@media (max-width: 479px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .social-link li:not(:last-child) {
    padding-right: 15px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .inner-content .social-link li a {
  font-size: 14px;
}

/*----------------------------------------*/
/*  25 - Components - Instagram
/*----------------------------------------*/
.instagram-img {
  position: relative;
  display: block;
}

.instagram-img .add-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.instagram-img .add-action i {
  background-color: #ffffff;
  color: #1d1d1d;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  display: block;
}

.instagram-img:hover .add-action {
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
}

.instagram-area.style-1 .instagram-img:before {
  background-color: #1d1d1d;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.instagram-area.style-1 .instagram-img:hover:before {
  opacity: 0.5;
  visibility: visible;
}

.instagram-area.style-2 .instagram-img {
  display: flex;
  align-items: center;
}

.instagram-area.style-2 .instagram-img .single-img {
  position: relative;
}

.instagram-area.style-2 .instagram-img .single-img .add-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.instagram-area.style-2 .instagram-img .single-img .add-action i {
  background-color: #ffffff;
  color: #1d1d1d;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  display: block;
}

.instagram-area.style-2 .instagram-img .single-img:hover .add-action {
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
}

.instagram-area.style-2 .instagram-img .group-img_wrap {
  position: relative;
}

.instagram-area.style-2 .instagram-img .group-img_wrap:before {
  border: 1px solid #d8d8d8;
  border-radius: 100%;
  width: 100%;
  height: 90%;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  z-index: -1;
}

.instagram-area.style-2 .instagram-img .group-img_wrap .group-img {
  display: flex;
}

.instagram-area.style-2 .instagram-img .group-img_wrap .additional-img {
  width: 65%;
}

.instagram-area.style-2 .instagram-img .single-img {
  padding: 30px;
}

@media (max-width: 1199px) {
  .instagram-area.style-2 .instagram-img .single-img {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .instagram-area.style-2 .instagram-img .single-img {
    padding: 10px;
  }
}

/* ---Custom Instagram--- */
@media (max-width: 991px) {
  .custom-instagram_area .instagram-content {
    padding-bottom: 30px;
  }
}

.custom-instagram_area .instagram-content h2.sub-heading {
  font-size: 56px;
  line-height: 64.4px;
}

@media (max-width: 1199px) {
  .custom-instagram_area .instagram-content h2.sub-heading {
    font-size: 45px;
    line-height: 53.4px;
  }
}

/*----------------------------------------*/
/*  26 - Components - Banner
/*----------------------------------------*/
.banner-area {
  padding-top: 215px;
}

@media (max-width: 991px) {
  .banner-area {
    padding-top: 100px;
  }
}

.banner-area .banner-nav {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media (max-width: 991px) {
  .banner-area .banner-nav {
    flex-direction: column-reverse;
    padding: 30px;
  }
}

.banner-area .banner-nav .single-content {
  padding-left: 220px;
}

@media (max-width: 1199px) {
  .banner-area .banner-nav .single-content {
    padding-left: 80px;
  }
}

@media (max-width: 991px) {
  .banner-area .banner-nav .single-content {
    padding-left: 0;
    padding-top: 25px;
    text-align: center;
  }
}

.banner-area .banner-nav .single-content span.category {
  color: #1d1d1d;
  font-size: 20px;
  line-height: 24px;
  display: block;
  padding-bottom: 10px;
}

.banner-area .banner-nav .single-content h2.product-offer {
  font-size: 80px;
  line-height: 80px;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .banner-area .banner-nav .single-content h2.product-offer {
    font-size: 40px;
    line-height: 40px;
  }
}

.banner-area .banner-nav .single-content p.short-desc {
  font-family: 'Playfair Display';
  color: #1d1d1d;
  font-style: italic;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .banner-area .banner-nav .single-content .button-wrap {
    justify-content: center;
  }
}

.banner-area .banner-nav .single-img {
  display: flex;
}

.banner-area .banner-nav .single-img .front-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 991px) {
  .banner-area .banner-nav .single-img .front-img {
    position: relative;
  }
}

.banner-area .banner-nav .single-img .front-img img {
  width: 85%;
}

@media (max-width: 991px) {
  .banner-area .banner-nav .single-img .front-img img {
    width: 100%;
  }
}

.banner-area .banner-nav .single-img .inner-img {
  background-color: #ffffff;
  width: 75%;
  margin-left: auto;
}

@media (max-width: 991px) {
  .banner-area .banner-nav .single-img .inner-img {
    display: none;
  }
}

/* ---Banner Area Two--- */
.banner-area-2 .single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.banner-area-2 .single-img .inner-content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
}

@media (max-width: 1199px) {
  .banner-area-2 .single-img .inner-content h3.banner-title {
    font-size: 18px;
    line-height: 21.6px;
  }
}

/* ---Banner Area Three--- */
.banner-area-3 .scrollax-bg {
  background-image: url("../images/banner/3-1-1920x840.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
  height: 550px;
}

@media (max-width: 991px) {
  .banner-area-3 .scrollax-bg {
    height: 450px;
  }
}

@media (max-width: 767px) {
  .banner-area-3 .scrollax-bg {
    background-position: left;
  }
}

.banner-area-3 .scrollax-bg .single-content {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 30%;
}

@media (max-width: 1199px) {
  .banner-area-3 .scrollax-bg .single-content {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .banner-area-3 .scrollax-bg .single-content {
    width: calc(100% - 60px);
    left: 30px;
    right: auto;
  }
}

.banner-area-3 .single-content h2.heading {
  font-size: 24px;
  line-height: 1.2;
}

.banner-area-3 .single-content span.product-offer {
  color: #1d1d1d;
  font-size: 36px;
  line-height: 64.8px;
  text-transform: lowercase;
  display: block;
}

.banner-area-3 .single-content span.product-offer strong {
  font-size: 120px;
  line-height: 80px;
  font-weight: 400;
  vertical-align: middle;
}

.banner-area-3 .single-content span.product-offer sup {
  vertical-align: super;
}

/* ---Banner Area Four--- */
.banner-area-4 .banner-bg {
  background-image: url("../images/banner/4-1-1920x494.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
  height: 500px;
}

.banner-area-4 .banner-bg .inner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 575px) {
  .banner-area-4 .banner-bg .inner-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.banner-area-4 .banner-bg .inner-content h2.heading {
  position: relative;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 36px;
  line-height: 43.2px;
}

@media (max-width: 575px) {
  .banner-area-4 .banner-bg .inner-content h2.heading {
    font-size: 26px;
    padding-left: 0;
    padding-right: 0;
  }
}

.banner-area-4 .banner-bg .inner-content h2.heading:before {
  background-color: #D2A35C;
  width: 50px;
  height: 2px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .banner-area-4 .banner-bg .inner-content h2.heading:before {
    content: none;
  }
}

.banner-area-4 .banner-bg .inner-content h2.heading:after {
  background-color: #D2A35C;
  width: 50px;
  height: 2px;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .banner-area-4 .banner-bg .inner-content h2.heading:after {
    content: none;
  }
}

.banner-area-4 .banner-bg .inner-content p.short-desc {
  max-width: 45%;
  text-align: center;
  font-size: 14px;
  line-height: 33.9px;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .banner-area-4 .banner-bg .inner-content p.short-desc {
    max-width: 65%;
  }
}

@media (max-width: 1199px) {
  .banner-area-4 .banner-bg .inner-content p.short-desc {
    max-width: 95%;
  }
}

/* ---Banner Area Five--- */
.banner-area-5 .scrollax-bg {
  background-image: url("../images/banner/5-1-1920x658.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
  height: 550px;
}

@media (max-width: 991px) {
  .banner-area-5 .scrollax-bg {
    height: 450px;
  }
}

@media (max-width: 767px) {
  .banner-area-5 .scrollax-bg {
    background-position: left;
  }
}

.banner-area-5 .scrollax-bg .single-content {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 30%;
}

@media (max-width: 1199px) {
  .banner-area-5 .scrollax-bg .single-content {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .banner-area-5 .scrollax-bg .single-content {
    width: 100%;
    left: 30px;
  }
}

.banner-area-5 .scrollax-bg .single-content h2.heading {
  font-size: 24px;
  line-height: 24px;
}

.banner-area-5 .scrollax-bg .single-content span.product-offer {
  color: #1d1d1d;
  font-size: 36px;
  line-height: 64.8px;
  text-transform: lowercase;
  display: block;
}

.banner-area-5 .scrollax-bg .single-content span.product-offer strong {
  font-size: 120px;
  line-height: 80px;
  font-weight: 400;
  vertical-align: middle;
}

.banner-area-5 .scrollax-bg .single-content span.product-offer sup {
  vertical-align: super;
}

/* ---Banner Area Six--- */
@media (max-width: 767px) {
  .banner-area-6 .single-content {
    padding-top: 20px;
  }
}

.banner-area-6 .single-content span.category {
  padding-bottom: 5px;
  display: block;
}

.banner-area-6 .single-content h2.sub-heading {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 46px;
  line-height: 46px;
}

@media (max-width: 991px) {
  .banner-area-6 .single-content h2.sub-heading {
    font-size: 35px;
  }
}

.banner-area-6 .single-content p.short-desc {
  line-height: 32px;
}

/* ---Banner Area Eight--- */
.banner-area-8 .scrollax-bg {
  background-image: url("../images/banner/7-1920x723.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
  height: 720px;
}

@media (max-width: 1199px) {
  .banner-area-8 .scrollax-bg {
    height: 485px;
    background-position: center;
  }
}

.banner-area-8 .scrollax-bg .single-content {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  text-align: center;
}

@media (max-width: 767px) {
  .banner-area-8 .scrollax-bg .single-content {
    width: calc(100% - 30px);
    left: 15px;
  }
}

.banner-area-8 .scrollax-bg .single-content h2.heading {
  font-family: 'Playfair Display', serif;
  color: #ffffff;
  font-style: italic;
  font-weight: 400;
  font-size: 90px;
  line-height: 90px;
}

@media (max-width: 767px) {
  .banner-area-8 .scrollax-bg .single-content h2.heading {
    font-size: 45px;
    line-height: 50px;
  }
}

.banner-area-8 .scrollax-bg .single-content .popup-btn a.popup-vimeo {
  background-color: #ffffff;
  border-radius: 100%;
  width: 75px;
  height: 75px;
  line-height: 78px;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-size: 34px;
}

.banner-area-8 .scrollax-bg .single-content .popup-btn a.popup-vimeo:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

/* ---Banner Area Nine--- */
.banner-area-9 .banner-item .single-img {
  position: relative;
  cursor: pointer;
}

.banner-area-9 .banner-item .single-img .inner-content {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.banner-area-9 .banner-item .single-img .inner-content.text-position_center {
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.banner-area-9 .banner-item .single-img .inner-content .playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.banner-area-9 .banner-item .single-img .inner-content span.category {
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.banner-area-9 .banner-item .single-img .inner-content h2.heading {
  font-size: 46px;
  line-height: 46px;
}

@media (max-width: 479px) {
  .banner-area-9 .banner-item .single-img .inner-content h2.heading {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575px) {
  .banner-area-9 .banner-item .single-img .inner-content .button-wrap .skudmart-btn {
    width: 110px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
}

.banner-area-9 .banner-item:hover .skudmart-btn {
  background-color: #7aedc9;
  color: #ffffff;
}

@media (max-width: 1199px) {
  .banner-area-9 [class*="col-"]:not(:last-child) .banner-item {
    padding-bottom: 30px;
  }
}

/* ---Banner Area Ten--- */
.banner-area-10 .scrollax-bg {
  background-image: url("../images/banner/10-1-1920x384.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 384px;
}

@media (max-width: 1199px) {
  .banner-area-10 .scrollax-bg {
    height: 360px;
  }
}

.banner-area-10 .scrollax-bg .banner-item {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.banner-area-10 .scrollax-bg .banner-item .banner-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 55%;
  text-align: center;
  z-index: 1;
}

@media (max-width: 575px) {
  .banner-area-10 .scrollax-bg .banner-item .banner-content {
    width: 100%;
  }
}

.banner-area-10 .scrollax-bg .banner-item .banner-content span {
  color: #1d1d1d;
}

.banner-area-10 .scrollax-bg .banner-item .banner-content span.category {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 24px;
  line-height: 24px;
}

.banner-area-10 .scrollax-bg .banner-item .banner-content h2.heading {
  text-shadow: 6px 6px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 88px;
  font-weight: 700;
}

@media (max-width: 991px) {
  .banner-area-10 .scrollax-bg .banner-item .banner-content h2.heading {
    font-size: 50px;
  }
}

.banner-area-10 .scrollax-bg .banner-item .banner-content span.product-offer {
  font-size: 18px;
  line-height: 18px;
}

.banner-area-10 .scrollax-bg .banner-item .banner-img_wrap {
  position: absolute;
  left: 0;
  bottom: -105px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

@media (max-width: 991px) {
  .banner-area-10 .scrollax-bg .banner-item .banner-img_wrap {
    bottom: -70px;
  }
}

@media (max-width: 575px) {
  .banner-area-10 .scrollax-bg .banner-item .banner-img_wrap {
    width: 100%;
  }
}

/* ---Bnner Area Eleven--- */
.banner-area-11 .banner-item {
  height: 100%;
}

.banner-area-11 .banner-item .single-img {
  position: relative;
  height: 100%;
}

.banner-area-11 .banner-item .single-img .inner-content {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.banner-area-11 .banner-item .single-img .inner-content p.short-desc {
  font-size: 14px;
  max-width: 65%;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .banner-area-11 .banner-item .single-img .inner-content p.short-desc {
    max-width: 95%;
  }
}

.banner-area-11 .banner-item .single-img:hover .inner-content {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

@media (max-width: 991px) {
  .banner-area-11 .banner-wrap {
    padding-top: 30px;
  }
}

/* ---Banner Area Twelve--- */
.banner-area-12 .section-title_area-2 span.category {
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  display: block;
}

.banner-area-12 .section-title_area-2 h2.heading {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 2px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .banner-area-12 .section-title_area-2 h2.heading {
    font-size: 30px;
  }
}

.banner-area-12 .section-title_area-2 p.short-desc {
  font-size: 16px;
  max-width: 55%;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 1199px) {
  .banner-area-12 .section-title_area-2 p.short-desc {
    max-width: 85%;
  }
}

@media (max-width: 767px) {
  .banner-area-12 .section-title_area-2 p.short-desc {
    max-width: 100%;
  }
}

.banner-area-12 .banner-slide {
  position: relative;
}

.banner-area-12 .banner-slide .inner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.banner-area-12 .banner-slide .inner-content h3.banner-title {
  font-size: 28px;
  line-height: 33.6px;
  letter-spacing: 2px;
}

/* ---Banner Area Thirteen--- */
.banner-area-13 .banner-item {
  display: flex;
}

@media (max-width: 767px) {
  .banner-area-13 .banner-item {
    flex-direction: column;
  }
}

.banner-area-13 .banner-item .banner-img {
  position: relative;
  width: 50%;
}

@media (max-width: 767px) {
  .banner-area-13 .banner-item .banner-img {
    width: 100%;
  }
}

.banner-area-13 .banner-item .banner-img .inner-img {
  position: absolute;
  right: 50px;
  bottom: 50px;
}

@media (max-width: 575px) {
  .banner-area-13 .banner-item .banner-img .inner-img {
    right: 15px;
    bottom: 50px;
    width: calc(100% - 30px);
  }
}

.banner-area-13 .banner-item .banner-content {
  padding: 5%;
  width: 50%;
}

@media (max-width: 767px) {
  .banner-area-13 .banner-item .banner-content {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .banner-area-13 .banner-item .banner-content {
    padding: 8% 5%;
  }
}

.banner-area-13 .banner-item .banner-content span.category {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  display: block;
  color: #1d1d1d;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 85px;
}

.banner-area-13 .banner-item .banner-content h2.heading {
  font-size: 56px;
  line-height: 67.2px;
  position: relative;
  padding-left: 85px;
}

@media (max-width: 1500px) {
  .banner-area-13 .banner-item .banner-content h2.heading {
    font-size: 40px;
    line-height: 46.2px;
  }
}

@media (max-width: 1199px) {
  .banner-area-13 .banner-item .banner-content h2.heading {
    font-size: 23px;
    line-height: 1.4;
  }
}

@media (max-width: 767px) {
  .banner-area-13 .banner-item .banner-content h2.heading {
    font-size: 20px;
  }
}

.banner-area-13 .banner-item .banner-content h2.heading:before {
  background-color: #1d1d1d;
  content: "";
  width: 60px;
  height: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.banner-area-13 .banner-item .banner-content p.short-desc {
  line-height: 30px;
}

@media (max-width: 767px) {
  .banner-area-13 .banner-item.style-02 {
    flex-direction: column-reverse;
  }
}

/* ---Banner Area Fourteen--- */
.banner-area-14 {
  position: relative;
}

.banner-area-14.banner-bg {
  background-image: url("../images/banner/14-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 665px;
}

@media (max-width: 479px) {
  .banner-area-14.banner-bg {
    height: 440px;
  }
}

.banner-area-14 .banner-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.banner-area-14 .banner-content .inner-content {
  border: 5px solid #1d1d1d;
  padding: 30px;
  width: 445px;
  text-align: center;
}

.banner-area-14 .banner-content .inner-content h3.banner-title {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
}

.banner-area-14 .banner-content .inner-content h4.product-offer {
  font-size: 80px;
  line-height: 80px;
  letter-spacing: 6px;
}

@media (max-width: 479px) {
  .banner-area-14 .banner-content .inner-content h4.product-offer {
    font-size: 40px;
    line-height: 1.4;
    letter-spacing: 2px;
  }
}

.banner-area-14 .banner-content .inner-content span {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 14px;
  text-transform: uppercase;
  color: #1d1d1d;
  font-weight: 500;
  display: block;
}

@media (max-width: 479px) {
  .banner-area-14 .banner-content .inner-content span {
    line-height: 1.4;
    letter-spacing: 2px;
  }
}

/* ---Banner Area Fifteen--- */
.banner-area-15 .parallax-banner {
  background-repeat: no-repeat;
  background-size: cover;
  height: 940px;
}

@media (max-width: 767px) {
  .banner-area-15 .parallax-banner {
    height: 550px;
  }
}

.banner-area-15 .parallax-banner.bg-01 {
  background-image: url("../images/banner/15-1.webp");
}

.banner-area-15 .parallax-banner.bg-02 {
  background-image: url("../images/banner/15-2.webp");
}

.banner-area-15 .parallax-banner.bg-03 {
  background-image: url("../images/banner/15-3.webp");
}

.banner-area-15 .parallax-banner.bg-04 {
  background-image: url("../images/banner/15-4.webp");
}

.banner-area-15 .parallax-banner .inner-content {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.banner-area-15 .parallax-banner .inner-content h1.heading {
  font-size: 56px;
  line-height: 56px;
  letter-spacing: 14px;
}

@media (max-width: 991px) {
  .banner-area-15 .parallax-banner .inner-content h1.heading {
    font-size: 45px;
    letter-spacing: 5px;
  }
}

@media (max-width: 767px) {
  .banner-area-15 .parallax-banner .inner-content h1.heading {
    font-size: 26px;
  }
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection {
  color: #1d1d1d;
  position: relative;
  font-size: 26px;
  line-height: 31.2px;
  display: block;
}

@media (max-width: 767px) {
  .banner-area-15 .parallax-banner .inner-content span.banner-collection {
    font-size: 16px;
  }
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection:before {
  border-top: 3px solid;
  width: 30px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection:after {
  border-top: 3px solid;
  width: 30px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:before {
  width: 200px;
  margin-right: 25px;
}

@media (max-width: 1199px) {
  .banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:before {
    width: 50px;
  }
}

@media (max-width: 1199px) {
  .banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:before {
    width: 30px;
  }
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:after {
  width: 200px;
  margin-left: 25px;
}

@media (max-width: 1199px) {
  .banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:after {
    width: 50px;
  }
}

@media (max-width: 1199px) {
  .banner-area-15 .parallax-banner .inner-content span.banner-collection.lg-size:after {
    width: 30px;
  }
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.md-size:before {
  width: 50px;
  margin-right: 25px;
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.md-size:after {
  width: 50px;
  margin-left: 25px;
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.white-color {
  color: #ffffff;
}

.banner-area-15 .parallax-banner .inner-content span.banner-collection.white-color:before, .banner-area-15 .parallax-banner .inner-content span.banner-collection.white-color:after {
  color: #ffffff;
}

.banner-area-15 .parallax-banner .inner-content.style-02 .category {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 32px;
  line-height: 32px;
  display: block;
}

.banner-area-15 .parallax-banner .inner-content.style-02 h1.heading {
  font-style: italic;
  letter-spacing: 0;
}

.banner-area-15 .parallax-banner .inner-content.style-03 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---Banner Area Sixteen--- */
.banner-area-16 .banner-item .single-img {
  position: relative;
}

.banner-area-16 .banner-item .single-img .inner-content {
  position: absolute;
  left: 85px;
  bottom: 85px;
  z-index: 1;
}

@media (max-width: 767px) {
  .banner-area-16 .banner-item .single-img .inner-content {
    left: 30px;
    width: calc(100% - 60px);
  }
}

.banner-area-16 .banner-item .single-img .inner-content span.category {
  font-size: 32px;
  line-height: 38.4px;
  letter-spacing: 2px;
  display: block;
}

@media (max-width: 1199px) {
  .banner-area-16 .banner-item .single-img .inner-content span.category {
    font-size: 16px;
    line-height: 1.4;
    padding-bottom: 0;
  }
}

.banner-area-16 .banner-item .single-img .inner-content span.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.banner-area-16 .banner-item .single-img .inner-content h3.product-offer {
  font-size: 100px;
  line-height: 100px;
  letter-spacing: 14px;
}

@media (max-width: 1500px) {
  .banner-area-16 .banner-item .single-img .inner-content h3.product-offer {
    font-size: 70px;
    line-height: 1.4;
  }
}

@media (max-width: 1199px) {
  .banner-area-16 .banner-item .single-img .inner-content h3.product-offer {
    font-size: 40px;
    padding-bottom: 15px;
  }
}

@media (max-width: 479px) {
  .banner-area-16 .banner-item .single-img .inner-content h3.product-offer {
    font-size: 25px;
  }
}

.banner-area-16 .banner-item .single-img .inner-content.text-position_center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.banner-area-16 .banner-item .single-img:hover .skudmart-btn {
  background-color: #D2A35C;
  color: #ffffff;
}

@media (max-width: 991px) {
  .banner-area-16 [class*="col-"]:not(:last-child) .banner-item {
    padding-bottom: 30px;
  }
}

/* ---Banner Area Seventeen--- */
.banner-area-17.with-bg {
  background-image: url("../images/banner/17-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
}

@media (max-width: 767px) {
  .banner-area-17.with-bg {
    height: 520px;
  }
}

.banner-area-17 .banner-wrap {
  background-color: #ffffff;
  position: absolute;
  width: 620px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .banner-area-17 .banner-wrap {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

.banner-area-17 .banner-wrap .banner-inner_slider {
  height: 100%;
}

.banner-area-17 .banner-wrap .banner-inner_slider .banner-item .inner-content {
  padding: 75px 30px 80px;
  text-align: center;
}

.banner-area-17 .banner-wrap .banner-inner_slider .banner-item .inner-content span.category {
  color: #D2A35C;
  font-size: 18px;
  line-height: 21.4px;
  letter-spacing: 1px;
  display: block;
}

.banner-area-17 .banner-wrap .banner-inner_slider .banner-item .inner-content h3.heading {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 66px;
  line-height: 66px;
  max-width: 75%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .banner-area-17 .banner-wrap .banner-inner_slider .banner-item .inner-content h3.heading {
    max-width: 100%;
    font-size: 40px;
    line-height: 1.4;
  }
}

/* ---Banner Area Eighteen--- */
.banner-area-18 .with-bg {
  background-image: url("../images/banner/18-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

@media (max-width: 767px) {
  .banner-area-18 .with-bg {
    height: 450px;
  }
}

.banner-area-18 .inner-content {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  text-align: center;
}

@media (max-width: 767px) {
  .banner-area-18 .inner-content {
    width: calc(100% - 30px);
    left: 0;
  }
}

.banner-area-18 .inner-content span.category {
  color: #D2A35C;
  font-size: 32px;
  line-height: 32px;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
}

.banner-area-18 .inner-content h3.product-offer {
  font-size: 80px;
  line-height: 80px;
}

@media (max-width: 767px) {
  .banner-area-18 .inner-content h3.product-offer {
    font-size: 34px;
    line-height: 1.2;
  }
}

.banner-area-18 .inner-content h3.product-offer strong {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  display: block;
  letter-spacing: 2px;
}

/* ---Banner Area Nineteen--- */
@media (min-width: 1280px) {
  .banner-area-19 {
    width: calc(100% - 100px);
    margin-left: auto;
  }
}

.banner-area-19 .banner-item .single-img .inner-content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 991px) {
  .banner-area-19 .banner-item .single-img .inner-content h1.heading {
    font-size: 25px;
  }
}

.banner-area-19 .banner-item:hover .single-img .inner-content {
  top: 50%;
  visibility: visible;
  opacity: 1;
}

/*----------------------------------------*/
/*  27 - Components - Shipping
/*----------------------------------------*/
.shipping-area .shipping-nav.with-border_bottom {
  border-top: 1px solid #d8d8d8;
}

.shipping-area .shipping-nav.with-border {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.shipping-item {
  text-align: center;
}

@media (max-width: 991px) {
  .shipping-item {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .shipping-item {
    padding-bottom: 25px;
  }
}

.shipping-item span {
  color: #1d1d1d;
  padding-bottom: 15px;
  font-size: 40px;
  display: block;
}

.shipping-item h4.heading {
  font-size: 18px;
  line-height: 21.6px;
}

.shipping-item a {
  color: inherit;
  font-size: 14px;
  line-height: 14px;
}

.shipping-item.style-02 h4.heading {
  font-size: 14px;
  line-height: 16.4px;
  text-transform: uppercase;
}

.shipping-item.style-02 p.short-desc {
  font-size: 14px;
  line-height: 21.2px;
  margin: 0 auto;
}

@media (min-width: 1501px) {
  .shipping-item.style-02 p.short-desc {
    max-width: 80%;
  }
}

/*----------------------------------------*/
/*  52 - Pages - Newsletter
/*----------------------------------------*/
.newsletter-item {
  display: flex;
}

@media (max-width: 767px) {
  .newsletter-item {
    flex-direction: column;
    align-items: center;
  }
}

.newsletter-item h3.heading {
  width: 35%;
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: 991px) {
  .newsletter-item h3.heading {
    font-size: 26px;
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .newsletter-item h3.heading {
    padding-bottom: 15px;
    text-align: center;
    width: 100%;
  }
}

.newsletter-item .subscribe-widget {
  width: 65%;
}

@media (max-width: 767px) {
  .newsletter-item .subscribe-widget {
    width: 100%;
  }
}

.newsletter-item .subscribe-widget li.subscribe-form_wrap form {
  position: relative;
}

.newsletter-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  background-color: transparent;
  width: 100%;
  padding-right: 90px;
  font-size: 14px;
  line-height: 1.8;
}

.newsletter-item .subscribe-widget li.subscribe-form_wrap form input.input-field::placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter-item .subscribe-widget li.subscribe-form_wrap form input.with-border_bottom {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.newsletter-item .subscribe-widget li.subscribe-form_wrap form button.subscribe-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.newsletter-item.style-02 {
  height: 100%;
  align-items: center;
}

.newsletter-item.style-02 .inner-item {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  padding-top: 65px;
  padding-bottom: 70px;
  width: 970px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .newsletter-item.style-02 .inner-item {
    width: calc(100% - 30px);
  }
}

.newsletter-item.style-02 .inner-item h3.heading {
  font-size: 24px;
  line-height: 24px;
  width: 100%;
  text-align: center;
}

@media (max-width: 479px) {
  .newsletter-item.style-02 .inner-item h3.heading {
    line-height: 1.4;
  }
}

.newsletter-item.style-02 .inner-item .subscribe-widget {
  width: calc(100% - 200px);
  margin: 0 auto;
}

@media (max-width: 991px) {
  .newsletter-item.style-02 .inner-item .subscribe-widget {
    width: calc(100% - 30px);
  }
}

.newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  height: 70px;
  padding-left: 30px;
  padding-right: 120px;
}

@media (max-width: 479px) {
  .newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
    padding-left: 15px;
    padding-right: 90px;
  }
}

.newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap form input::placeholder {
  color: inherit;
  opacity: 1;
}

.newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap button.subscribe-btn {
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap button.subscribe-btn {
    right: 15px;
  }
}

.newsletter-item.style-02 .inner-item .subscribe-widget li.subscribe-form_wrap button.subscribe-btn:hover {
  color: #D2A35C;
}

.newsletter-item.style-03 .inner-item {
  width: 100%;
}

.newsletter-item.style-03 .inner-item h3.heading {
  width: 100%;
  font-size: 32px;
  line-height: 32px;
}

.newsletter-item.style-03 .inner-item .subscribe-widget {
  width: 100%;
}

.newsletter-item.style-03 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  border-color: #1d1d1d;
}

.newsletter-item.style-03 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field::placeholder {
  color: #1d1d1d;
}

.newsletter-item.style-04 {
  justify-content: center;
  text-align: center;
  text-align: left;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .newsletter-item.style-04 {
    width: 770px;
  }
}

.newsletter-item.style-04 .inner-item {
  width: 100%;
}

.newsletter-item.style-04 .inner-item h3.heading {
  color: #ffffff;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
}

.newsletter-item.style-04 .inner-item .subscribe-widget {
  width: 100%;
}

.newsletter-item.style-04 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  background-color: #ffffff;
  height: 70px;
  padding-left: 30px;
  padding-right: 75px;
}

.newsletter-item.style-04 .inner-item .subscribe-widget li.subscribe-form_wrap form input::placeholder {
  color: #1d1d1d;
  opacity: 1;
}

.newsletter-item.style-04 .inner-item .subscribe-widget li.subscribe-form_wrap button.subscribe-btn {
  height: 70px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  text-align: center;
  text-transform: uppercase;
}

.newsletter-item.style-05 {
  justify-content: center;
  text-align: center;
}

.newsletter-item.style-05 .inner-item {
  width: calc(100% - 400px);
  margin: 0 auto;
}

@media (max-width: 991px) {
  .newsletter-item.style-05 .inner-item {
    width: 100%;
  }
}

.newsletter-item.style-05 .inner-item h3.heading {
  width: 100%;
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: 479px) {
  .newsletter-item.style-05 .inner-item h3.heading {
    font-size: 30px;
  }
}

.newsletter-item.style-05 .inner-item p.short-desc {
  color: #1d1d1d;
  max-width: 75%;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .newsletter-item.style-05 .inner-item p.short-desc {
    max-width: 100%;
  }
}

.newsletter-item.style-05 .inner-item .subscribe-widget {
  width: 100%;
}

.newsletter-item.style-05 .inner-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  height: 70px;
  padding-left: 30px;
  padding-right: 150px;
}

.newsletter-item.style-05 .inner-item .subscribe-widget li.subscribe-form_wrap form input::placeholder {
  color: inherit;
  opacity: 1;
}

.newsletter-item.style-05 .inner-item .subscribe-widget li.subscribe-form_wrap button.subscribe-btn {
  background-color: #1d1d1d;
  color: #ffffff;
  height: 70px;
  padding: 0 15px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  text-transform: uppercase;
}

/* ---Newsletter Area Two--- */
.newsletter-area.bg-1 {
  background-image: url("../images/newsletter/bg/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 510px;
}

.newsletter-area.bg-2 {
  background-image: url("../images/newsletter/bg/2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 560px;
}

.newsletter-area.bg-3 {
  background-image: url("../images/newsletter/bg/3.webp");
  background-repeat: no-repeat;
  background-color: #e1f9ee;
  background-position: 80% 58%;
  height: 265px;
}

.newsletter-area.bg-4 {
  background-image: url("../images/newsletter/bg/4.webp");
  background-repeat: no-repeat;
  height: 250px;
}

/*----------------------------------------*/
/*  28 - Components - Multiple Section
/*----------------------------------------*/
.multiple-section {
  position: relative;
}

@media (min-width: 1280px) {
  .multiple-section {
    width: calc(100% - 370px);
    margin-left: auto;
  }
}

.multiple-section .multiple-section_body .product-item {
  text-align: center;
}

.multiple-section .multiple-section_body .product-item .single-content {
  position: relative;
  transition-delay: 280ms;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
}

.multiple-section .multiple-section_body .product-item .single-content .product-name {
  font-size: 14px;
}

.multiple-section .multiple-section_body .product-item:hover .single-content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.multiple-section .multiple-section_body .banner-item.style-01 .inner-content {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
}

.multiple-section .multiple-section_body .banner-item.style-01 .inner-content span.category {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 21.6px;
  letter-spacing: 1px;
  display: block;
}

.multiple-section .multiple-section_body .banner-item.style-01 .inner-content h2.heading {
  font-size: 66px;
  line-height: 66px;
}

.multiple-section .multiple-section_body .banner-item.style-01 .inner-content h2.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.multiple-section .multiple-section_body .banner-item.style-02 .inner-content .inner-img img {
  transform: scale(1);
}

.multiple-section .multiple-section_body .banner-item.style-02:hover .inner-img img {
  transform: scale(0.8);
}

.multiple-section .header-logo {
  text-align: left;
}

@media (min-width: 1280px) {
  .multiple-section .header-logo {
    display: none;
  }
}

@media (min-width: 1280px) {
  .multiple-section .header-right ul li.mobile-menu_wrap {
    display: none;
  }
}

/* ---Multiple Section Two--- */
.multiple-section-2 .custom-col {
  flex: 0 0 20%;
  max-width: 20%;
}

@media (max-width: 1500px) {
  .multiple-section-2 .custom-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.multiple-section-2 .custom-col-2 {
  flex: 0 0 80%;
  max-width: 80%;
}

@media (max-width: 1500px) {
  .multiple-section-2 .custom-col-2 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (max-width: 1300px) {
  .multiple-section-2 .custom-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1300px) {
  .multiple-section-2 .multiple-section_sidebar {
    display: none;
  }
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .heading-area {
  background-color: #7aedc9;
  display: flex;
  padding: 15px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .heading-area i {
  color: #1d1d1d;
  margin-right: 10px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .heading-area h3.heading {
  font-size: 18px;
  line-height: 21.6px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item {
  border: 1px solid #d8d8d8;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .product-link {
  text-transform: uppercase;
  position: relative;
  padding-top: 10px;
  font-size: 13px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .product-link:before {
  background-color: #d8d8d8;
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  height: 1px;
  width: 40px;
  transform: translateX(-50%);
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item ul.widget-category li:not(:last-child).title > a {
  padding-bottom: 15px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item ul.widget-category li.title > a {
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item ul.widget-category li > ul li:not(:last-child) a {
  padding-bottom: 5px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item ul.widget-category li > ul li a {
  font-size: 14px;
  line-height: 25.2px;
  display: block;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product {
  display: flex;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product[class*="list-product"]:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-img {
  border: 1px solid #d8d8d8;
  width: 30%;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-img img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-content {
  padding-left: 15px;
  width: 70%;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-content .product-name {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 23px;
  display: block;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-content .price-box span {
  font-size: 14px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .list-product .single-content .price-box span.new-price {
  color: #1d1d1d;
  font-weight: 500;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item p.short-desc {
  font-size: 14px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item .subscribe-widget {
  width: 100%;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item .subscribe-widget li.subscribe-form_wrap form input.input-field {
  width: 100%;
  height: 50px;
  padding: 15px 100px 15px 15px;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item .subscribe-widget li.subscribe-form_wrap form input::placeholder {
  color: #1d1d1d;
  opacity: 1;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item .subscribe-widget li.subscribe-form_wrap form button.subscribe-btn {
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .newsletter-item .subscribe-widget li.subscribe-form_wrap form button.subscribe-btn:hover {
  color: inherit;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .tag-list li {
  display: inline-block;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .tag-list li a {
  border: 1px solid #d8d8d8;
  text-emphasis: none;
  font-size: 12px;
  padding: 4px 8px;
  margin: 0 2px 5px;
  display: block;
}

.multiple-section-2 .multiple-section_sidebar .widget-item_area .widget-item .tag-list li a:hover {
  background-color: #7aedc9;
  border-color: #7aedc9;
  color: #ffffff;
}

.multiple-section-2 .multiple-section_banner.bg-01 {
  background-image: url("../images/multiple-section/bg/1-1402-220.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}

.multiple-section-2 .multiple-section_banner.bg-02 {
  background-image: url("../images/multiple-section/bg/2-1402-220.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 767px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content {
    flex-direction: column;
    justify-content: center;
  }
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside {
  width: 50%;
  text-align: center;
}

@media (max-width: 767px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside {
    width: 100%;
  }
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside .font-italic * {
  font-style: italic;
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside span.category {
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: 991px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside span.category {
    font-size: 16px;
    line-height: 1.2;
  }
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside h2.heading {
  font-size: 66px;
  line-height: 66px;
}

@media (max-width: 1500px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside h2.heading {
    font-size: 55px;
  }
}

@media (max-width: 991px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-leftside h2.heading {
    font-size: 25px;
    line-height: 1.2;
  }
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside {
  padding-left: 20%;
  width: 50%;
}

@media (max-width: 767px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }
}

.multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside h3.product-offer {
  font-size: 80px;
  line-height: 80px;
}

@media (max-width: 1500px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside h3.product-offer {
    font-size: 65px;
  }
}

@media (max-width: 991px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside h3.product-offer {
    font-size: 25px;
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .multiple-section-2 .multiple-section_banner.style-01 .inner-content .text-rightside .button-wrap {
    justify-content: center;
  }
}

.multiple-section-2 .multiple-section_banner.style-02 {
  position: relative;
}

.multiple-section-2 .multiple-section_banner.style-02 .inner-content {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 50%;
  text-align: center;
}

@media (max-width: 767px) {
  .multiple-section-2 .multiple-section_banner.style-02 .inner-content {
    width: 100%;
  }
}

.multiple-section-2 .multiple-section_banner.style-02 .inner-content h2.heading {
  background: linear-gradient(to 270deg, #f64d4d 0%, #c965b7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 66px;
  line-height: 66px;
}

@media (max-width: 1500px) {
  .multiple-section-2 .multiple-section_banner.style-02 .inner-content h2.heading {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .multiple-section-2 .multiple-section_banner.style-02 .inner-content h2.heading {
    font-size: 25px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .multiple-section-2 .testimonial-with_instagram .section-space {
    padding-bottom: 30px;
  }
}

.multiple-section-2 .testimonial-with_instagram .testimonial-area {
  padding: 45px;
  height: 100%;
}

.multiple-section-2 .testimonial-with_instagram .testimonial-area h2.heading {
  font-size: 22px;
  line-height: 22px;
}

.multiple-section-2 .testimonial-with_instagram .testimonial-area .single-testimonial_slider .testimonial-item .single-content {
  padding-right: 0;
}

.multiple-section-2 .testimonial-with_instagram .testimonial-area .single-testimonial_slider .swiper-pagination {
  position: relative;
  bottom: 60px;
}

.multiple-section-2 .testimonial-with_instagram .instagram-area {
  padding: 45px;
  height: 100%;
}

/*----------------------------------------*/
/*  35 - Section - Header
/*----------------------------------------*/
/* ---Header Top--- */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 991px) {
  .header-top {
    justify-content: flex-end;
  }
}

.header-top .header-shipping {
  font-size: 12px;
  line-height: 21.6px;
}

.header-top .header-shipping span {
  font-weight: 500;
}

.header-top .hassub-item li.has-sub {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.header-top .hassub-item li.has-sub:not(:last-child) {
  margin-right: 15px;
}

.header-top .hassub-item li.has-sub a {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 21.6px;
  font-weight: 500;
}

@media (max-width: 575px) {
  .header-top .hassub-item li.has-sub a {
    font-size: 10px;
  }
}

.header-top .hassub-item li.has-sub a i {
  font-size: 10px;
  margin-left: 5px;
}

.header-top .hassub-item li.has-sub ul.hassub-body {
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 140px;
  display: none;
  position: absolute;
  top: 30px;
  left: auto;
  right: 0;
  z-index: 2;
  padding: 20px;
  text-align: right;
}

.header-top .hassub-item li.has-sub ul.hassub-body.different-width-75 {
  width: 75px;
}

.header-top .hassub-item li.has-sub ul.hassub-body.different-width-100 {
  width: 100px;
}

@media (max-width: 991px) {
  .header-top.style-02 {
    justify-content: space-between;
  }
}

.header-top.style-02 ul li.myaccount-wrap a {
  text-transform: uppercase;
  font-size: 11px;
}

/* ---Main Header Nav--- */
.main-header_nav {
  align-items: center;
}

@media (max-width: 1199px) {
  .main-header_nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* ---Main Header--- */
.main-header .main-nav > ul > li {
  display: inline-block;
}

.main-header .main-nav > ul > li:not(:last-child) {
  padding-right: 20px;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown {
  background-color: #ffffff;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.09);
  top: calc(100% + 20px);
  position: absolute;
  padding: 30px;
  width: 230px;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li:not(:last-child) {
  padding-bottom: 20px;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li a {
  display: block;
  font-size: 14px;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li a i {
  font-size: 11px;
  margin-right: 5px;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li a span.submenu-indicate {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 11px;
  margin-right: 0;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder {
  position: relative;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder ul.skudmart-submenu {
  background-color: #ffffff;
  width: 230px;
  padding: 30px;
  top: 0;
  left: calc(100% + 30px);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder ul.skudmart-submenu > li > a {
  display: block;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder ul.skudmart-submenu > li > a i {
  font-size: 11px;
  vertical-align: middle;
  margin-right: 5px;
}

.main-header .main-nav > ul > li > ul.skudmart-dropdown li.submenu-holder:hover > ul.skudmart-submenu {
  opacity: 1;
  visibility: visible;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap {
  background-color: #ffffff;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.09);
  position: absolute;
  padding: 50px;
  width: 870px;
  top: calc(100% + 20px);
  left: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu {
  display: flex;
  justify-content: space-between;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu > li {
  width: 50%;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu > li span.title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #1d1d1d;
  padding-bottom: 20px;
  display: block;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu > li a {
  font-size: 14px;
  line-height: 25.2px;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu > li a:hover {
  text-decoration: underline;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap > ul.sku-megamenu > li ul li:not(:last-child) {
  padding-bottom: 15px;
}

.main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap.different-width {
  width: 1170px;
}

@media (max-width: 1500px) {
  .main-header .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap.different-width {
    width: 1120px;
  }
}

.main-header .main-nav > ul > li.megamenu-holder:hover .sku-megamenu_wrap {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.main-header .main-nav > ul > li > a {
  display: block;
  padding: 45px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.main-header .main-nav > ul > li:hover ul.skudmart-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.main-header.white-color .main-nav > ul {
  display: flex;
}

.main-header.white-color .main-nav > ul > li:hover > a {
  color: #ffffff;
}

.main-header.white-color .main-nav > ul > li > a {
  color: #ffffff;
}

.main-header.white-color .header-right ul li.with-border_bottom input.input-field {
  border-color: #ffffff;
  color: #ffffff;
}

.main-header.white-color .header-right ul li.with-border_bottom input::placeholder {
  color: #ffffff;
}

.main-header.white-color .header-right ul li a {
  color: #ffffff;
}

.main-header.white-color .header-right ul li button i {
  color: #ffffff;
}

@media (max-width: 1199px) {
  .main-header .main-menu .main-nav > ul > li.megamenu-holder .sku-megamenu_wrap {
    width: 100%;
  }
}

.main-header .main-menu .main-nav > ul > li ul > li.submenu-holder ul.skudmart-submenu {
  left: auto;
  right: calc(100% + 30px);
}

.main-header .main-menu.menu-style_two {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ---Transparent Header--- */
.transparent-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

/* ---Header Logo--- */
.header-logo {
  text-align: center;
}

@media (max-width: 1199px) {
  .header-logo {
    text-align: left;
  }
}

.header-logo img {
  width: 170px;
}

.header-logo.sticky-logo {
  display: none;
}

/* ---Header Right--- */
.header-right ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-right ul li:not(:first-child) {
  padding-left: 20px;
}

@media (max-width: 575px) {
  .header-right ul li:not(:first-child) {
    padding-left: 15px;
  }
}

.header-right ul li a {
  font-size: 18px;
}

.header-right ul li.minicart-wrap {
  position: relative;
}

.header-right ul li.minicart-wrap span.cart-counter {
  background-color: #1d1d1d;
  color: #ffffff;
  border-radius: 100%;
  font-size: 10px;
  width: 18px;
  height: 18px;
  line-height: 17px;
  text-align: center;
  position: absolute;
  top: -15px;
  right: -15px;
}

.header-right ul li.myaccount-wrap a {
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

/* ---Header Middle Form Area--- */
.hm-form_area form.hm-searchbox {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

.hm-form_area form.hm-searchbox .select-search-category {
  width: auto;
  line-height: 43px;
  height: 43px;
  margin: 0;
  border: 0;
  padding: 0 28px 0 25px;
  font-size: 13px;
  border-radius: 15px 0 0 15px;
  position: relative;
}

.hm-form_area form.hm-searchbox .select-search-category:before {
  right: 30px;
  top: 25px;
}

.hm-form_area form.hm-searchbox .select-search-category ul.list {
  height: 440px;
  overflow-y: auto;
  overscroll-behavior-x: none;
}

.hm-form_area form.hm-searchbox .select-search-category .current:after {
  background-color: #d8d8d8;
  content: "";
  position: absolute;
  display: block;
  height: 22px;
  width: 1px;
  top: 10px;
  right: -10px;
  font-size: 13px;
}

.hm-form_area form.hm-searchbox input {
  background-color: transparent;
  font-size: 13px;
  height: 45px;
  border: none;
  width: 100%;
  padding: 0 75px 0 33px;
}

.hm-form_area form.hm-searchbox .search-btn {
  border-left: 1px solid #d8d8d8;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 100%;
}

.hm-form_area form.hm-searchbox .search-btn i {
  vertical-align: middle;
}

/* ---Header Bottom--- */
.header-bottom .hb-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 575px) {
  .header-bottom .hb-wrap .hm-form_area form.hm-searchbox {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .header-bottom .hb-wrap .nice-select {
    display: none;
  }
}

/* ---Sticky Header--- */
.header-sticky {
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -ms-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
  display: none;
}

@media (min-width: 576px) {
  .header-sticky.sticky {
    animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
  }
}

/* ---Header Style Two--- */
.header-style-02 .search-box.with-dark_color {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.header-style-02 .search-box.with-dark_color form input.input-field {
  width: 100%;
  font-size: 22px;
  padding: 5px 30px 5px 0;
  border-color: #1d1d1d;
  color: #1d1d1d;
}

.header-style-02 .search-box.with-dark_color form input.input-field:focus {
  color: #1d1d1d;
}

.header-style-02 .search-box.with-dark_color form button.search-btn {
  font-size: 24px;
}

.header-style-02 .header-right ul li:not(:first-child) {
  padding-left: 30px;
}

@media (max-width: 575px) {
  .header-style-02 .header-right ul li:not(:first-child) {
    padding-left: 15px;
  }
}

.header-style-02 .header-right ul li a {
  font-size: 22px;
}

@media (max-width: 575px) {
  .header-style-02 .header-right ul li a {
    font-size: 20px;
  }
}

/* ---Header Style Four--- */
@media (min-width: 768px) {
  .header-style-04 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}

/* ---Header Style Five--- */
.header-style-05 .main-header_nav {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .header-style-05 .main-header_nav .header-logo {
    text-align: center;
  }
}

/* ---Header Style Six--- */
.header-style-06.main-header.white-color .main-nav > ul {
  display: flex;
}

.header-style-06.main-header.white-color .main-nav > ul > li {
  border-right: 1px solid #666;
  padding-right: 0;
}

.header-style-06.main-header.white-color .main-nav > ul > li:hover {
  background-color: #363b5f;
}

.header-style-06.main-header.white-color .main-nav > ul > li > a {
  padding: 25px;
}

/* ---Header Style Seven--- */
.header-style-07 .main-nav > ul {
  display: flex;
}

.header-style-07 .main-nav > ul > li:not(:last-child) {
  padding-right: 0;
}

.header-style-07 .main-nav > ul > li > a {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 21.6px;
  padding: 20px 15px;
}

.header-style-07 .main-nav > ul > li > a i {
  vertical-align: text-top;
  margin-left: 5px;
}

.header-style-07 .contact-number {
  display: flex;
  justify-content: flex-end;
}

.header-style-07 .contact-number span,
.header-style-07 .contact-number a {
  font-size: 14px;
  line-height: 23.6px;
  text-transform: uppercase;
}

.header-style-07 .contact-number span {
  color: #1d1d1d;
}

/* ---Header Style Eight--- */
.header-style-08 .header-logo_wrap {
  display: flex;
}

.header-style-08 .header-logo_wrap .offcanvas-menu_wrap {
  padding-left: 30px;
}

/* ---Offcanvas Menu Button--- */
.offcanvas-menu_btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}

.offcanvas-menu_btn i {
  vertical-align: middle;
  font-size: 30px;
  padding-right: 5px;
}

/* ---Vertical Fixed Header--- */
.with-vertical_header .vertical-fixed_header {
  display: none;
}

@media (min-width: 1280px) {
  .with-vertical_header .vertical-fixed_header {
    background-color: #1d1d1d;
    position: fixed;
    width: 100px;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    display: block;
  }
  .with-vertical_header .vertical-fixed_header .inner-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .fixed-header_logo {
    width: 20px;
    margin: 0 auto;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom {
    writing-mode: tb-rl;
    display: flex;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .copyright {
    padding-top: 30px;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .copyright span,
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .copyright a {
    font-size: 14px;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .social-link li {
    padding-right: 0;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .social-link li:not(:last-child) {
    padding-bottom: 25px;
  }
  .with-vertical_header .vertical-fixed_header .inner-header .header-bottom .social-link li a {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  .with-vertical_header .main-header {
    display: none;
  }
}

/* ---Vertical Fixed Header Two--- */
.vertical-fixed_header-2 {
  display: none;
}

@media (min-width: 1280px) {
  .vertical-fixed_header-2 {
    box-shadow: 0 0 20px 0 rgba(41, 44, 58, 0.06);
    background-color: #ffffff;
    position: fixed;
    width: 370px;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    display: block;
    z-index: 3;
  }
  .vertical-fixed_header-2 .main-header {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li {
    position: static;
    display: block;
    padding-right: 0;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li > a {
    padding-top: 0;
    padding-bottom: 15px;
    font-size: 32px;
    line-height: 57px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li > a.active {
    color: #D2A35C;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li > a i {
    font-size: 14px;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li .different-width {
    width: 920px;
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li .sku-megamenu_wrap,
  .vertical-fixed_header-2 .main-header .main-nav > ul > li .skudmart-dropdown {
    top: 0;
    left: calc(100% + 50px);
  }
  .vertical-fixed_header-2 .main-header .main-nav > ul > li:hover .sku-megamenu_wrap,
  .vertical-fixed_header-2 .main-header .main-nav > ul > li:hover .skudmart-dropdown {
    top: 0;
    left: 100%;
  }
}

/*----------------------------------------*/
/*  36 - Section - Footer
/*----------------------------------------*/
/* ---Footer Top Area--- */
.footer-top_area.with-border_bottom {
  border-bottom: 1px solid #d8d8d8;
}

@media (max-width: 1199px) {
  .footer-top_area.footer-style-02 {
    text-align: center;
  }
}

.footer-top_area.footer-style-02 .footer-widgets_area p.short-desc {
  padding-bottom: 0;
}

@media (max-width: 1500px) {
  .footer-top_area.footer-style-02 .footer-widgets_area p.short-desc {
    padding-bottom: 10px;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .footer-top_area.footer-style-02 .widget-menu {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .footer-top_area.footer-style-02 .widget-menu ul {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .footer-top_area.footer-style-02 .widget-menu ul li:not(:last-child) {
    padding-right: 0 !important;
    padding-bottom: 10px;
  }
}

/* ---Footer Widget--- */
.footer-widgets_area h2.footer-title {
  padding-bottom: 10px;
}

.footer-widgets_area h2.footer-title a {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
}

.footer-widgets_area p.short-desc {
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 20px;
  max-width: 68%;
}

.footer-widgets_area p.playfair-font {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.footer-widgets_area ul.list-item li {
  display: inline-block;
}

.footer-widgets_area ul.list-item li:not(:last-child) a {
  padding-right: 7px;
}

.footer-widgets_area ul.list-item li:not(:last-child) a:after {
  color: #d8d8d8;
  position: absolute;
  content: "|";
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.footer-widgets_area ul.list-item li a {
  position: relative;
  font-size: 12px;
  line-height: 21.6px;
  text-transform: uppercase;
}

.footer-widgets_area h3.heading {
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 15px;
  font-weight: 600;
}

.footer-widgets_area .footer-widget li:not(:last-child) {
  padding-bottom: 15px;
}

.footer-widgets_area .footer-widget li a {
  font-size: 14px;
  line-height: 22px;
}

.footer-widgets_area .footer-widget li a:hover {
  text-decoration: underline;
}

.footer-widgets_area .footer-widget li.subscribe-form_wrap {
  position: relative;
}

.footer-widgets_area .footer-widget li.subscribe-form_wrap input.input-field {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 160px;
}

.footer-widgets_area .footer-widget li.subscribe-form_wrap .subscribe-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-widgets_area .footer-widget.subscribe-widget h3.heading {
  padding-bottom: 25px;
}

/* ---Footer Widgets Wrap--- */
@media (max-width: 991px) {
  .footer-widgets_wrap [class*="col-"] {
    padding-top: 40px;
  }
}

/* ---Footer Bottom Area--- */
.footer-bottom_area {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (max-width: 575px) {
  .footer-bottom_area .copyright {
    text-align: center;
    padding-bottom: 10px;
  }
}

.footer-bottom_area .copyright span {
  color: initial;
}

.footer-bottom_area .copyright span,
.footer-bottom_area .copyright a {
  font-size: 14px;
  line-height: 22px;
}

.footer-bottom_area .payment-method {
  margin-top: 0;
  text-align: right;
}

@media (max-width: 575px) {
  .footer-bottom_area .payment-method {
    text-align: center;
  }
}

/* ---Footer Dark Color--- */
.footer-area.bg-dark_color .footer-top_area .footer-widgets_area h2.footer-title a {
  text-transform: uppercase;
  color: #ffffff;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area p.short-desc {
  color: #bebebe;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area h3.heading {
  color: #ffffff;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area .social-link li a {
  color: #ffffff;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area .footer-widget li:not(:last-child) {
  padding-bottom: 5px;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area .footer-widget li a {
  color: #bebebe;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area .footer-widget li a:hover {
  color: #ffffff;
}

.footer-area.bg-dark_color .footer-top_area .footer-widgets_area .footer-widget li span {
  color: #bebebe;
  font-size: 14px;
  line-height: 22px;
}

.footer-area.bg-dark_color .footer-top_area [class*="footer-widgets_wrap"]:not(:last-child) {
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .footer-area.bg-dark_color .footer-top_area [class*="footer-widgets_wrap"]:not(:last-child) {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .footer-area.bg-dark_color .footer-top_area [class*="footer-widgets_wrap"] [class*="col-"] {
    padding-top: 20px;
  }
}

.footer-area.bg-dark_color .footer-top_area .widget-menu ul {
  display: flex;
  justify-content: center;
}

.footer-area.bg-dark_color .footer-top_area .widget-menu ul li {
  display: inline-block;
}

.footer-area.bg-dark_color .footer-top_area .widget-menu ul li:not(:last-child) {
  padding-right: 30px;
}

.footer-area.bg-dark_color .footer-top_area .widget-menu ul li a:hover {
  text-decoration: underline;
}

.footer-area.bg-dark_color .footer-bottom_area .copyright {
  text-align: center;
}

.footer-area.bg-dark_color .footer-bottom_area .copyright span,
.footer-area.bg-dark_color .footer-bottom_area .copyright a {
  color: #bebebe;
}

.footer-area.bg-dark_color .footer-bottom_area .copyright a:hover {
  color: #ffffff;
}

.footer-area .with-position {
  padding-bottom: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media (max-width: 991px) {
  .footer-area .with-position {
    text-align: center;
  }
}

.footer-area .with-position .copyright span,
.footer-area .with-position .copyright a {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .footer-area .with-position .copyright span,
  .footer-area .with-position .copyright a {
    font-size: 12px;
  }
}

.footer-area .with-position .footer-widget_item {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .footer-area .with-position .footer-widget_item {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .footer-area .with-position .footer-widget_item {
    flex-direction: column;
    align-items: center;
  }
}

.footer-area .with-position .footer-widget_item .widget-menu {
  padding-right: 25px;
}

@media (max-width: 767px) {
  .footer-area .with-position .footer-widget_item .widget-menu {
    padding-right: 0;
  }
}

.footer-area .with-position .footer-widget_item .widget-menu ul li {
  display: inline-block;
}

.footer-area .with-position .footer-widget_item .widget-menu ul li:not(:last-child) {
  padding-right: 25px;
}

@media (max-width: 1199px) {
  .footer-area .with-position .footer-widget_item .widget-menu ul li:not(:last-child) {
    padding-right: 15px;
  }
}

.footer-area .with-position .footer-widget_item .widget-menu ul li a {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .footer-area .with-position .footer-widget_item .widget-menu ul li a {
    font-size: 12px;
  }
}

@media (max-width: 1199px) {
  .footer-area .with-position .footer-widget_item .social-link li:not(:last-child) {
    padding-right: 15px;
  }
}

.footer-area .with-position .footer-widget_item .social-link li a {
  font-size: 13px;
}

/*----------------------------------------*/
/*  37 - Pages - About Us
/*----------------------------------------*/
.about-banner_area {
  padding-top: 95px;
}

@media (max-width: 991px) {
  .about-banner_area[class*="about-banner_area"]:not(:first-child) {
    padding-top: 90px;
  }
}

.about-banner_area .single-content h2.heading {
  font-family: 'Playfair Display';
  font-style: italic;
  font-size: 50px;
  line-height: 65px;
  max-width: 75%;
  padding-bottom: 15px;
}

@media (max-width: 1199px) {
  .about-banner_area .single-content h2.heading {
    font-size: 32px;
    line-height: 1.4;
    max-width: 80%;
  }
}

@media (max-width: 991px) {
  .about-banner_area .single-content h2.heading {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .about-banner_area .single-content h2.heading {
    padding-top: 20px;
    font-size: 20px;
  }
}

.about-banner_area .single-content h2.heading-2 {
  font-family: 'Playfair Display';
  font-style: italic;
  font-size: 32px;
  line-height: 41px;
  max-width: 75%;
  padding-bottom: 15px;
}

@media (max-width: 1199px) {
  .about-banner_area .single-content h2.heading-2 {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .about-banner_area .single-content h2.heading-2 {
    padding-top: 20px;
    font-size: 20px;
  }
}

/* ---Our Story Area--- */
.our-story_area {
  padding-top: 95px;
}

.our-story_area .inner-bg {
  background-image: url("../images/about-us/bg/1.jpg");
  background-repeat: no-repeat;
  height: 740px;
  position: relative;
}

@media (max-width: 1199px) {
  .our-story_area .inner-bg {
    height: 550px;
  }
}

@media (max-width: 767px) {
  .our-story_area .inner-bg {
    height: 350px;
  }
}

.our-story_area .inner-bg .inner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.our-story_area .inner-bg .inner-content h2.heading {
  color: #ffffff;
  font-size: 150px;
  line-height: 150px;
  letter-spacing: 15px;
  font-weight: 400;
  padding-bottom: 10px;
}

@media (max-width: 1199px) {
  .our-story_area .inner-bg .inner-content h2.heading {
    font-size: 80px;
    line-height: 80px;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .our-story_area .inner-bg .inner-content h2.heading {
    font-size: 25px;
    line-height: 25px;
    padding-bottom: 25px;
  }
}

.our-story_area .inner-bg .inner-content .link-wrap a.link-share {
  background-color: #ffffff;
  color: #1d1d1d;
  width: 60px;
  height: 60px;
  line-height: 66px;
  text-align: center;
  display: block;
  font-size: 20px;
  border-radius: 100%;
}

.our-story_area .inner-bg .inner-content .link-wrap a.link-share:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

/* ---Brand With Section Title--- */
.brand-area.with-section_title .brand-nav {
  border-bottom: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding-top: 55px;
  position: relative;
  margin-top: -60px;
  padding-bottom: 45px;
}

.brand-area.with-section_title .brand-nav .about-page_brand .swiper-wrapper {
  align-items: center;
}

.brand-area.with-section_title .brand-nav .about-page_brand .swiper-wrapper .swiper-slide .brand-item {
  text-align: center;
}

.brand-area.with-section_title .brand-nav .about-page_brand .swiper-wrapper .swiper-slide .brand-item img {
  transform: scale(0.8);
}

/*----------------------------------------*/
/*  38 - Pages - Contact Us
/*----------------------------------------*/
@media (max-width: 991px) {
  .contact-sidebar {
    padding-top: 95px;
  }
}

.contact-sidebar ul.contact-info li {
  display: flex;
  padding-bottom: 30px;
}

.contact-sidebar ul.contact-info li:last-child {
  border-bottom: 1px solid #d8d8d8;
}

.contact-sidebar ul.contact-info li .single-icon {
  padding-right: 10px;
}

.contact-sidebar ul.contact-info li .single-icon i {
  font-size: 20px;
}

.contact-sidebar ul.contact-info li .single-content h5.heading {
  font-size: 14px;
  line-height: 16.8px;
  padding-bottom: 10px;
}

.contact-sidebar ul.contact-info li .single-content span {
  font-size: 13px;
  line-height: 20px;
  display: block;
}

.contact-sidebar ul.additional-info {
  padding-top: 25px;
}

.contact-sidebar ul.additional-info li:not(:last-child) {
  padding-bottom: 5px;
}

.contact-sidebar ul.additional-info li span {
  color: #1d1d1d;
  padding-right: 10px;
  vertical-align: middle;
}

.contact-sidebar ul.additional-info li a {
  font-size: 13px;
  line-height: 21.6px;
}

/* ---Form Message--- */
.form-message {
  margin-top: 15px;
  margin-bottom: 0;
}

.form-message.success {
  color: green;
}

.form-message.error {
  color: red;
}

/*----------------------------------------*/
/*  39 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.coming-soon_area.coming-soon-bg {
  background-image: url("../images/coming-soon/bg/1.webp");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 100vh;
}

.coming-soon_area .inner-content {
  padding-top: 100px;
  overflow-y: auto;
  width: 100%;
}

.coming-soon_area .inner-content h1.heading {
  font-family: 'Pacifico', cursive;
  font-size: 100px;
  line-height: 100px;
  color: #ffffff;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .coming-soon_area .inner-content h1.heading {
    font-size: 35px;
    line-height: 1.2;
  }
}

.coming-soon_area .inner-content .countdown-wrap {
  padding-bottom: 45px;
}

.coming-soon_area .inner-content .button-wrap {
  padding-bottom: 100px;
}

/*----------------------------------------*/
/*  40 - Pages - FAQs
/*----------------------------------------*/
.frequently-area h2.heading {
  padding-bottom: 60px;
  font-size: 24px;
  line-height: 24px;
}

.frequently-area .frequently-item ul li.has-sub, .frequently-area .frequently-item ul li.children {
  border: 1px solid #d8d8d8;
  padding: 20px;
}

@media (max-width: 767px) {
  .frequently-area .frequently-item ul li.has-sub, .frequently-area .frequently-item ul li.children {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .frequently-area .frequently-item ul li.has-sub:not(:last-child), .frequently-area .frequently-item ul li.children:not(:last-child) {
    margin-bottom: 20px;
  }
}

.frequently-area .frequently-item ul li.has-sub > a, .frequently-area .frequently-item ul li.children > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-weight: 600;
  cursor: pointer;
}

.frequently-area .frequently-item ul li.has-sub > ul.frequently-body, .frequently-area .frequently-item ul li.children > ul.frequently-body {
  display: none;
  padding-top: 20px;
}

.frequently-area .frequently-item ul li.has-sub > ul.frequently-body li, .frequently-area .frequently-item ul li.children > ul.frequently-body li {
  font-size: 12px;
  line-height: 21.6px;
  color: #454545;
}

.frequently-area .frequently-item ul li.has-sub.open a, .frequently-area .frequently-item ul li.children.open a {
  color: #D2A35C;
}

.frequently-area .frequently-item ul li.has-sub.open a i:before, .frequently-area .frequently-item ul li.children.open a i:before {
  content: "\ea68";
}

/*----------------------------------------*/
/*  41 - Pages - Error 404
/*----------------------------------------*/
.error-404_area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.error-404_area.error-404-bg {
  background-image: url("../images/error-404/bg/1.jpg");
  background-repeat: no-repeat;
  height: 100vh;
}

.error-404_area .error-content h1.heading {
  font-size: 250px;
  line-height: 1;
}

@media (max-width: 767px) {
  .error-404_area .error-content h1.heading {
    font-size: 100px;
  }
}

.error-404_area .error-content h2.sub-heading {
  font-size: 46px;
  line-height: 1.27;
}

@media (max-width: 767px) {
  .error-404_area .error-content h2.sub-heading {
    font-size: 25px;
  }
}

.error-404_area .error-content .button-wrap {
  padding-top: 25px;
}

/*----------------------------------------*/
/*  42 - Pages - Collections
/*----------------------------------------*/
@media (max-width: 767px) {
  .classic-banner_area .single-content {
    padding-top: 30px;
  }
}

.classic-banner_area .single-content span.category {
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 10px;
  display: block;
}

.classic-banner_area .single-content h2.heading {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 46px;
  line-height: 1.2;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .classic-banner_area .single-content h2.heading {
    font-size: 30px;
  }
}

.classic-banner_area .single-content p.short-desc {
  font-size: 16px;
  line-height: 32px;
  padding-bottom: 30px;
}

/* ---Collection Slider--- */
.collection-slider {
  height: 100%;
}

.collection-slider .swiper-slide {
  height: 100%;
}

.collection-slider .swiper-slide.swiper-slide-active .single-item .single-content {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 1;
}

.collection-slider .swiper-slide .single-item {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 767px) {
  .collection-slider .swiper-slide .single-item {
    flex-direction: column;
  }
}

.collection-slider .swiper-slide .single-item .single-img {
  width: 50%;
  height: 100%;
}

@media (max-width: 767px) {
  .collection-slider .swiper-slide .single-item .single-img {
    width: 100%;
  }
}

.collection-slider .swiper-slide .single-item .single-img .inner-img {
  height: 100%;
}

.collection-slider .swiper-slide .single-item .single-img .inner-img img {
  height: 100%;
}

.collection-slider .swiper-slide .single-item .single-content {
  padding-left: 80px;
  width: 50%;
  opacity: 0;
}

@media (max-width: 991px) {
  .collection-slider .swiper-slide .single-item .single-content {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .collection-slider .swiper-slide .single-item .single-content {
    padding-top: 25px;
    padding-left: 0;
    width: 100%;
  }
}

.collection-slider .swiper-slide .single-item .single-content span.category {
  padding-bottom: 5px;
  display: block;
}

.collection-slider .swiper-slide .single-item .single-content h3.heading {
  font-size: 36px;
  line-height: 36px;
}

.collection-slider .swiper-slide .single-item .single-content .link-share {
  display: flex;
}

.collection-slider .swiper-slide .single-item .single-content .link-share li {
  display: inline-block;
}

.collection-slider .swiper-slide .single-item .single-content .link-share li:not(:last-child) {
  padding-right: 25px;
}

.collection-slider .swiper-slide .single-item .single-content .link-share li a {
  font-size: 14px;
  line-height: 25.2px;
}

/* ---Popular Collection--- */
.popular-collection_area .masonry-grid {
  margin-left: -5px;
  margin-right: -5px;
}

.popular-collection_area .masonry-grid .grid-item {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.popular-collection_area .collection-item .button-wrap {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.popular-collection_area .collection-item .button-wrap a.skudmart-btn {
  text-transform: uppercase;
}

.popular-collection_area .collection-item .button-wrap a.skudmart-btn:hover {
  color: #ffffff;
}

.popular-collection_area .collection-item:hover .button-wrap {
  bottom: 0;
}

/* ---Shop Instagram--- */
.shop-instagram-area .instagram-item {
  margin-top: 30px;
}

/*----------------------------------------*/
/*  43 - Pages - Shop
/*----------------------------------------*/
/* ---Skukmart Toolbar--- */
.skukmart-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .skukmart-toolbar {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .skukmart-toolbar .product-page_count {
    padding-bottom: 20px;
  }
}

.skukmart-toolbar .toolbar-right_side {
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .skukmart-toolbar .toolbar-right_side {
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .skukmart-toolbar .toolbar-right_side {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .skukmart-toolbar .toolbar-right_side .product-view-mode {
    padding-bottom: 25px;
  }
}

.skukmart-toolbar .toolbar-right_side .product-view-mode a {
  color: #707070;
  margin-right: 25px;
}

@media (max-width: 575px) {
  .skukmart-toolbar .toolbar-right_side .product-view-mode a:last-child {
    margin-right: 0;
  }
}

.skukmart-toolbar .toolbar-right_side .product-view-mode a.active {
  color: #D2A35C;
}

.skukmart-toolbar .toolbar-right_side ul.product-selection {
  display: flex;
}

@media (max-width: 479px) {
  .skukmart-toolbar .toolbar-right_side ul.product-selection {
    flex-direction: column;
    width: 100%;
  }
}

.skukmart-toolbar .toolbar-right_side ul.product-selection > li:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 479px) {
  .skukmart-toolbar .toolbar-right_side ul.product-selection > li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
}

.skukmart-toolbar .toolbar-right_side ul.product-selection > li.filter-selection {
  border: 1px solid #d8d8d8;
  padding: 0 20px;
  height: 41px;
  line-height: 41px;
}

@media (max-width: 479px) {
  .skukmart-toolbar .toolbar-right_side ul.product-selection > li.filter-selection a.filter-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.skukmart-toolbar .toolbar-right_side ul.product-selection > li.filter-selection a.filter-btn i {
  margin-left: 5px;
}

.skukmart-toolbar .toolbar-right_side ul.product-selection > li.filter-selection a.filter-btn.active {
  color: #D2A35C;
}

.skukmart-toolbar .toolbar-right_side ul.product-selection > li.filter-selection a.filter-btn.active i:before {
  content: '\ea13';
}

/* ---Filter Body--- */
.filter-body {
  display: none;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.09);
  padding: 35px 40px;
  margin-bottom: 30px;
}

.filter-body .custom-col {
  flex: 0 0 20%;
  max-width: 20%;
}

@media (max-width: 991px) {
  .filter-body .custom-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  .filter-body .custom-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .filter-body .filter-widgets_area {
    padding-bottom: 25px;
  }
}

.filter-body .filter-widgets_area h2.heading {
  font-size: 16px;
  line-height: 19.2px;
  padding-bottom: 20px;
}

.filter-body .filter-widgets_area .widgets-item ul li:not(:last-child) {
  padding-bottom: 15px;
}

.filter-body .filter-widgets_area .widgets-item ul li a {
  color: #707070;
}

.filter-body .filter-widgets_area .widgets-item ul li:hover a {
  color: #D2A35C;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub a i {
  font-size: 14px;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub > ul.inner-body {
  display: none;
  padding-left: 15px;
  padding-top: 15px;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub > ul.inner-body li:not(:last-child) {
  padding-bottom: 5px;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub > ul.inner-body li:hover a {
  color: #D2A35C;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub > ul.inner-body li a {
  display: block;
  font-size: 14px;
  line-height: 25.2px;
  color: #999999;
}

.filter-body .filter-widgets_area .widgets-item ul li.has-sub.open i:before {
  content: '\ea68';
}

/* ---Shop Product Wrap--- */
.shop-product-wrap.grid .list-product_item {
  display: none;
}

.shop-product-wrap.grid .product-item {
  display: block;
  margin: 0;
}

.shop-product-wrap.grid .product-item .single-product {
  overflow: hidden;
}

.shop-product-wrap.grid .product-item .single-product .product-img img {
  width: 100%;
}

.shop-product-wrap.grid.gridview-2 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 575px) {
  .shop-product-wrap.grid.gridview-2 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop-product-wrap.grid.gridview-3 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.333333%;
}

@media (max-width: 1199px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 575px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop-product-wrap.grid.gridview-4 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 1199px) {
  .shop-product-wrap.grid.gridview-4 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.333333%;
  }
}

@media (max-width: 1199px) {
  .shop-product-wrap.grid.gridview-4 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 575px) {
  .shop-product-wrap.grid.gridview-4 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop-product-wrap.grid.gridview-5 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.shop-product-wrap.listview > [class*="col-"] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  width: 100%;
  max-width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item {
  display: block;
  padding-bottom: 40px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product {
  overflow: hidden;
  display: flex;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product {
    flex-direction: column;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img {
  position: relative;
  width: 415px;
}

@media (max-width: 575px) {
  .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img {
    width: 100%;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img:before {
  background-color: #1d1d1d;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img a img {
  width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img a img.secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li {
  display: inline-block;
  transform: translateY(0);
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li:first-child {
  transition-delay: 100ms;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li a {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  width: 40px;
  height: 40px;
  line-height: 42px;
  border-radius: 100%;
  display: block;
  text-align: center;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li a:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
  color: #ffffff;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-img .add-actions ul li a i {
  font-size: 16px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content {
  width: calc(100% - 415px);
  padding-left: 40px;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info {
    padding-top: 25px;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info h3.product-name {
  padding-bottom: 10px;
  font-size: unset;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info h3.product-name a {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 700;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info .price-box {
  padding-bottom: 15px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info .price-box span.new-price {
  color: #454545;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info p.short-desc {
  color: #454545;
  padding-bottom: 15px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .product-desc_info .rating-box {
  padding-bottom: 20px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li {
  display: inline-block;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li:not(:last-child) {
  margin-right: 15px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li a {
  height: 50px;
  line-height: 50px;
  display: block;
  font-size: 14px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li a i {
  vertical-align: middle;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li a:hover {
  color: #D2A35C;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li.add-to-cart a {
  background-color: #1d1d1d;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 20px;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product .product-content .add-actions-2 ul li.add-to-cart a:hover {
  background-color: #D2A35C;
  color: #ffffff;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product:hover .product-img:before {
  opacity: 0.5;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product:hover .product-img a img.secondary-img {
  opacity: 1;
}

.shop-product-wrap.listview > [class*="col-"] .list-product_item .single-product:hover .product-img .add-actions {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.shop-product-wrap.listview > [class*="col-"] .product-item {
  display: none;
}

/* ---Shop With Sidebar--- */
@media (max-width: 991px) {
  .shop-area.with-sidebar {
    padding-bottom: 85px;
  }
}

/* ---Shop Vendor--- */
@media (max-width: 991px) {
  .vendor-profile_area .single-content {
    padding-top: 20px;
  }
}

.vendor-profile_area .single-content h1.heading {
  padding-bottom: 10px;
}

.vendor-profile_area .single-content .vendor-widgets_area {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

@media (max-width: 575px) {
  .vendor-profile_area .single-content .vendor-widgets_area {
    flex-direction: column;
  }
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets > li {
  display: inline-block;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets > li:not(:last-child) {
  padding-right: 25px;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets > li i {
  vertical-align: text-top;
  margin-right: 5px;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets > li a {
  color: #707070;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets > li a:hover {
  color: #D2A35C;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets.customer-feedback > li {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 479px) {
  .vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets.customer-feedback > li {
    display: block;
  }
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets.customer-feedback > li > ul.rating-box {
  display: flex;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-leftside .vendor-widgets.customer-feedback > li > ul.rating-box li i {
  vertical-align: middle;
  color: #D2A35C;
  font-size: 12px;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-rightside .social-link li:not(:last-child) {
  padding-right: 15px;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-rightside .social-link li a {
  color: #707070;
}

.vendor-profile_area .single-content .vendor-widgets_area .widgets-rightside .social-link li a:hover {
  color: #D2A35C;
}

.vendor-profile_area .single-content .vendor-profile_menu {
  background-color: #f7f7f7;
}

.vendor-profile_area .single-content .vendor-profile_menu ul {
  display: flex;
}

@media (max-width: 767px) {
  .vendor-profile_area .single-content .vendor-profile_menu ul {
    flex-direction: column;
  }
}

.vendor-profile_area .single-content .vendor-profile_menu ul li {
  display: inline-block;
}

.vendor-profile_area .single-content .vendor-profile_menu ul li:not(:last-child) a {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .vendor-profile_area .single-content .vendor-profile_menu ul li:not(:last-child) a {
    border-right: 0;
  }
}

.vendor-profile_area .single-content .vendor-profile_menu ul li a {
  padding: 15px 25px;
  display: block;
}

.vendor-profile_area .single-content .vendor-profile_menu ul li a:hover {
  background-color: #D2A35C;
  color: #ffffff;
}

.vendor-profile_area .single-content .vendor-profile_menu ul li.vendor-search_box {
  position: relative;
}

.vendor-profile_area .single-content .vendor-profile_menu ul li.vendor-search_box form input.input-field {
  background-color: transparent;
  padding: 15px 50px 15px 20px;
  border: 1px solid #f7f7f7;
  border-left: 0;
}

.vendor-profile_area .single-content .vendor-profile_menu ul li.vendor-search_box form input.input-field:focus {
  border-color: rgba(0, 0, 0, 0.1);
}

.vendor-profile_area .single-content .vendor-profile_menu ul li.vendor-search_box form .search-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

/*----------------------------------------*/
/*  44 - Pages - Cart
/*----------------------------------------*/
@media (max-width: 767px) {
  .table-content table {
    text-align: center;
  }
}

.table-content table thead th {
  border-bottom: 1px solid #dee2e6;
}

@media (max-width: 767px) {
  .table-content table thead tr {
    display: none;
  }
}

.table-content table td.product-subtotal {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .table-content table td {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .table-content table td:nth-child(3) {
    padding: 32.1px 10px;
    display: block;
    width: 100%;
  }
}

.table-content table th,
.table-content table td {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .skudmart-cart-area .table-content table td:nth-child(3) {
    padding: 45.1px 10px;
  }
}

.coupon-all {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 479px) {
  .coupon-all {
    display: block;
  }
}

@media (max-width: 479px) {
  .coupon-all .coupon {
    margin-bottom: 30px;
  }
}

.coupon-all .coupon input {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  font-size: 13px;
  border-radius: 0;
  height: 42px;
  width: 120px;
  padding: 0 10px;
}

.coupon-all .coupon input.input-btn,
.coupon-all .coupon2 input.input-btn {
  background-color: #1d1d1d;
  border: 0 none;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  text-transform: uppercase;
  width: inherit;
}

@media (max-width: 479px) {
  .coupon-all .coupon input.input-btn,
  .coupon-all .coupon2 input.input-btn {
    padding: 0 19.5px;
  }
}

.coupon-all .coupon input.input-btn:hover,
.coupon-all .coupon2 input.input-btn:hover {
  background-color: #D2A35C;
}

.cart-page-total {
  padding-top: 30px;
}

.cart-page-total > h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #d8d8d8;
}

.cart-page-total > ul li {
  list-style: none;
  font-size: 15px;
  padding: 10px 30px;
  border-bottom: 1px solid #d8d8d8;
  font-weight: 700;
}

.cart-page-total > ul li:last-child {
  border-bottom: 0;
}

.cart-page-total > ul li span {
  float: right;
}

.cart-page-total a {
  background-color: #1d1d1d;
  border: 1px solid #1d1d1d;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  text-transform: capitalize;
}

.cart-page-total a:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
  color: #ffffff;
}

/*----------------------------------------*/
/*  45 - Pages - Order Tracking
/*----------------------------------------*/
.order-tracking_item p.short-desc {
  color: #1d1d1d;
}

.order-tracking_item form.order-tracking_form label.order-label {
  color: #1d1d1d;
  display: block;
}

.order-tracking_item form.order-tracking_form input.order-input {
  border: 1px solid #d8d8d8;
  color: #707070;
  width: 100%;
  height: 70px;
  padding: 0 20px;
}

.order-tracking_item form.order-tracking_form input.order-input:focus {
  border-color: #1d1d1d;
  color: #1d1d1d;
}

.order-tracking_item form.order-tracking_form input.order-input:focus::placeholder {
  opacity: 1;
}

/*----------------------------------------*/
/*  46 - Pages - My Account
/*----------------------------------------*/
.login-area li:not(:last-child) {
  padding-bottom: 30px;
}

.login-area li a {
  display: block;
  font-size: 20px;
  line-height: 24px;
}

.login-area li a i {
  vertical-align: text-top;
  padding-right: 10px;
}

.login-area li a.lost-pass {
  color: #D2A35C;
  font-size: 16px;
  line-height: 28.8px;
  display: block;
}

.login-area li a.lost-pass:hover {
  text-decoration: underline;
}

.login-area li ul.hassub-body {
  display: none;
}

.login-area li ul.hassub-body.show {
  display: block;
}

.login-area li ul.hassub-body li .login-form {
  padding-top: 20px;
}

.login-area li ul.hassub-body li .login-form label.input-label {
  display: block;
}

.login-area li ul.hassub-body li .login-form input.input-field {
  border: 1px solid #d8d8d8;
  padding: 0 25px;
  width: 100%;
  height: 50px;
}

.login-area li ul.hassub-body li .login-form input[type="checkbox"]:checked + label:after {
  color: #13aff0;
}

.login-area li ul.hassub-body li .login-form .vendor-body {
  display: none;
}

.login-area li ul.hassub-body li .login-form .vendor-btn.active input[type="checkbox"] + label::after {
  opacity: 1;
  color: #13aff0;
}

/*----------------------------------------*/
/*  47 - Pages - Wishlist
/*----------------------------------------*/
.table-content table {
  border-color: #d8d8d8;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .table-content table thead tr {
    display: none;
  }
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.table-content table th {
  border-top: medium none;
  font-size: 15px;
  text-transform: capitalize;
  padding: 20px 10px;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
}

.table-content table td {
  border-top: medium none;
  padding: 18px 10px;
  vertical-align: middle;
  font-size: 13px;
}

@media (max-width: 767px) {
  .table-content table td {
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  .table-content table td:nth-child(3) {
    padding: 32.1px 10px;
    display: block;
    width: 100%;
  }
}

.table-content table td.skudmart-product_remove {
  font-size: 20px;
}

.table-content table td.skudmart-product_remove a i {
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.table-content table td.skudmart-product-thumbnail img {
  width: auto;
  height: 100%;
}

.table-content table td.skudmart-product-name {
  font-size: 16px;
  text-transform: capitalize;
}

.table-content table td.skudmart-product-price {
  font-size: 16px;
}

.table-content table td.quantity .cart-plus-minus {
  margin: 0 auto;
}

.table-content table td.skudmart-product-stock-status span.in-stock, .table-content table td.skudmart-product-stock-status span.out-stock {
  font-size: 12px;
  text-transform: capitalize;
}

.table-content table td.skudmart-product-stock-status span.out-stock {
  color: #ff0000;
}

.table-content table td.skudmart-cart_btn a {
  font-size: 14px;
  text-transform: uppercase;
  background: #707070;
  color: #ffffff;
  height: 40px;
  line-height: 43px;
  width: 130px;
  display: inline-block;
}

.table-content table td.skudmart-cart_btn a:hover {
  background: #D2A35C;
  color: #ffffff;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #d8d8d8;
}

/*----------------------------------------*/
/*  48 - Pages - Compare
/*----------------------------------------*/
.compare-table .table thead th {
  border-width: 1px;
  padding: 15px 10px;
}

.compare-table .table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.compare-table .table tbody tr td,
.compare-table .table tbody tr th {
  vertical-align: middle;
  text-align: center;
}

.compare-table .table tbody tr th {
  min-width: 200px;
}

.compare-table .table tbody tr td {
  min-width: 250px;
}

.compare-table .table tbody tr td .compare-pdoduct-image {
  max-width: 200px;
  display: inline-block;
  padding: 20px 0;
}

.compare-table .table tbody tr td .compare-pdoduct-image a {
  display: inline-block;
}

.compare-table .table tbody tr td .compare-pdoduct-image a.skudmart-btn {
  display: block;
  margin-top: 30px;
}

.compare-table .table tbody tr td .compare-pdoduct-image a.skudmart-btn:hover {
  color: #ffffff;
}

.compare-table .table tbody tr td .compare-product-name {
  font-size: 14px;
  margin-bottom: 0;
}

.compare-table .table tbody tr td .compare-product-name a {
  transition-property: color;
}

.compare-table .table tbody tr td .rating-box ul li {
  display: inline-block;
}

.compare-table .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: transparent;
}

/*----------------------------------------*/
/*  49 - Pages - Product Detail
/*----------------------------------------*/
.product-detail_area .product-detail_img {
  overflow: hidden;
}

.product-detail_area .product-detail_img .single-img .inner-stuff {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.product-detail_area .product-detail_img .single-img .inner-stuff ul li:not(:last-child) {
  margin-bottom: 15px;
}

.product-detail_area .product-detail_img .single-img .inner-stuff ul li a {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 53px;
  border-radius: 100%;
  display: block;
  text-align: center;
}

.product-detail_area .product-detail_img .single-img .inner-stuff ul li a:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}

@media (min-width: 768px) {
  .product-detail_area .product-detail_img.vertical-slider_wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap .pd-vertical_slider {
    flex-basis: 90%;
    max-width: 90%;
    margin-left: 30px;
    cursor: crosshair;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap .vertical-slider_nav {
    flex-basis: 10%;
    max-width: 10%;
    height: 100%;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap .vertical-slider_nav .swiper-wrapper {
    height: 100%;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap .vertical-slider_nav .swiper-wrapper .swiper-slide {
    height: 100% !important;
    opacity: 0.5;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap .vertical-slider_nav .swiper-wrapper .swiper-slide.swiper-slide-thumb-active {
    opacity: 1;
  }
  .product-detail_area .product-detail_img.vertical-slider_wrap.vt-right_side {
    flex-direction: unset;
  }
}

@media (max-width: 767px) {
  .product-detail_area .product-detail_img.vertical-slider_wrap .vertical-slider_nav {
    padding-top: 30px;
  }
}

.product-detail_area .product-detail_img.horizontal-slider_wrap .gallery-thumbs {
  padding-top: 8px;
}

@media (max-width: 991px) {
  .product-detail_area .product-detail_content {
    padding-top: 25px;
  }
}

.product-detail_area .product-detail_content h1.heading {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 400;
}

.product-detail_area .product-detail_content .price-box {
  padding-bottom: 15px;
}

.product-detail_area .product-detail_content .price-box span.new-price {
  color: #1d1d1d;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
}

.product-detail_area .product-detail_content p.short-desc {
  padding-bottom: 25px;
}

.product-detail_area .product-detail_content .countdown-wrap {
  padding-bottom: 30px;
}

.product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item {
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.1);
  width: 70px;
  height: 70px;
  margin-left: 0;
  border-radius: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item[class*="countdown__item"] {
  margin-right: 20px;
}

@media (max-width: 1500px) {
  .product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item[class*="countdown__item"] {
    margin-right: 15px;
  }
}

@media (max-width: 479px) {
  .product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item {
    width: 50px;
    height: 50px;
  }
}

.product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item span {
  color: #1d1d1d;
  font-size: 16px;
  line-height: 1.2;
}

@media (max-width: 479px) {
  .product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item span {
    font-size: 14px;
  }
}

.product-detail_area .product-detail_content .countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
  padding-top: 0;
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 12px;
}

.product-detail_area .product-detail_content .quantity-with_btn {
  display: flex;
  padding-bottom: 20px;
}

.product-detail_area .product-detail_content .quantity-with_btn .add-to_cart {
  padding-left: 15px;
}

.product-detail_area .product-detail_content .product-with_quantity {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap {
  padding-left: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .inner-content .product-name a {
  color: #707070;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .inner-content .product-name a:hover {
  color: #D2A35C;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .inner-content .price-box span {
  font-size: 14px;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .inner-content .price-box span.new-price {
  color: inherit;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .inner-content .price-box span.old-price {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  color: #999999;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .addition-content .product-stock span {
  font-size: 12px;
}

.product-detail_area .product-detail_content .product-with_quantity .product-info_wrap .addition-content .product-stock span:before {
  content: '\ea20';
  font-family: LaStudioIcons;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid;
  font-size: 8px;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  color: #86BA45;
  margin-left: 5px;
  margin-right: 5px;
}

.product-detail_area .product-detail_content .addition-group_btn {
  padding-bottom: 25px;
}

.product-detail_area .product-detail_content .addition-group_btn li {
  display: inline-block;
}

.product-detail_area .product-detail_content .addition-group_btn li:not(:last-child) {
  padding-right: 15px;
}

.product-detail_area .product-detail_content .addition-group_btn li a i {
  font-size: 20px;
}

.product-detail_area .product-detail_content .additional-stuff > li {
  border-top: 1px solid #d8d8d8;
  padding-top: 20px;
}

.product-detail_area .product-detail_content .additional-stuff > li:not(:last-child) {
  padding-bottom: 20px;
}

.product-detail_area .product-detail_content .additional-stuff > li.product-meta > ul li {
  font-size: 14px;
  line-height: 25.2px;
}

.product-detail_area .product-detail_content .additional-stuff > li.product-meta > ul li a {
  color: #D2A35C;
}

.product-detail_area .product-detail_content .additional-stuff > li.product-meta > ul li a:hover {
  text-decoration: underline;
}

.product-detail_area .product-detail_content .additional-stuff > li.store-info > ul {
  display: flex;
  flex-wrap: wrap;
}

.product-detail_area .product-detail_content .additional-stuff > li.store-info > ul li:not(:last-child) {
  padding-right: 25px;
}

.product-detail_area .product-detail_content .additional-stuff > li.store-info > ul li a {
  color: inherit;
  font-size: 14px;
  line-height: 25.2px;
}

.product-detail_area .product-detail_content .additional-stuff > li.store-info > ul li a:hover {
  color: #D2A35C;
}

.product-detail_area .product-detail_content .additional-stuff > li.store-info > ul li a i {
  vertical-align: text-top;
}

.product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap {
  display: flex;
  align-items: center;
}

@media (max-width: 479px) {
  .product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap {
    flex-direction: column;
  }
}

.product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap span {
  text-transform: uppercase;
  color: #1d1d1d;
  font-size: 11px;
  line-height: 19.8px;
  font-weight: 600;
  padding-right: 20px;
}

@media (max-width: 479px) {
  .product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap span {
    padding-bottom: 15px;
    padding-right: 0;
  }
}

.product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap > ul li:not(:last-child) {
  padding-right: 15px;
}

.product-detail_area .product-detail_content .additional-stuff > li.social-link_wrap > ul li a {
  border: 1px solid #d8d8d8;
  color: inherit;
  border-radius: 100%;
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

.product-detail_area .addition-info .single-img .inner-content {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 1;
  pointer-events: none;
}

.product-detail_area .addition-info .single-img .inner-content span {
  text-transform: uppercase;
}

.product-detail_area .addition-info .single-img .inner-content span.category {
  font-size: 20px;
  line-height: 1.2;
}

@media (max-width: 1500px) {
  .product-detail_area .addition-info .single-img .inner-content span.category {
    font-size: 16px;
  }
}

.product-detail_area .addition-info .single-img .inner-content span.offer {
  font-size: 60px;
  line-height: 54px;
}

@media (max-width: 1500px) {
  .product-detail_area .addition-info .single-img .inner-content span.offer {
    font-size: 40px;
    line-height: 1.2;
  }
}

.product-detail_area .addition-info .pd-newsletter {
  background-color: #e5f3ef;
  text-align: center;
  margin-top: 20px;
  padding: 15px;
}

.product-detail_area .addition-info .pd-newsletter i {
  color: #1d1d1d;
  font-size: 30px;
}

.product-detail_area .addition-info .pd-newsletter h5,
.product-detail_area .addition-info .pd-newsletter p {
  font-family: 'Playfair Display', serif;
}

.product-detail_area .addition-info .pd-newsletter h5.heading {
  padding-bottom: 5px;
  font-size: 22px;
  line-height: 1.2;
}

.product-detail_area .addition-info .pd-newsletter p.short-desc {
  font-size: 10px;
  line-height: 15px;
}

.product-detail_area .addition-info .pd-newsletter .subscribe-form_wrap {
  display: flex;
  justify-content: center;
}

.product-detail_area .addition-info .pd-newsletter .subscribe-form_wrap form input.input-field {
  font-family: 'Playfair Display', serif;
  background-color: transparent;
  width: 100%;
  font-size: 11px;
  border: 0;
  padding-bottom: 10px;
  text-align: center;
}

/* ---Product Demo Four--- */
.product-demo-04 .product-detail_img .product-demo_wrap {
  --bs-gutter-x: 10px;
}

.product-demo-04 .product-detail_img .product-demo_wrap .single-img {
  margin-top: 10px;
}

.product-demo-04 .product-detail_img .product-demo_wrap .custom-col-01 {
  flex: 0 0 100%;
  max-width: 100%;
}

.product-demo-04 .product-detail_img .product-demo_wrap .custom-col-01 .single-img img {
  width: 100%;
  height: 330px;
  object-fit: cover;
  object-position: center;
}

.product-demo-04 .product-detail_img .product-demo_wrap .custom-col-02 {
  flex: 0 0 50%;
  max-width: 50%;
}

/* ---Variable Product--- */
.variable-product_area .product-detail_content .selector-wrap {
  display: flex;
  align-items: center;
}

.variable-product_area .product-detail_content .selector-wrap span {
  width: 150px;
}

/*----------------------------------------*/
/*  50 - Pages - Elements
/*----------------------------------------*/
/* ---Typography--- */
.typography-section .heading {
  line-height: 1.4;
}

.typography-section h1.heading {
  max-width: 54%;
}

@media (max-width: 767px) {
  .typography-section h1.heading {
    max-width: 100%;
  }
}

.typography-section h2.heading {
  max-width: 42%;
}

@media (max-width: 767px) {
  .typography-section h2.heading {
    max-width: 100%;
  }
}

.typography-section h3.heading {
  max-width: 38%;
}

@media (max-width: 767px) {
  .typography-section h3.heading {
    max-width: 100%;
  }
}

.typography-section h4.heading {
  max-width: 32%;
}

@media (max-width: 767px) {
  .typography-section h4.heading {
    max-width: 100%;
  }
}

.typography-section h5.heading {
  max-width: 27%;
}

@media (max-width: 767px) {
  .typography-section h5.heading {
    max-width: 100%;
  }
}

.typography-section h6.heading {
  max-width: 21%;
}

@media (max-width: 767px) {
  .typography-section h6.heading {
    max-width: 100%;
  }
}

.typography-section p .custom {
  color: #1d1d1d;
}

.typography-section p del {
  color: #aaa;
}

/* List Styles */
.list-styles_area .list-style_wrap .elements-heading p.short-desc {
  max-width: 35%;
}

@media (max-width: 767px) {
  .list-styles_area .list-style_wrap .elements-heading p.short-desc {
    max-width: 100%;
  }
}

.list-styles_area .list-style_wrap ul.list-item {
  display: flex;
}

@media (max-width: 767px) {
  .list-styles_area .list-style_wrap ul.list-item {
    flex-wrap: wrap;
  }
}

.list-styles_area .list-style_wrap ul.list-item > li {
  width: 25%;
}

@media (max-width: 767px) {
  .list-styles_area .list-style_wrap ul.list-item > li {
    width: 100%;
  }
}

.list-styles_area .list-style_wrap ul.list-item > li:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 767px) {
  .list-styles_area .list-style_wrap ul.list-item > li:not(:last-child) {
    margin-right: 0;
  }
  .list-styles_area .list-style_wrap ul.list-item > li:not(:last-child) .sub-list_item {
    padding-bottom: 30px;
  }
}

.list-styles_area .list-style_wrap ul.list-item > li > ul.sub-list_item li:not(:last-child) {
  padding-bottom: 15px;
}

.list-styles_area .list-style_wrap ul.list-item > li > ul.sub-list_item li i {
  color: #1d1d1d;
  margin-right: 5px;
}

.list-styles_area.with-divider .list-style_wrap ul.list-item > li > ul.sub-list_item li:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.list-styles_area.with-3-column .list-style_wrap ul.list-item > li {
  width: 33.333333%;
}

@media (max-width: 767px) {
  .list-styles_area.with-3-column .list-style_wrap ul.list-item > li {
    width: 100%;
  }
}

.list-styles_area.with-3-column .list-style_wrap ul.list-item > li > ul.sub-list_item li:not(:last-child) {
  padding-bottom: 30px;
}

.list-styles_area.with-3-column .list-style_wrap ul.list-item > li > ul.sub-list_item li a {
  color: #707070;
}

.list-styles_area.with-3-column .list-style_wrap.list-style_wrap ul.list-item > li.hover-style > ul.sub-list_item li a:hover {
  color: #4241FF;
}

.list-styles_area.with-3-column .list-style_wrap.list-style_wrap ul.list-item > li.hover-style-2 > ul.sub-list_item li:hover i {
  color: #4241FF;
}

.list-styles_area.with-3-column .list-style_wrap.list-style_wrap ul.list-item > li.hover-style-3 > ul.sub-list_item li:hover a,
.list-styles_area.with-3-column .list-style_wrap.list-style_wrap ul.list-item > li.hover-style-3 > ul.sub-list_item li:hover i {
  color: #4241FF;
}

.list-styles_area.with-3-column .list-style_wrap.list-style_wrap ul.list-item > li.specific-font_size {
  font-size: 18px;
}

/* ---Elements Button--- */
.element-button_wrap .elements-heading p.short-desc {
  max-width: 44%;
}

@media (max-width: 767px) {
  .element-button_wrap .elements-heading p.short-desc {
    max-width: 100%;
  }
}

.element-button_wrap ul.button-list {
  display: flex;
}

@media (max-width: 767px) {
  .element-button_wrap ul.button-list {
    flex-wrap: wrap;
  }
}

.element-button_wrap ul.button-list > li {
  width: 25%;
}

@media (max-width: 767px) {
  .element-button_wrap ul.button-list > li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .element-button_wrap ul.button-list > li:not(:last-child) {
    padding-bottom: 30px;
  }
}

.element-button_wrap ul.button-list > li > ul.sub-list_button li:not(:last-child) {
  padding-bottom: 15px;
}

/* ---Icon Box--- */
.iconbox-item {
  text-align: center;
}

@media (max-width: 991px) {
  .iconbox-item {
    padding-bottom: 25px;
  }
}

.iconbox-item .single-icon i {
  color: #fe4a49;
  font-size: 60px;
}

.iconbox-item .single-content h2.heading {
  font-size: 18px;
  line-height: 21.6px;
}

.iconbox-item .single-content span.counter-title {
  color: #1d1d1d;
  font-size: 16px;
  line-height: 32px;
}

.iconbox-item .single-content a.readmore {
  color: #1d1d1d;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 500;
}

.iconbox-item.single-icon_bg .single-icon {
  display: flex;
  justify-content: center;
}

.iconbox-item.single-icon_bg .single-icon .inner-icon {
  background-color: #02deea;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.iconbox-item.single-icon_bg .single-icon i {
  vertical-align: middle;
  color: #ffffff;
  font-size: 36px;
}

.iconbox-item.single-icon_bg .single-content p.short-desc {
  font-size: 12px;
  line-height: 24px;
}

.iconbox-item.single-icon_bg.radical-red_color .single-icon .inner-icon {
  background-color: #F53E6A;
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.iconbox-style-02 .iconbox-item {
  display: flex;
  text-align: left;
}

.iconbox-style-02 .iconbox-item .single-icon i {
  color: #2f21b3;
  font-size: 60px;
}

.iconbox-style-02 .iconbox-item .single-content {
  padding-left: 50px;
}

.iconbox-style-03 .iconbox-item {
  margin-bottom: 30px;
}

.iconbox-style-03 .iconbox-item .inner-iconbox_item {
  padding-top: 50px;
  padding-left: 35px;
  padding-right: 35px;
}

.iconbox-style-03 .iconbox-item.single-icon_bg .single-icon .inner-icon {
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.iconbox-style-03 .iconbox-item.valhalla-color .single-icon .inner-icon {
  background-color: #2d2d48;
  color: rgba(255, 255, 255, 0.8);
}

.iconbox-style-03 .iconbox-item.valhalla-color .single-content h2,
.iconbox-style-03 .iconbox-item.valhalla-color .single-content p {
  color: rgba(255, 255, 255, 0.8);
}

.iconbox-style-03 .iconbox-item .single-content h2.heading {
  font-size: 24px;
  line-height: 28.8px;
}

.iconbox-style-03 .iconbox-item .single-content p.short-desc {
  font-size: 14px;
}

.iconbox-style-03 .iconbox-item .button-wrap a.skudmart-btn {
  text-transform: uppercase;
  height: 90px;
  line-height: 90px;
}

.iconbox-style-03 .iconbox-item:hover.single-icon_bg .single-icon .inner-icon {
  background-color: #F53E6A;
}

.iconbox-style-03 .iconbox-item:hover.single-icon_bg .button-wrap a.skudmart-btn {
  background-color: #F53E6A;
  color: #ffffff;
}

.iconbox-style-04 .iconbox-item {
  margin-bottom: 30px;
}

.iconbox-style-04 .iconbox-item .inner-iconbox_item {
  padding-top: 50px;
  padding-left: 35px;
  padding-right: 35px;
}

.iconbox-style-04 .iconbox-item .single-icon {
  display: flex;
  justify-content: center;
}

.iconbox-style-04 .iconbox-item .single-icon .inner-icon {
  box-shadow: 0 0 55px 0 #dadada;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.iconbox-style-04 .iconbox-item .single-icon .inner-icon i {
  vertical-align: middle;
  font-size: 30px;
}

.iconbox-style-04 .iconbox-item .single-content h2.heading {
  font-size: 24px;
  line-height: 28.8px;
}

.iconbox-style-04 .iconbox-item .single-content p.short-desc {
  font-size: 14px;
}

.our-work-process .work-process-item {
  display: flex;
}

@media (max-width: 991px) {
  .our-work-process .work-process-item {
    flex-direction: column;
  }
}

.our-work-process .work-process-item .work-process-img {
  width: 50%;
}

@media (max-width: 991px) {
  .our-work-process .work-process-item .work-process-img {
    width: 100%;
  }
}

.our-work-process .work-process-item .work-process-content {
  padding-top: 45px;
  padding-left: 50px;
  padding-right: 50px;
  width: 50%;
}

@media (max-width: 991px) {
  .our-work-process .work-process-item .work-process-content {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .our-work-process .work-process-item .work-process-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.our-work-process .work-process-item .work-process-content h2.heading {
  font-size: 32px;
  line-height: 32px;
}

.our-work-process .work-process-item .work-process-content .iconbox-item {
  display: flex;
}

@media (max-width: 575px) {
  .our-work-process .work-process-item .work-process-content .iconbox-item {
    flex-direction: column;
    align-items: center;
  }
}

.our-work-process .work-process-item .work-process-content .iconbox-item .single-icon .inner-icon {
  background-color: #F53E6A;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

@media (max-width: 575px) {
  .our-work-process .work-process-item .work-process-content .iconbox-item .single-icon .inner-icon {
    margin-bottom: 25px;
  }
}

.our-work-process .work-process-item .work-process-content .iconbox-item .single-icon .inner-icon i {
  vertical-align: middle;
  color: #ffffff;
  font-size: 30px;
}

.our-work-process .work-process-item .work-process-content .iconbox-item .single-content {
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 575px) {
  .our-work-process .work-process-item .work-process-content .iconbox-item .single-content {
    padding-left: 0;
    text-align: center;
  }
}

.our-work-process .work-process-item .work-process-content .iconbox-item .single-content h3.heading {
  font-size: 20px;
  line-height: 24px;
}

/* ---Pricing Tables--- */
.pricing-table_item {
  border: 1px solid #d8d8d8;
  text-align: center;
  padding: 45px 50px;
  position: relative;
}

@media (max-width: 991px) {
  .pricing-table_item {
    margin-bottom: 30px;
  }
}

.pricing-table_item .lable {
  position: absolute;
  top: 0;
  right: 0;
}

.pricing-table_item .single-icon {
  padding-bottom: 5px;
}

.pricing-table_item .single-icon i {
  font-size: 24px;
}

.pricing-table_item h3.pricing-table_title {
  font-size: 18px;
  line-height: 21.6px;
}

.pricing-table_item h3.pricing-table_title.persian-blue_color {
  color: #2f21b3;
}

.pricing-table_item .price-box.with-border {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
}

.pricing-table_item .price-box span {
  color: #1d1d1d;
  font-size: 32px;
  line-height: 57.6px;
  font-weight: 700;
}

.pricing-table_item .price-box span em {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  vertical-align: middle;
}

.pricing-table_item ul.pricing-feature {
  padding-top: 35px;
}

.pricing-table_item ul.pricing-feature li {
  color: #1d1d1d;
}

.pricing-table_item ul.pricing-feature li:not(:last-child) {
  padding-bottom: 10px;
}

.pricing-table_item ul.pricing-feature li.with-skicker {
  font-weight: 700;
  position: relative;
}

.pricing-table_item ul.pricing-feature li.with-skicker:after {
  content: 'hot';
  background-color: #EA622B;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -15px;
}

.pricing-table_item ul.pricing-feature li.with-skicker-2 {
  font-weight: 700;
  position: relative;
}

.pricing-table_item ul.pricing-feature li.with-skicker-2:after {
  content: 'new';
  background-color: green;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -15px;
}

.pricing-table_area.style-two h3.pricing-table_title {
  font-size: 18px;
  line-height: 21.6px;
}

.pricing-table_area.style-two h3.pricing-table_title.persian-blue_color {
  color: #2f21b3;
}

.pricing-table_area.style-two .pricing-table_item {
  text-align: left;
}

.pricing-table_area.style-two .pricing-table_item .price-box span {
  font-size: 66px;
  line-height: 66px;
}

.pricing-table_area.style-two .pricing-table_item .price-box span.persian-blue_color {
  color: #2f21b3;
}

.pricing-table_area.style-two .pricing-table_item .price-box span.persian-blue_color sup.price-prefix {
  font-size: 16px;
  color: #707070;
  vertical-align: super;
}

.pricing-table_area.style-two .pricing-table_item .price-box span.persian-blue_color em {
  color: #707070;
}

.pricing-table_area.style-two .pricing-table_item ul.pricing-feature li {
  color: #707070;
}

.pricing-table_area.style-two .pricing-table_item ul.pricing-feature li i {
  color: #2f21b3;
  margin-right: 5px;
}

.pricing-table_area.style-three .pricing-table_item h3.pricing-table_title {
  font-size: 24px;
  line-height: 28.8px;
}

.pricing-table_area.style-three .pricing-table_item h3.pricing-table_title.persian-blue_color {
  color: #2f21b3;
}

.pricing-table_area.style-three .pricing-table_item .price-box span {
  font-size: 46px;
  line-height: 46px;
}

.pricing-table_area.style-three .pricing-table_item .price-box span.persian-blue_color {
  color: #2f21b3;
}

.pricing-table_area.style-three .pricing-table_item .price-box span.persian-blue_color sup.price-prefix {
  font-size: 16px;
  color: #2f21b3;
  vertical-align: super;
}

.pricing-table_area.style-three .pricing-table_item .price-box span.persian-blue_color em {
  color: #707070;
}

.pricing-table_area.style-three .pricing-table_item .button-wrap a.skudmart-btn {
  border-radius: 35px 35px 35px 35px;
}

/* ---Team Member--- */
@media (max-width: 991px) {
  .team-member_item {
    padding-bottom: 30px;
  }
}

.team-member_item .single-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.team-member_item .single-content h3.member-name {
  padding-top: 10px;
}

.team-member_item .single-content h3.member-name a {
  font-size: 20px;
  line-height: 24px;
}

.team-member_item .single-content span.occupation {
  color: #1d1d1d;
  padding-top: 5px;
  display: block;
  font-size: 12px;
  line-height: 12px;
}

.team-member_item.hover-style .single-img {
  position: relative;
}

.team-member_item.hover-style .single-img:before {
  background-color: #D2A35C;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.team-member_item.hover-style .single-img .add-action {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
}

.team-member_item.hover-style .single-img .add-action ul.social-link li:not(:last-child) {
  padding-right: 10px;
}

.team-member_item.hover-style .single-img .add-action ul.social-link li a {
  border: 1px solid #d8d8d8;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.team-member_item.hover-style .single-img .add-action ul.social-link li a:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
}

.team-member_item.hover-style:hover .single-img:before {
  opacity: .9;
}

.team-member_item.hover-style:hover .single-img .add-action {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.team-member_item.hover-style-2 .single-img {
  position: relative;
  overflow: hidden;
}

.team-member_item.hover-style-2 .single-img img {
  filter: grayscale(100);
}

.team-member_item.hover-style-2 .single-img .add-action {
  position: absolute;
  bottom: 0;
  left: -80px;
  opacity: 0;
  visibility: hidden;
}

.team-member_item.hover-style-2:hover .single-img img {
  filter: grayscale(0);
}

.team-member_item.hover-style-2:hover .single-img .add-action {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.team-member_item.hover-style-3 .single-img {
  position: relative;
}

.team-member_item.hover-style-3 .single-img img {
  filter: grayscale(100);
}

.team-member_item.hover-style-3 .single-img:before {
  background-color: rgba(33, 33, 33, 0.5);
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 1;
}

.team-member_item.hover-style-3 .single-img .add-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li {
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li:nth-child(1) {
  transition-delay: 100ms;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li:nth-child(2) {
  transition-delay: 150ms;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li:nth-child(3) {
  transition-delay: 200ms;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li.member-name a {
  font-size: 20px;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li.occupation span {
  font-size: 12px;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li.social-link_wrap > ul.social-link li:not(:last-child) {
  padding-right: 10px;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li.social-link_wrap > ul.social-link li a {
  border: 1px solid #d8d8d8;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.team-member_item.hover-style-3 .single-img .add-action ul > li.social-link_wrap > ul.social-link li a:hover {
  background-color: #D2A35C;
  border-color: #D2A35C;
}

.team-member_item.hover-style-3:hover .single-img img {
  filter: grayscale(0);
}

.team-member_item.hover-style-3:hover .single-img:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
}

.team-member_item.hover-style-3:hover .single-img .add-action {
  opacity: 1;
  visibility: visible;
}

.team-member_item.hover-style-3:hover .single-img .add-action ul li {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.team-member_item.hover-style-4 .single-img .add-action {
  top: auto;
  transform: translateX(-50%);
  bottom: 0;
}

.team-member_item.hover-style-4:hover .single-img .add-action {
  bottom: 30px;
}

/* ---Typed Heading--- */
.typed-heading h2.heading {
  font-size: 28px;
  line-height: 1.2;
}

.typed-heading h5.cd-headline {
  font-size: 16px;
  line-height: 28.8px;
  font-weight: 400;
}

.typed-heading h5.cd-headline span b.is-visible {
  color: #F53E6A;
  font-style: normal;
}

/* ---Toggle--- */
.toggle-area .elements-heading p.different-width {
  max-width: 40%;
}

@media (max-width: 767px) {
  .toggle-area .elements-heading p.different-width {
    max-width: 100%;
  }
}

.toggle-item > ul > li {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.toggle-item > ul > li a {
  color: #1d1d1d;
  padding-left: 10px;
  padding-right: 10px;
}

.toggle-item > ul > li a:hover {
  color: #D2A35C;
}

.toggle-item > ul > li > ul.toggle-body {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
}

.toggle-item > ul > li.open i.arrows-1_small-triangle-right:before {
  content: "\eac5";
}

.toggle-item > ul > li.open i.arrows-1_square-right:before {
  content: "\eac6";
}

.toggle-item.style-two > ul > li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ---Google Map--- */
.google-map_area h2.heading {
  font-size: 20px;
  line-height: 20px;
}

.google-map_area .map-size {
  width: 100%;
  height: 300px;
}

/* ---Counter--- */
@media (max-width: 991px) {
  .counter-item {
    padding-bottom: 25px;
  }
}

.counter-item .count-wrap.style-two {
  display: flex;
  justify-content: center;
}

.counter-item .count-wrap.style-two > span,
.counter-item .count-wrap.style-two h3 {
  color: #1d1d1d;
  font-size: 35px;
  line-height: 35px;
}

/* ---Progress Bar--- */
.progress-charts {
  overflow: hidden;
}

.progress-charts span.skill-name {
  font-size: 14px;
  line-height: 24.3px;
  display: block;
}

.progress-charts .progress {
  height: 5px;
  border-radius: 5px;
  overflow: visible;
  margin: 20px 0;
}

.progress-charts .progress.height-4 {
  height: 4px;
}

.progress-charts .progress.height-8 {
  height: 8px;
}

.progress-charts .progress.height-10 {
  height: 10px;
}

.progress-charts .progress .progress-bar {
  position: relative;
  overflow: visible;
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  right: 0;
  top: -20px;
  color: #333;
}

/*----------------------------------------*/
/*  51 - Pages - Blog
/*----------------------------------------*/
.blog-item .single-img {
  position: relative;
}

.blog-item .single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-item .single-img .blog-meta {
  background-color: #ffffff;
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-item .single-img .blog-meta span {
  font-size: 12px;
  text-transform: uppercase;
  color: #1d1d1d;
  font-weight: 500;
}

.blog-item .single-img .blog-meta span.date {
  display: block;
}

.blog-item .single-img .add-action {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.blog-item .single-img .add-action .post-category {
  background-color: #1d1d1d;
  padding: 15px 45px;
}

.blog-item .single-img .add-action .post-category a {
  color: #ffffff;
  font-size: 11px;
  line-height: 19.8px;
  text-transform: uppercase;
  font-weight: 500;
}

.blog-item .single-img .add-action .post-category a:hover {
  text-decoration: underline;
  color: #D2A35C;
}

.blog-item .single-content.with-border {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog-item .single-content .post-category a {
  font-size: 11px;
  line-height: 19.8px;
  text-transform: uppercase;
}

.blog-item .single-content .post-category.with-bg {
  background-color: #7aedc9;
  display: inline-block;
  padding: 5px;
  margin-top: 15px;
}

.blog-item .single-content .post-category.with-bg a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.blog-item .single-content h3.heading a {
  font-size: 24px;
  line-height: 36px;
}

.blog-item .single-content .post-meta span,
.blog-item .single-content .post-meta a {
  font-size: 12px;
  line-height: 20.8px;
}

.blog-item .single-content .post-meta span.author a:hover {
  text-decoration: underline;
}

.blog-item .single-content p.short-desc {
  color: #1d1d1d;
  font-size: 14px;
  line-height: 25.2px;
}

.blog-item .single-content.different-font-size h3.heading a {
  font-size: 32px;
  line-height: 48px;
}

@media (max-width: 767px) {
  .blog-item .single-content.different-font-size h3.heading a {
    font-size: 25px;
    line-height: 1.2;
  }
}

.blog-item .single-content.different-font-size p.short-desc {
  font-size: 16px;
  line-height: 28.8px;
}

.blog-item.list-view {
  display: flex;
}

@media (max-width: 767px) {
  .blog-item.list-view {
    flex-direction: column;
  }
}

.blog-item.list-view .single-img {
  width: 100%;
}

.blog-item.list-view .single-content {
  padding-left: 30px;
}

@media (max-width: 767px) {
  .blog-item.list-view .single-content {
    padding-top: 20px;
    padding-left: 0;
  }
}

/* ---Without Sidebar--- */
.blog-area.without-sidebar .blog-item .single-img .add-action {
  left: 8%;
}

@media (max-width: 767px) {
  .blog-area.without-sidebar .blog-item .single-img .add-action {
    left: 0;
  }
}

.blog-area.without-sidebar .blog-item .single-content {
  padding-left: 8%;
  padding-right: 8%;
}

@media (max-width: 767px) {
  .blog-area.without-sidebar .blog-item .single-content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .blog-area.without-sidebar .blog-item .single-content h3.heading a {
    font-size: 25px;
    line-height: 1.2;
  }
}

/* ---Single Post--- */
.single-post_area .blog-item .single-content .post-category {
  background-color: #1d1d1d;
  display: inline-block;
  padding: 7.5px 15px;
}

.single-post_area .blog-item .single-content .post-category a {
  color: #ffffff;
  font-size: 11px;
  line-height: 19.8px;
  text-transform: uppercase;
  font-weight: 500;
}

.single-post_area .blog-item .single-content .post-category a:hover {
  text-decoration: underline;
  color: #D2A35C;
}

.single-post_area .blog-item .single-content p.short-desc {
  font-size: 14px;
  line-height: 28px;
}

.single-post_area .blog-item .img-with_content .single-img {
  float: left;
  margin-right: 30px;
  width: 50%;
}

@media (max-width: 767px) {
  .single-post_area .blog-item .img-with_content .single-img {
    width: 100%;
    padding-bottom: 25px;
  }
}

.single-post_area .blog-item .img-with_content .single-content {
  width: 100%;
}

.single-post_area .blog-feedback_area {
  padding-top: 55px;
}

.single-post_area .blog-feedback_area h2.heading {
  font-size: 24px;
  line-height: 33.6px;
  padding-bottom: 25px;
}

.single-post_area .blog-feedback_area li:not(:last-child) {
  margin-bottom: 30px;
}

.single-post_area .blog-feedback_area li.user-body {
  display: flex;
}

@media (max-width: 479px) {
  .single-post_area .blog-feedback_area li.user-body {
    display: block;
    text-align: center;
  }
}

.single-post_area .blog-feedback_area li.user-body.sub-user_body {
  margin-left: 25px;
}

.single-post_area .blog-feedback_area li.user-body .user-pic {
  width: 75px;
}

@media (max-width: 479px) {
  .single-post_area .blog-feedback_area li.user-body .user-pic {
    padding-bottom: 30px;
    width: 100%;
  }
}

.single-post_area .blog-feedback_area li.user-body .user-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  border: 1px solid #d8d8d8;
  width: calc(100% - 75px);
  margin-left: 25px;
  padding: 30px;
}

@media (max-width: 479px) {
  .single-post_area .blog-feedback_area li.user-body .user-content {
    width: 100%;
    margin-left: 0;
    padding: 15px;
  }
}

.single-post_area .blog-feedback_area li.user-body .user-content h3 {
  display: flex;
  padding-bottom: 20px;
}

.single-post_area .blog-feedback_area li.user-body .user-content h3.user-name a {
  font-size: 16px;
  line-height: 19.2px;
}

.single-post_area .blog-feedback_area li.user-body .user-content h3 .user-meta {
  color: #999999;
  padding-left: 5px;
  font-size: 12px;
  line-height: 21px;
}

.single-post_area .blog-feedback_area li.user-body .user-content p.user-feedback {
  border-bottom: 1px solid #d8d8d8;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 25.2px;
  padding-bottom: 20px;
}

.single-post_area .blog-feedback_area li.user-body .user-content .reply-btn_wrap {
  padding-top: 10px;
}

.single-post_area .blog-feedback_area li.user-body .user-content .reply-btn_wrap a.reply-btn {
  color: #454545;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  display: inline-block;
}

.single-post_area .blog-feedback_area li.user-body .user-content .reply-btn_wrap a.reply-btn > span {
  padding-right: 5px;
  vertical-align: middle;
}

.single-post_area .blog-feedback_area li.user-body .user-content .reply-btn_wrap a:hover {
  color: #D2A35C;
}

.single-post_area .blog-comment_area {
  padding-top: 50px;
}

.single-post_area .blog-comment_area h2.heading {
  padding-bottom: 35px;
}

.single-post_area .blog-comment_area .comment-field textarea {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 150px;
  padding: 15px;
}

.single-post_area .blog-comment_area .group-input {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

@media (max-width: 1199px) {
  .single-post_area .blog-comment_area .group-input {
    display: block;
  }
}

.single-post_area .blog-comment_area .group-input input.input-field {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 60px;
  padding: 0 10px;
}

@media (max-width: 1199px) {
  .single-post_area .blog-comment_area .group-input input.input-field {
    margin-bottom: 25px;
    margin-right: 0;
  }
}

.single-post_area .blog-comment_area .group-input input.input-field:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 1199px) {
  .single-post_area .blog-comment_area .group-input input.input-field:not(:last-child) {
    margin-right: 0;
  }
}

.single-post_area .blog-comment_area .field-checkbox {
  padding-top: 15px;
}

.single-post_area .blog-comment_area .field-checkbox input.input-checkbox {
  margin-right: 15px;
  vertical-align: middle;
}

.single-post_area .blog-comment_area .field-checkbox input[type="checkbox"]:checked + label:after {
  color: #13aff0;
}

.single-post_area .blog-comment_area .field-checkbox label.label-checkbox {
  color: #454545;
  font-size: 14px;
}

.single-post_area .blog-comment_area .comment-btn_wrap {
  padding-top: 15px;
}

.single-post_area .blog-comment_area .comment-btn_wrap a.skudmart-btn {
  font-size: 14px;
}

/*----------------------------------------*/
/*  53 - Pages - Checkout
/*----------------------------------------*/
.checkout-area input[type="checkbox"]:checked + label:after {
  color: #13aff0;
  opacity: 1;
}

.coupon-accordion h3 {
  background-color: #f7f7f7;
  font-size: 14px;
  font-weight: 400;
  list-style: outside none none;
  margin: 0 0 30px;
  padding: 16px 32px 16px 56px;
  position: relative;
  width: auto;
  text-transform: none;
}

@media (max-width: 479px) {
  .coupon-accordion h3 {
    line-height: 24px;
  }
}

.coupon-accordion h3:before {
  content: "\ea1b";
  display: inline-block;
  font-family: 'LaStudioIcons';
  left: 24px;
  position: absolute;
  top: 16px;
}

.coupon-accordion span {
  cursor: pointer;
  text-transform: none;
}

.coupon-accordion .coupon-content {
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
  display: none;
  padding: 20px;
}

.coupon-accordion .coupon-content .coupon-info p {
  margin-bottom: 0;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label, .coupon-accordion .coupon-content .coupon-info p.form-row-last label {
  display: block;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label span.required, .coupon-accordion .coupon-content .coupon-info p.form-row-last label span.required {
  color: #ff0000;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first input, .coupon-accordion .coupon-content .coupon-info p.form-row-last input {
  border: 1px solid #d8d8d8;
  height: 36px;
  margin: 0 0 14px;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
  background-color: transparent;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"] {
  background: #707070 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="checkbox"] {
  height: inherit;
  position: relative;
  top: 2px;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row label {
  margin-top: 7px;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password {
  margin-top: 15px;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password a {
  font-size: 14px;
}

.coupon-checkout-content {
  margin-bottom: 30px;
  display: none;
}

.coupon-checkout-content .coupon-info .checkout-coupon {
  margin-bottom: 0;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="text"] {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  width: 170px;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"] {
  background: #707070;
  border: medium none;
  border-radius: 0;
  color: #ffffff;
  height: 36px;
  cursor: pointer;
  margin-left: 6px;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: inherit;
}

@media (max-width: 479px) {
  .coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn {
    margin: 28px 0 0 !important;
  }
}

.coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn:hover {
  background-color: #D2A35C;
}

.checkbox-form h3 {
  border-bottom: 1px solid #d8d8d8;
  font-size: 25px;
  margin: 0 0 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .checkbox-form h3 {
    font-size: 18px;
  }
}

.checkbox-form .country-select {
  margin-bottom: 25px;
}

.checkbox-form .country-select .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  width: 100%;
  padding: 0 0 0 15px;
}

.checkbox-form .checkout-form-list {
  margin-bottom: 30px;
}

.checkbox-form .checkout-form-list input[type="text"],
.checkbox-form .checkout-form-list input[type="password"],
.checkbox-form .checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  height: 42px;
  width: 100%;
  padding: 0 0 0 10px;
}

.checkbox-form .checkout-form-list.create-acc {
  margin-bottom: 15px !important;
}

.checkbox-form .checkout-form-list.create-acc label {
  display: inline-block;
}

.checkbox-form .country-select label, .checkbox-form.checkout-form-list label {
  margin: 0 0 5px;
  display: block;
}

.checkbox-form .country-select label span.required, .checkbox-form.checkout-form-list label span.required {
  display: inline-block;
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 5px;
}

.different-address h3 label {
  display: inline-block;
  margin-right: 20px;
  font-size: 25px;
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .different-address h3 label {
    font-size: 15px;
  }
}

.different-address h3 input {
  height: inherit;
  line-height: normal;
  margin: 4px 0 0;
  position: relative;
  top: 0;
  width: auto;
}

.order-notes .checkout-form-list-2 {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .order-notes .checkout-form-list-2 {
    margin-bottom: 30px;
  }
}

.order-notes textarea {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  height: 90px;
  padding: 15px;
  width: 100%;
}

.create-account,
#ship-box-info {
  display: none;
}

.your-order {
  background: #f7f7f7;
  padding: 30px 40px 30px;
}

.your-order h3 {
  border-bottom: 1px solid #d8d8d8;
  font-size: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .your-order h3 {
    font-size: 18px;
  }
}

.your-order .your-order-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
}

.your-order .your-order-table table thead {
  background-color: #f7f7f7;
}

.your-order .your-order-table table thead tr th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 250px;
}

.your-order .your-order-table table thead tr th,
.your-order .your-order-table table thead tr td {
  border-bottom: 1px solid #d8d8d8;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order .your-order-table table .cart_item:hover {
  background: #f7f7f7;
}

.your-order .your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order .your-order-table table .order-total td span {
  font-size: 20px;
}

.your-order .your-order-table table .order-total th {
  border-bottom: medium none;
  font-size: 18px;
}

.payment-method {
  margin-top: 20px;
}

.payment-method .payment-accordion .card {
  border: 0;
  border-radius: 0;
  background: #f7f7f7;
}

.payment-method .payment-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f7f7f7;
  border-bottom: 0;
}

.payment-method .payment-accordion .card-body {
  padding: 0;
}

.payment-method .payment-accordion .panel.panel-default {
  margin-bottom: 20px;
}

.payment-method .payment-accordion .panel-title {
  font-size: 18px;
  cursor: pointer;
}

.order-button-payment input {
  background: #707070;
  border: medium none;
  color: #ffffff;
  font-size: 17px;
  height: 50px;
  margin: 20px 0 0;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
}
