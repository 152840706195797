/*----------------------------------------*/
/*  22 - Components - Offcanvas
/*----------------------------------------*/
.mobile-menu_wrapper,
.offcanvas-minicart_wrapper,
.offcanvas-search_wrapper,
.offcanvas-menu_wrapper {
	.offcanvas-body {
		background-color: $secondary-color;
		position: fixed;
		padding: 0;
		top: 0;
		left: -280px;
		width: 280px;
		z-index: 9999;
		visibility: hidden;
		opacity: 0;
		overflow: visible;
		@include transition(all 500ms ease);
		height: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.inner-body{
			padding: 50px 0;
			.offcanvas-top{
				display: flex;
				justify-content: flex-end;
				padding: 0 25px 30px;
				transform: none;
				height: auto;
				.btn-close {
					background-image: none;
					font-size: 24px;
					color: #c0c0c0;
					opacity: 1;
					outline: 0;
					padding: 0;
				}
			}
			.offcanvas-menu_area{
				.offcanvas-navigation {
					.mobile-menu {
						& > li {
							height: 100%;
							& > a {
								span {
									position: relative;
									display: flex;
									justify-content: space-between;
									align-items: center;
								}
							}
						}
						li {
							position: relative;
							&:not(:last-child){
								padding-bottom: 25px;
							}
							& > .menu-expand {
								position: absolute;
								right: 0;
								top: 2px;
								width: 50px;
								height: 100%;
								line-height: 40px;
								cursor: pointer;
								text-align: center;
								margin-right: 4px;
								@extend %basetransition;
								font-size: 20px;
							}
							a {
								color: #c0c0c0;
								font-size: 14px;
								text-transform: uppercase;
								display: block;
								padding: 0 30px;
							}
							.sub-menu {
								background-color: rgba(255, 255, 255, .05);
								overflow-y: auto;
								padding: 25px 0;
								margin: 20px 0 10px;
								li {
									a {
										text-transform: uppercase;
										font-size: 11px;
										color: #c0c0c0;
										span{
											display: flex;
											justify-content: space-between;
											align-items: center;
										}
									}
									&.menu-open{
										> a{
											> span{
												> i {
													&:before {
														content: '\ea13';
													}
												}
											}
										}
									}
								}
							}
							&.menu-open {
								> a{
									color: $primary-color;
									> span{
										> i {
											&:before {
												content: '\ea13';
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.open {
		.offcanvas-body {
			visibility: visible;
			left: 0;
			opacity: 1;
			z-index: 999;
		}
	}
}

/* ---Offcanvas Menu--- */
.offcanvas-menu_wrapper{
	.offcanvas-body{
		&.style-02{
			width: 470px;
			@include breakpoint(max-xs_device){
				width: 100%;
			}
			.inner-body{
				height: 100%;
				padding: 50px 0 0;
				.offcanvas-wrap{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;
					.offcanvas-menu_area{
						.offcanvas-navigation{
							ul{
								&.mobile-menu{
									li{
										a{
											color: $white-color;
											padding: 0 70px;
											font-size: 20px;
											line-height: 36px;
											text-transform: capitalize;
											span{
												i{
													font-size: 14px;
												}
											}
										}
										ul{
											&.sub-menu{
												li{
													a{
														text-transform: capitalize;
														font-size: 16px;
														line-height: 28.8px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.offcanvas-bottom{
						padding: 45px 70px 55px;
						transform: none;
						height: auto;
					}
				}
			}
		}
		&.direction-right{
			left: auto;
			right: -470px;
		}
	}
	&.open {
		.direction-right{
			right: 0;
		}
	}
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper {
	.offcanvas-body {
		box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
		background-color: $white-color;
		padding: 55px 60px 0;
		width: 450px;
		left: auto;
		right: -660px;
		@include breakpoint(max-xs_device){
			width: 100%;
			padding: 55px 30px 0;
		}
		.minicart-content {
			.minicart-heading {
				display: flex;
				justify-content: space-between;
				padding-bottom: 25px;
				h4 {
					font-size: 18px;
					line-height: 21.6px;
				}
				.btn-close {
					background-image: none;
					font-size: 24px;
					padding: 0;
					opacity: 1;
				}
			}
			.minicart-list {
				max-height: 350px;
				position: relative;
				overflow: auto;
				li {
					&:not(:last-child){
						border-bottom: 1px solid $border-color;
						padding-bottom: 30px;
						margin-bottom: 30px;
					}
					&.minicart-product {
						display: flex;
						@include breakpoint(max-xxs_device){
							flex-direction: column;
						}
						a {
							&.product-item_remove {
								i{
									position: absolute;
									font-size: 12px;
									top: auto;
									right: 15px;
								}
							}
						}
						.product-item_img {
							flex-basis: 70px;
							max-width: 70px;
							@extend %basetransition;
						}
						.product-item_content {
							flex-basis: calc(100% - 70px);
							max-width: calc(100% - 70px);
							padding-left: 20px;
							padding-right: 10px;
							@include breakpoint(max-xxs_device){
								padding-left: 0;
								padding-right: 0;
								padding-top: 15px;
							}
							a {
								&.product-item_title {
									line-height: 1.4;
									font-size: 16px;
									width: 90%;
									display: block;
									@include breakpoint(max-xxs_device){
										width: 100%;
									}
								}
							}
							.product-item_quantity {
								display: block;
								padding-top: 10px;
								font-size: 13px;
								line-height: 24.7px;
								color: $charcoal-color;
							}
						}
					}
				}
			}
		}
		.minicart-item_total {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px 0 30px;
		}
		.group-btn_wrap {
			padding-bottom: 60px;
		}
	}
	&.open {
		.offcanvas-body {
			left: auto;
			right: 0;
		}
	}
}

/* ---Offcanvas Search Area--- */
.offcanvas-search_wrapper {
	.offcanvas-body {
		background-color: rgba(0,0,0,.6);
		opacity: 0.97;
		width: 100%;
		left: 0;
		right: 0;
		@include transition(all 500ms ease);
		transform: scale(0);
		visibility: visible;
		opacity: 0;
		.offcanvas-search {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			@include transition(1.3s ease-in-out);
			.offcanvas-top{
				display: flex;
				justify-content: flex-end;
				width: 100%;
				padding-bottom: 125px;
				height: auto;
				transform: none;
				a{
					&.btn-close{
						background-image: none;
						color: $white-color;
						font-size: 30px;
						line-height: 1;
						opacity: 1;
						padding: 0;
						&:hover{
							color: $primary-color;
						}
					}
				}
			}
			span{
				&.searchbox-info{
					color: $white-color;
					font-size: 20px;
					@include breakpoint (max-xs_device){
						font-size: 16px;
					}
				}
			}
			.hm-searchbox {
				position: relative;
				width: 100%;
				input {
					background-color: transparent;
					border: 0;
					border-bottom: 1px solid $white-color;
					height: 100px;
					line-height: 100px;
					width: 100%;
					padding: 0;
					color: $white-color;
					text-align: center;
					font-size: 30px;
					padding-right: 40px;
					@include breakpoint (max-xs_device){
						font-size: 16px;
						height: 60px;
						line-height: 60px
					}
					&::placeholder {
						color: $white-color;
						text-align: center;
						opacity: 1;
					}
				}
				.search-btn {
					position: absolute;
					font-size: 30px;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					border: 0;
					background: transparent;
					color: $white-color;
					@include breakpoint (max-xs_device){
						font-size: 16px;
					}
					&:hover{
                        i{
                            color: $primary-color;
                        }
                    }
				}
			}
		}
	}
	&.open {
		.offcanvas-body {
			padding: 0;
			transform: scale(1);
			visibility: visible;
			opacity: 1;
		}
	}
}