/*----------------------------------------*/
/*  24 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper{
	display: block;
	&.modal {
		visibility: hidden;
		opacity: 0;
		@extend %basetransition;
		z-index: -9;
	}
	&.show{
		background-color: rgba(0,0,0,.5);
		visibility: visible;
		opacity: 1;
		z-index: 1051;
	}
    .modal{
        &-slider{
            height: 100%;
            .single-img{
                height: 100%;
                img{
                    &.img-full{
                        height: 100%;
                    }
                }
            }
        }
    }
	.modal-dialog{
        max-width: 945px;
        @include breakpoint(max-md_device){
            max-width: 100%;
            width: calc(100% - 60px);
            margin: 30px auto 0;
        }
        @include breakpoint(max-xxs_device){
            width: calc(100% - 30px);
            margin: 15px auto 0;
        }
		.modal-content {
            border-radius: 0;
            border: 0;
            overflow: visible;
			.modal-body {
                padding: 0;
				.close-btn {
					font-size: 18px;
					opacity: 1;
					position: absolute;
					right: -20px;
					top: -20px;
                    z-index: 99;
                    @include breakpoint(max-xxs_device){
                        top: -15px;
                        right: -15px;
                    }
                    i{
                        background-color: $secondary-color;
                        color: $white-color;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        border-radius: 100%;
                        display: block;
                    }
					&:hover{
                        i{
                            color: $primary-color;
                            transform: rotate(180deg);
                        }
					}
				}
				.modal-inner-area{
                    .inner-content{
                        padding-top: 15px;
                        padding-left: 30px;
                        padding-right: 30px;
                        height: 510px;
                        overflow-y: auto;
                        @include breakpoint(max-md_device){
                            height: 375px;
                        }
                        @include breakpoint(max-sm_device){
                            height: 100%;
                        }
                        .product-name{
                            padding-bottom: 10px;
                            a{
                                font-size: 24px;
                                line-height: 28.8px;
                            }
                        }
                        .price-box{
                            padding-bottom: 15px;
                            span{
                                color: $secondary-color;
                                font-size: 32px;
                                line-height: 32px;
                                font-weight: 500;
                            }
                        }
                        p{
                            &.short-desc{
                                font-size: 14px;
                                line-height: 25.2px;
                                padding-bottom: 25px;
                            }
                        }
                        .button-wrap{
                            border-bottom: 1px solid $border-color;
                            padding-bottom: 30px;
                            margin-bottom: 20px;
                            ul{
                                &.group-btn{
                                    align-items: center;
                                }
                            }
                        }
                        .product-meta{
                            border-bottom: 1px solid $border-color;
                            padding-bottom: 25px;
                            margin-bottom: 25px;
                            ul{
                                li{
                                    span,
                                    a{
                                        font-size: 14px;
                                        line-height: 25.2px;
                                    }
                                    a{
                                        color: $primary-color;
                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                        .social-link{
                            padding-bottom: 25px;
                            display: flex;
                            align-items: center;
                            li{
                                &.title{
                                    color: $secondary-color;
                                    padding-right: 20px;
                                    font-size: 12px;
                                    line-height: 19.8px;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    @include breakpoint(max-md_device){
                                        font-size: 11px;
                                    }
                                }
                                &:not(:last-child){
                                    @include breakpoint(max-xxs_device){
                                        padding-right: 15px;
                                    }
                                }
                                a{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
			}
		}
	}
}
